/**
 * AppEASA
 *
 * @file QuotationsList.js
 * @version 1.0
 * @description shareholder's cotations list component
 *
 */

// Base component
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// load other libs
import moment from "moment";

// load quotation filter component
import selectQuotation from "../../utils/QuotationSelector";

// Material UI Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

class QuotationsList extends Component {
  componentDidMount() {}
  render() {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Numéro</TableCell>
            <TableCell>Prospect</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Durée</TableCell>
            <TableCell>Début</TableCell>
            <TableCell>Fin</TableCell>
            <TableCell align="center">Combinaisons</TableCell>
            <TableCell>GO</TableCell>
            <TableCell>Type de prix</TableCell>
            <TableCell align="center">Statut</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.quotations.map((quotation) => {
            let currentProspect = this.props.prospects.find((p) => p.id === quotation.prospectId);

            let duration = quotation.endYear - quotation.startYear;
            let durationLabel = `${duration + 1} année(s)`;

            let goLabel = "Pérsonnalisée";
            let gos = JSON.parse(quotation.marketGoPrices);
            if (quotation.selectedGo !== 0) {
              let go = gos && gos.find((g) => g.id === quotation.selectedGo);

              goLabel = go && go.label;
            }

            let statusDisplay = <AccessAlarmIcon style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "1.2rem" }} />;

            if (quotation.status === "won") {
              statusDisplay = <CheckCircleOutlineIcon style={{ color: "rgba(17, 170, 0, 0.5)", fontSize: "1.2rem" }} />;
            } else if (quotation.status === "lost") {
              statusDisplay = <HighlightOffIcon style={{ color: "rgba(255, 0, 0, 0.5)", fontSize: "1.2rem" }} />;
            }

            let priceTypeLabel = "POD";
            if (quotation.selectedPod === "pricetype") {
              priceTypeLabel = "Prix par branche";
            }

            let combinations = JSON.parse(quotation.combinations);
            return (
              <TableRow key={quotation.id}>
                <TableCell>
                  <Link to={`/quotations/edit/${quotation.shareholderId}/${quotation.id}`}>{quotation.reference}</Link>
                </TableCell>
                <TableCell>
                  <p className="text-small">{currentProspect && currentProspect.name}</p>
                </TableCell>
                <TableCell>
                  <p className="text-small">{moment(quotation.created_at).format("DD/MM/YYYY")}</p>
                </TableCell>
                <TableCell>
                  <p className="text-small">{durationLabel}</p>
                </TableCell>
                <TableCell>
                  <p className="text-small">{quotation.startYear}</p>
                </TableCell>
                <TableCell>
                  <p className="text-small">{quotation.endYear}</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small">{combinations && combinations.length}</p>
                </TableCell>
                <TableCell>
                  <p className="text-small">{goLabel}</p>
                </TableCell>
                <TableCell>
                  <p className="text-small">{priceTypeLabel}</p>
                </TableCell>

                <TableCell align="center">{statusDisplay}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  quotations: selectQuotation(state.quotations, state.quotationsfilters),
  prospects: state.prospects,
  prices: state.prices,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuotationsList));
