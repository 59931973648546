/**
 * AppEASA
 *
 * @file NewsletterJan2022.js
 * @version 1.0
 * @description Newsletter view
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";

// Custom UI components
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

class NewsletterJan2022 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.user && this.props.user.role === "shareholder") {
      this.props.setBreadcrumb([{ label: "Vous êtes ici : Newsletter Janvier 2022", link: null }]);
    } else {
      this.props.setBreadcrumb([{ label: "Newsletter Janvier 2022", link: null }]);
    }
  }

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          {this.props.user && this.props.user.role === "shareholder" ? (
            <ShareholderHeaderBlock shareholderId={this.props.user && this.props.user.shareholderId} />
          ) : null}
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="title0">Contexte global</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Suisse :
                  </p>
                  <p className="text-newsletter">
                    Pour l’instant, on peut noter que l’impact de l’envolée des prix de l’électricité vécue durant le dernier trimestre 2021, reste modéré et
                    affectera peu les tarifs 2022. Toutefois, cette évolution aura un impact sur les tarifs des ménages en 2023, et ceci malgré le secours d’un
                    franc suisse qui s’est apprécié face à l’Euro.
                  </p>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Monde :
                  </p>
                  <p className="text-newsletter">
                    La tension entre l’Ukraine et la Russie monte d’un cran malgré les tentatives de pourparlers avec les Américains et les Européens.
                  </p>
                  <p className="text-newsletter">
                    En raison des menaces persistantes d’opérations militaires russes, les USA ont annoncé dimanche dernier avoir pris la décision d'ordonner
                    une évacuation des familles de diplomates en poste à Kiev.
                  </p>
                  <p className="text-newsletter">
                    On estime qu’actuellement environ 100'000 hommes sont déployés par la Russie près de la frontière, en vue d’une éventuelle invasion de
                    l’Ukraine. Cette situation pourrait dégénérer et mettre en péril la sécurité de l’Europe, tant sur le plan géopolitique, que sur le plan de
                    son approvisionnement énergétique (gaz, pétrole).
                  </p>
                  <p className="text-newsletter">
                    La Russie nie avoir planifié une attaque et affirme que la crise est provoquée par les actions de l'OTAN et des États-Unis. Elle exige des
                    garanties de la part de l'Occident, y compris une promesse de l’Organisation du traité de l’Atlantique nord de ne jamais admettre l'Ukraine
                    en son sein. Moscou considère l'ancienne république soviétique comme un tampon entre la Russie et les pays de l'OTAN.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Electricité</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    L'énergie est l'une des principales questions à l'ordre du jour pour l'Union européenne mais aussi pour la Suisse, car la flambée des prix
                    du gaz naturel a gonflé ou gonflera ces prochains mois les factures d’électricité des entreprises et des ménages.
                  </p>
                  <p className="text-newsletter">
                    Après la poursuite de la chute des prix de ce début d’année 2022 pour le Cal-2023, qui a atteint un minimum de 114.50 €/MWh ce 13 janvier
                    sur l’EEX, ceux-ci sont revenus aujourd’hui, mardi 26 janvier, pratiquement à leur niveau du 5 janvier 2022, soit environ 136.- €/MWh pour
                    le Baseload. Cela représente une augmentation de 18.8% en un peu moins de 2 semaines. Cette hausse est à mettre principalement sur le compte
                    des 4 facteurs suivants :
                  </p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">Les niveaux de stocks de gaz en Europe restent toujours en dessous des valeurs moyennes.</p>
                    </li>
                    <li>
                      <p className="text-newsletter">La crise russo-ukrainienne est source d’instabilité, ce que détestent les marchés.</p>
                    </li>
                    <li>
                      <p className="text-newsletter">Indisponibilité d’une partie du parc nucléaire français.</p>
                    </li>
                    <li>
                      <p className="text-newsletter">Prix actuels (26.01.2022) du Baseload sur le marché suisse :</p>
                    </li>
                  </ol>
                  <p className="text-newsletter">Prix actuels (26.01.2022) du Baseload sur le marché suisse :</p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">Cal-2023 : 138.68.00 €/MWh</p>
                    </li>
                    <li>
                      <p className="text-newsletter">Cal-2024 : 95.28 €/MWh</p>
                    </li>
                    <li>
                      <p className="text-newsletter">Cal-2025 : 88.28 €/MWh</p>
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Gaz naturel</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Selon les dires de hauts responsables de l'administration Biden, dans l’éventualité où la Russie envahirait l'Ukraine, les États-Unis,
                    premier producteur mondial de gaz naturel, mènent actuellement des discussions avec les principaux pays et entreprises producteurs d'énergie
                    en lien avec des livraisons supplémentaires de gaz naturel liquéfié (GNL) vers l'Europe.
                  </p>
                  <p className="text-newsletter">
                    La Russie exporte environ 0,66 milliards de m3 par jour de gaz naturel, dont environ 72 % vont aux plus grandes économies européennes.
                    L'ensemble du marché mondial du GNL devrait atteindre environ 1.5 milliards de m3 par jour en 2022.
                  </p>
                  <p className="text-newsletter">
                    Les États-Unis ont envoyé environ la moitié de leurs exportations de GNL en décembre vers l'Europe, contre 37 % plus tôt en 2021, selon les
                    données de Refinitiv et du département américain de l'énergie.
                  </p>
                  <p className="text-newsletter">
                    Les tensions russo-ukrainiennes (et américaines), conjuguées avec un éventuel blocage du projet Nord Stream 2 en guise de sanction, ont fait
                    bondir les cours du gaz naturel, qui se situent en ce moment aux environs des 49.63 €/MWh pour le forward TTF 2023, soit une progression de
                    15.55% par rapport au prix de clôture du 20 janvier 2022 qui se situait à 42.95 €/MWh.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">CO2</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Les prix du carbone pourraient poursuivre leur ascension dans un contexte de prix élevés du gaz et d’un manque d'approvisionnement aux
                    enchères.
                  </p>
                  <p className="text-newsletter">
                    L'augmentation du dark spread fournit une incitation supplémentaire à brûler du charbon et à augmenter la demande de droits d’émission. Les
                    tensions géopolitiques actuelles apportent un soutien aux marchés de l'énergie, y compris celui du carbone.
                  </p>
                  <p className="text-newsletter">
                    L'analyse technique suggère une poursuite possible du mouvement à la hausse avec l'objectif le plus proche de 90,00 €/t.
                  </p>
                  <p className="text-newsletter">Il a clôturé ce lundi 15 novembre à 65.93 €/t, soit son plus haut niveau historique. Du jamais vu.</p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Charbon</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    L'Indonésie a envoyé une onde de choc sur les marchés mondiaux de l'énergie, lorsqu'elle a imposé à ses producteurs, en ce début d'année
                    2022, l'interdiction d'exporter du charbon, et ceci après que la compagnie d'électricité d'État, Perusahaan Listrik Negara (PLN), ait
                    signalé des stocks extrêmement bas dans les centrales électriques du pays qui ont laissé l'Indonésie au bord de pannes d'électricité
                    généralisées.
                  </p>
                  <p className="text-newsletter">
                    Cette annonce a provoqué une envolée des prix du charbon pour fleurter actuellement avec les 113.90 $/t, niveau qui n'avait plus été atteint
                    depuis septembre 2021.
                  </p>
                  <p className="text-newsletter">
                    L'Indonésie, premier exportateur de charbon thermique, a autorisé le départ d'une quarantaine de charbonniers chargés à ras bord, a annoncé
                    le ministère de coordination des affaires maritimes et de l'investissement.
                  </p>
                  <p className="text-newsletter">
                    Dans un communiqué, le ministère indonésien a déclaré que l'interdiction d'exportation mise en place le 1er janvier 2022 avait été assouplie
                    pour les producteurs qui avaient satisfait à l'exigence de vendre une partie de leur charbon à des fins de production locale tout en
                    assurant cette dernière durant un période de 15 jours.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Pétrole</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Les prix du Brent et du WTI ont atteint respectivement 89,03 et 86.13 dollars, mercredi 26 janvier 2022, matin. Principalement en cause, des
                    tensions géopolitiques dans plusieurs zone du globe (Ukraine, Golfe).
                  </p>
                  <p className="text-newsletter">
                    De nombreux analystes s'attendent désormais à voir les prix du brut dépasser les 90 dollars le baril, voire la barre des 100 dollars, ce qui
                    semblait encore impossible à envisager il y a quelques mois. Les analystes de Goldman Sachs voient par exemple le Brent atteindre 96 dollars
                    cette année, puis 105 dollars en 2023.
                  </p>
                  <p className="text-newsletter">
                    En ce qui concerne le variant Omicron, initialement perçu comme une menace importante pour les acquisitions de brut, il s'avère moins grave
                    pour la demande que ses prédécesseurs.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Garanties d’origine</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    L'Allemagne est présentement le dernier grand acteur européen à ne pas émettre les garanties d'origines de ses producteurs nationaux
                    profitant d'aides étatiques, au sens de la directive européenne.
                  </p>
                  <p className="text-newsletter">
                    Cette production avoisinait les 220TWh en 2020, soit environ 28% des émissions de GO en Europe la même année ! L'offre serait donc nettement
                    supérieure qu'auparavant et creuserait ainsi un écart avec la demande, avec pour conséquence une possible baisse des prix.
                  </p>
                  <p className="text-newsletter">
                    Si le retrait de la Norvège du marché européen des GO devait se confirmer en 2022, et que l'on considère l'offre supplémentaire allemande,
                    alors l'offre actuelle des GO en Europe augmenterait de 71TWh.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Taux de change €/CHF</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    La BNS a annoncé dernièrement un bénéfice qui devrait atteindre les 26 milliards de francs sur l'année 2021. Elle dispose ainsi d'une
                    capacité d'intervention non négligeable dans l'éventualité où l'économie suisse devait être soutenue.
                  </p>
                  <p className="text-newsletter">
                    La faiblesse de l'euro face au franc suisse pourrait s'accélérer avec les chiffres de l'inflation dans la zone euro. Par ailleurs, en
                    période d'instabilité, telle que nous la vivons aujourd'hui, le CHF reste une valeur refuge.
                  </p>
                  <p className="text-newsletter">Sans intervention forte de la BNS, prévision de marge de fluctuation hebdomadaire : 1.0300 - 1.0400.</p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Recommandations</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Energie :
                  </p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">
                        Cal-2023, 2024 et 2025 en fonction du niveau de couverture de vos portefeuilles respectifs, adopter une position d’observateur et saisir
                        les opportunités baissières. Fenêtre temporelle =&gt; février-avril 2022.
                      </p>
                      <p className="text-newsletter">Cal-2026, couvrir au minimum 15% à 20% de votre portefeuille en fonction des opportunités.</p>
                    </li>
                  </ol>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Paire Eur/CHF :
                  </p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">Acquérir 60% de vos besoins en Euro sur 2023.</p>
                    </li>
                    <li>
                      <p className="text-newsletter">40% sur 2024.</p>
                    </li>
                    <li>
                      <p className="text-newsletter">20% sur 2025.</p>
                    </li>
                  </ol>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Garanties d'origine :
                  </p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">Clôturer vos positions 2021 d’ici la fin mars 2022.</p>
                    </li>
                    <li>
                      <p className="text-newsletter">
                        Année de production 2022 : Couvrir le 50%, d’ici la fin mars 2022, puis suivre les opportunités du marché jusqu’à fin juin, début
                        juillet 2022 pour couvrir les 50% restants (avant la clôture de vos tarifs 2022).
                      </p>
                    </li>
                    <li>
                      <p className="text-newsletter">Année de production 2023 à 2025 : Attendre avril-juin 2022.</p>
                    </li>
                  </ol>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterJan2022);
