/**
 * AppEASA
 *
 * @file UsersEdit.js
 * @version 1.0
 * @description user's edit view
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { beginLoadShareholders } from "../../../actions/shareholders";
import { beginUpdateUser, beginDeleteUser } from "../../../actions/users";

// Load other libs

// load fields validator
import validate from "../../../utils/Validation";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class UsersEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteUserDialogOpen: false,
      selectedRole: "shareholder",
      selectedShareholder: 0,
      selectedShareholderError: false,
      isActive: true,
      resetPassword: false,
      controls: {
        firstname: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
          },
          touched: false,
          error: false,
        },
        lastname: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
          },
          touched: false,
          error: false,
        },
        email: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            isEmail: true,
          },
          touched: false,
          error: false,
        },
        mobile: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
          },
          touched: false,
          error: false,
        },
      },
    };
  }

  componentDidMount() {
    if (!this.props.selectedUser) {
      this.props.history.push("/users");
    } else {
      this.props.beginLoadShareholders();

      // setup user
      this.updateInputState("firstname", this.props.selectedUser.firstname);
      this.updateInputState("lastname", this.props.selectedUser.lastname);
      this.updateInputState("email", this.props.selectedUser.email);
      this.updateInputState("mobile", this.props.selectedUser.mobile);

      // let shareHolder =
      this.setState({
        isActive: this.props.selectedUser.isActive === 1 ? true : false,
        selectedRole: this.props.selectedUser.role,
        selectedShareholder: this.props.selectedUser.shareholderId,
      });

      // setup breadcrum
      this.props.setBreadcrumb([
        { label: "Utilisateurs", link: "/users" },
        { label: "Editer utilisateur", link: null },
      ]);
    }
  }

  updateInputState = (key, value) => {
    // console.log("updateInputState key=", key);
    // console.log("updateInputState value=", value);
    // specific check for equalTo
    let connectedValue = {};
    if (this.state.controls[key].validationRules.equalTo) {
      const equalControl = this.state.controls[key].validationRules.equalTo;
      const equalValue = this.state.controls[equalControl].value;
      connectedValue = {
        ...connectedValue,
        equalTo: equalValue,
      };
    }

    this.setState((prevState) => {
      return {
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            valid: validate(value, prevState.controls[key].validationRules, connectedValue),
            touched: true,
          },
        },
      };
    });
  };

  handleRoleChange = (e) => {
    this.setState({ selectedRole: e.target.value, selectedShareholder: 0 });
  };

  handleShareholderChange = (e) => {
    this.setState({ selectedShareholder: e.target.value, selectedShareholderError: false }, () => console.log(this.state));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.controls.firstname.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le prénom de l'utilisateur ne peut être vide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            firstname: {
              ...prevState.controls["firstname"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            firstname: {
              ...prevState.controls["firstname"],
              error: false,
            },
          },
        };
      });
    }

    if (!this.state.controls.lastname.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le nom de l'utilisateur ne peut être vide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            lastname: {
              ...prevState.controls["lastname"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            lastname: {
              ...prevState.controls["lastname"],
              error: false,
            },
          },
        };
      });
    }

    if (!this.state.controls.email.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "L'email de l'utilisateur n'est pas valide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            email: {
              ...prevState.controls["email"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            email: {
              ...prevState.controls["email"],
              error: false,
            },
          },
        };
      });
    }

    if (!this.state.controls.mobile.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le numéro de téléphone de l'utilisateur n'est pas valide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            mobile: {
              ...prevState.controls["mobile"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            mobile: {
              ...prevState.controls["mobile"],
              error: false,
            },
          },
        };
      });
    }

    if (this.state.selectedRole === "shareholder" && this.state.selectedShareholder === 0) {
      this.setState({ selectedShareholderError: true });
      this.props.setSnackbar({ open: true, severity: "error", message: "Sélectionnez l'actionnaire auquel cet utilisateur est lié.", duration: 3000 });
      return;
    } else {
      this.setState({ selectedShareholderError: false });
    }

    const update = {
      firstname: this.state.controls.firstname.value.trim(),
      lastname: this.state.controls.lastname.value.trim(),
      email: this.state.controls.email.value.trim(),
      mobile: this.state.controls.mobile.value.trim(),
      role: this.state.selectedRole,
      shareholder: this.state.selectedShareholder,
      isActive: this.state.isActive,
      resetPassword: this.state.resetPassword,
    };
    //console.log("onSubmit update user=", update);

    this.props.setLoading(true);
    this.props
      .beginUpdateUser(this.props.match.params.id, update)
      .then((res) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: "Utilisateur mis à jour avec succès.", duration: 3000 });
        this.props.history.push("/users");
      })
      .catch((err) => {
        //console.log("UsersEdit, onSubmit err=", err);
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Impossible de mettre à jour l'utilisateur.", duration: 3000 });
      });
  };

  handleDeleteDialogClose = () => {
    this.setState({ deleteUserDialogOpen: false });
  };

  handleDeleteDialogOpen = () => {
    this.setState({ deleteUserDialogOpen: true });
  };

  onDelete = () => {
    this.props.setLoading(true);
    this.props
      .beginDeleteUser(this.props.match.params.id)
      .then((res) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: "Utilisateur supprimé avec succès.", duration: 3000 });
        this.props.history.push("/users");
      })
      .catch((err) => {
        this.setState({ deleteUserDialogOpen: false });
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: err.data.errors[0].msg, duration: 3000 });
      });
  };

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <Grid container spacing={3} style={{ marginTop: "1rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Editer Utilisateur</p>
            </Grid>
            <Grid item>
              <ButtonGroup>
                <Button size="small" color="primary" variant="contained" disableElevation onClick={this.onSubmit}>
                  Enregistrer
                </Button>
                <Button size="small" color="secondary" variant="contained" disableElevation onClick={this.handleDeleteDialogOpen}>
                  Supprimer
                </Button>
                <Button size="small" color="default" variant="contained" disableElevation component={Link} to={`/users`}>
                  Annuler
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={6}>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Nom</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="firstname"
                    value={this.state.controls.firstname.value}
                    error={this.state.controls.firstname.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("firstname", e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Prénom</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="lastname"
                    value={this.state.controls.lastname.value}
                    error={this.state.controls.lastname.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("lastname", e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Adresse e-mail</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="email"
                    value={this.state.controls.email.value}
                    error={this.state.controls.email.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("email", e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Téléphone mobile</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="mobile"
                    value={this.state.controls.mobile.value}
                    error={this.state.controls.mobile.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("mobile", e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Rôle de l'utilisateur</p>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <Select id="user-role" value={this.state.selectedRole} fullWidth onChange={this.handleRoleChange}>
                      {this.props.roles &&
                        this.props.roles.map((role, index) => {
                          return (
                            <MenuItem key={index} value={role.name}>
                              {role.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {this.state.selectedRole === "shareholder" ? (
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel">Nom de l'actionnaire</p>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <Select
                        id="user-shareholder"
                        value={this.state.selectedShareholder}
                        fullWidth
                        onChange={this.handleShareholderChange}
                        error={this.state.selectedShareholderError}
                        placeholder="Selectionnez un actionnaire"
                      >
                        <MenuItem key={0} value={0}>
                          <p className="italic">Sélectionnez un actionnaire</p>
                        </MenuItem>
                        {this.props.shareholders &&
                          this.props.shareholders.map((shareholder, index) => {
                            return (
                              <MenuItem key={index} value={shareholder.id}>
                                {shareholder.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Utilisateur activé</p>
                </Grid>
                <Grid item xs={8}>
                  <Switch
                    id="isActive"
                    color="primary"
                    checked={this.state.isActive}
                    onChange={(e) =>
                      this.setState((prevState) => {
                        return { ...prevState, isActive: !prevState.isActive };
                      })
                    }
                  />
                </Grid>
              </Grid>
              {this.state.isActive ? (
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel">Réinitialiser le mot de passe</p>
                  </Grid>
                  <Grid item xs={8}>
                    <Switch
                      id="isActive"
                      color="primary"
                      checked={this.state.resetPassword}
                      onChange={(e) =>
                        this.setState((prevState) => {
                          return { ...prevState, resetPassword: !prevState.resetPassword };
                        })
                      }
                    />
                    {this.state.resetPassword ? (
                      <p className="text-sublabel">Un email avec mot de passe temporaire sera automatiquement envoyé à l'utilisateur.</p>
                    ) : null}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Container>
        <Dialog
          open={this.state.deleteUserDialogOpen}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">App EASA : Supprimer un utilisateur</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">Êtes-vous certain de vouloir supprimer cet utilisateur ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteDialogClose} color="primary">
              Annuler
            </Button>
            <Button onClick={this.onDelete} color="secondary" autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  settings: state.settings,
  roles: state.roles,
  shareholders: state.shareholders,
  selectedUser: state.users.find((user) => user.id === parseInt(props.match.params.id)),
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  beginLoadShareholders: () => dispatch(beginLoadShareholders()),
  beginUpdateUser: (id, update) => dispatch(beginUpdateUser(id, update)),
  beginDeleteUser: (id) => dispatch(beginDeleteUser(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersEdit));
