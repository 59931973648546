/**
 * AppEASA
 *
 * @file QuotationGoTable.js
 * @version 1.0
 * @description GO prices table including fields to add customized GO prices
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";

// Other libs/components
import NumberFormat from "react-number-format";

// Material UI Components
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import Collapse from "@material-ui/core/Collapse";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      mask="_"
      fixedDecimalScale={true}
      decimalScale={3}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ fontSize: "0.8rem", textAlign: "center" }}
    />
  );
}

class QuotationGoTable extends Component {
  goTableRow = (year) => {
    let customGOPrices = { year, price: 0 };
    if (parseInt(this.props.quotation.selectedGo) === 0) {
      let goPrice = this.props.quotation.goPrices.find((go) => go.year === year);
      if (goPrice) customGOPrices = goPrice;
    }

    return (
      <TableRow key={`goRow_${year}`}>
        <TableCell>
          <p className="text bold">{year}</p>
        </TableCell>
        {this.props.quotation.marketGoPrices &&
          this.props.quotation.marketGoPrices.map((go, index) => {
            let yearlyPrice = go.prices && go.prices.find((p) => p.year === year);
            return (
              <TableCell key={`goBody${index}`} align="center">
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={yearlyPrice && yearlyPrice.price}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
            );
          })}
        <TableCell align="center">
          <TextField
            id={`customized-go-price_${year}`}
            value={customGOPrices.price}
            onChange={(e) => this.props.updateCustomizedGoPrice(year, e.target.value)}
            style={{ width: "60px" }}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  render() {
    let customGOAveragePrice = 0;
    if (parseInt(this.props.quotation.selectedGo) === 0) {
      for (let index = 0; index < this.props.quotation.goPrices.length; index++) {
        const currentPrice = this.props.quotation.goPrices[index];
        customGOAveragePrice += currentPrice.price;
      }

      customGOAveragePrice = customGOAveragePrice / this.props.quotation.years.length;
      customGOAveragePrice = Number(Math.round(customGOAveragePrice + "e" + 2) + "e-" + 2);
    }
    return (
      <Fragment>
        <Collapse in={this.props.goPricesTableExpanded} timeout="auto" unmountOnExit>
          <Table size="small" style={{ marginTop: "1rem" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="text-small">Année</p>
                </TableCell>
                {this.props.quotation.marketGoPrices &&
                  this.props.quotation.marketGoPrices.map((go, index) => {
                    return (
                      <TableCell key={`goHeader${index}`} align="center">
                        <p className="text-small bold">{go.label}</p>
                      </TableCell>
                    );
                  })}
                <TableCell align="center">
                  <p className="text-small bold">Autre</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                {this.props.quotation.marketGoPrices &&
                  this.props.quotation.marketGoPrices.map((go, index) => {
                    return (
                      <TableCell key={`goHeaderRadio${index}`} align="center">
                        <Radio
                          checked={parseInt(this.props.selectedGo) === go.id}
                          onChange={(e) => this.props.updateGoPrice(parseInt(e.target.value))}
                          value={go.id}
                          name={go.label}
                          inputProps={{ "aria-label": go.label }}
                          color="primary"
                        />
                      </TableCell>
                    );
                  })}
                <TableCell key={`goHeaderRadioCustomized`} align="center">
                  <Radio
                    checked={parseInt(this.props.selectedGo) === 0}
                    onChange={(e) => this.props.updateGoPrice(e.target.value)}
                    value={0}
                    name={"customized"}
                    inputProps={{ "aria-label": "customized" }}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.quotation.years.map((y) => this.goTableRow(y))}
              <TableRow>
                <TableCell>
                  <p className="text-small italic">Moyenne</p>
                </TableCell>
                {this.props.quotation.marketGoPrices &&
                  this.props.quotation.marketGoPrices.map((go, index) => {
                    let average = 0;

                    for (let index = 0; index < this.props.quotation.years.length; index++) {
                      const currentYear = this.props.quotation.years[index];
                      let yearlyPrice;
                      yearlyPrice = go.prices && go.prices.find((p) => p.year === currentYear);

                      average += yearlyPrice && yearlyPrice.price;
                    }
                    average = average / this.props.quotation.years.length;

                    let bgColor = "white";
                    let fontWeight = "normal";
                    if (this.props.selectedGo === go.id) {
                      bgColor = "rgba(17, 170, 0, 0.1)";
                      fontWeight = "bold";
                    }
                    return (
                      <TableCell key={`goAverage${index}`} align="center" style={{ backgroundColor: bgColor }}>
                        <p className="text-small">
                          <NumberFormat
                            displayType="text"
                            style={{ fontWeight: fontWeight }}
                            decimalScale={3}
                            fixedDecimalScale={true}
                            value={average}
                            prefix=""
                            thousandSeparator="'"
                          />
                        </p>
                      </TableCell>
                    );
                  })}
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: this.props.selectedGo === 0 ? "rgba(17, 170, 0, 0.1)" : "",
                  }}
                >
                  <p className="text-small">
                    <NumberFormat
                      displayType="text"
                      style={{
                        fontWeight: this.props.selectedGo === 0 ? "bold" : "",
                      }}
                      decimalScale={3}
                      fixedDecimalScale={true}
                      value={customGOAveragePrice}
                      prefix=""
                      thousandSeparator="'"
                    />
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <p className="text-small italic margin-top-1rem">Prix en CHF/MWh</p>
        </Collapse>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
  settings: state.settings,
  prices: state.prices,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationGoTable);
