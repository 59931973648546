/**
 * AppEASA
 *
 * @file templatescontents.js
 * @version 1.0
 * @description templates contents reducer
 *
 */

// import redux action type
import { SET_TEMPLATES_CONTENT } from "../actions/types";

// set initial state
const initialState = [];

// export reducer
export default function templatesContentsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TEMPLATES_CONTENT:
      return payload;

    default:
      return state;
  }
}
