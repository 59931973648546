/**
 * AppEASA
 *
 * @file settings.js
 * @version 1.0
 * @description redux settings' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_TEMPLATES_CONTENT } from "./types";

// import redux actions
import { setLoading } from "./ui";
import setAuthToken from "../utils/setAuthToken";

// load templates content blocs
export const beginLoadTemplatesContentBlocs = (shareholderId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/settings/contentbloc/" + shareholderId, config);

      if (res.status === 200) {
        dispatch(setTemplateContentBlocs(res.data.contentBlocs));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les contenus des modèles de documents."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadTemplatesContentBlocs = ", err);
      dispatch(setLoading(false));
      reject({ status: false });
    }
  });

// set templates content blocs
export const setTemplateContentBlocs = (blocs) => ({
  type: SET_TEMPLATES_CONTENT,
  payload: blocs,
});

// save new content bloc
export const beginSaveTemplatesContentBloc = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios
        .post("/api/settings/contentbloc/new", body, config)
        .then((res) => {
          // console.log("res = ", res);
          resolve({ status: res.status });
        })
        .catch((error) => {
          reject({ status: false, msg: error.response.data });
        });
    } catch (err) {
      console.log(" beginSaveTemplatesContentBloc = ", err);
      dispatch(setLoading(false));
      reject({ status: false });
    }
  });

// update content bloc
export const beginUpdateTemplatesContentBloc = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios
        .put("/api/settings/contentbloc", body, config)
        .then((res) => {
          // console.log("res = ", res);
          resolve({ status: res.status });
        })
        .catch((error) => {
          reject({ status: false, msg: error.response.data });
        });
    } catch (err) {
      console.log(" beginUpdateTemplatesContentBloc = ", err);
      dispatch(setLoading(false));
      reject({ status: false });
    }
  });

// delete content blocs
export const beginDeleteTemplatesContentBloc = (blocId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      await axios
        .delete("/api/settings/contentbloc/" + blocId)
        .then((res) => {
          // console.log("res = ", res);
          resolve({ status: res.status });
        })
        .catch((error) => {
          reject({ status: false, msg: error.response.data });
        });
    } catch (err) {
      console.log("beginDeleteTemplatesContentBloc = ", err);
      dispatch(setLoading(false));
      reject({ status: false });
    }
  });
