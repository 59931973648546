/**
 * AppEASA
 *
 * @file UsersAccess.js
 * @version 1.0
 * @description component used to check users's view access
 *
 */

// load requierd librairies
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// load components
import Can from "../../rbac/Can";

// load UI Components
import UsersEdit from "./UsersEdit";
import RestrictedAccess from "../../rbac/RestrictedAccess";

class UsersEditAccess extends Component {
  render() {
    return (
      <Can
        permissions={this.props.user.permissions}
        action={"users:edit"}
        yes={() => {
          return <UsersEdit />;
        }}
        no={() => <RestrictedAccess />}
      />
    );
  }
}

UsersEditAccess.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  //navbar: state.navbar,
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersEditAccess);
