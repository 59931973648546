/**
 * AppEASA
 *
 * @file Quotations.js
 * @version 1.0
 * @description quotations list
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { beginLoadConsumers } from "../../../actions/consumers";
import { beginLoadProspects } from "../../../actions/prospects";
import { resetNewQuotation } from "../../../actions/quotation";
import { beginLoadQuotations } from "../../../actions/quotations";

// UI Components
import QuotationsList from "../../quotation/QuotationsList";
import QuotationsListFilters from "../../quotation/QuotationsListFilters";
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

class Quotations extends Component {
  componentDidMount() {
    this.props.resetNewQuotation();

    let redirectTo = this.props.user && this.props.user.role === "admin" ? "/dashboard" : "/shareholders";
    if (this.props.shareholder === undefined) {
      this.props.history.push(redirectTo);
      return;
    }

    // load quotations
    this.props.beginLoadQuotations(this.props.match.params.id);

    // load shareholder's prospects
    this.props.beginLoadProspects(this.props.match.params.id).catch((err) => {});

    // load price type
    this.props.beginLoadConsumers();

    this.props.setBreadcrumb([
      {
        label: this.props.user && this.props.user.role === "admin" ? "Tableau de bord" : "Actionnaires",
        link: redirectTo,
      },
      { label: `Cotations ${this.props.shareholder.name}`, link: null },
    ]);
  }

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <ShareholderHeaderBlock shareholderId={this.props.match.params.id} />
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Cotations</p>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="contained"
                disableElevation
                component={Link}
                to={`/quotations/add/${this.props.shareholder && this.props.shareholder.id}`}
              >
                Nouvelle cotation
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={12}>
              <QuotationsListFilters />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={12}>
              <QuotationsList />
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  beginLoadProspects: (shareholderId) => dispatch(beginLoadProspects(shareholderId)),
  resetNewQuotation: () => dispatch(resetNewQuotation()),
  beginLoadQuotations: (shareholderId) => dispatch(beginLoadQuotations(shareholderId)),
  beginLoadConsumers: () => dispatch(beginLoadConsumers()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quotations));
