/**
 * AppEASA
 *
 * @file UsersAdd.js
 * @version 1.0
 * @description user's add view
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { beginSaveUser } from "../../../actions/users";
import { beginLoadShareholders } from "../../../actions/shareholders";
import { beginLoadRoles } from "../../../actions/roles";

// Load other libs

// load fields validator
import validate from "../../../utils/Validation";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";

class UsersAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: "shareholder",
      selectedShareholder: 0,
      selectedShareholderError: false,
      isActive: true,
      controls: {
        firstname: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
          },
          touched: false,
          error: false,
        },
        lastname: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
          },
          touched: false,
          error: false,
        },
        email: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            isEmail: true,
          },
          touched: false,
          error: false,
        },
        mobile: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
          },
          touched: false,
          error: false,
        },
      },
    };
  }

  componentDidMount() {
    this.props.beginLoadRoles();
    this.props.beginLoadShareholders();
    this.props.setBreadcrumb([
      { label: "Utilisateurs", link: "/users" },
      { label: "Nouvel utilisateur", link: null },
    ]);
  }

  updateInputState = (key, value) => {
    // console.log("updateInputState key=", key);
    // console.log("updateInputState value=", value);
    // specific check for equalTo
    let connectedValue = {};
    if (this.state.controls[key].validationRules.equalTo) {
      const equalControl = this.state.controls[key].validationRules.equalTo;
      const equalValue = this.state.controls[equalControl].value;
      connectedValue = {
        ...connectedValue,
        equalTo: equalValue,
      };
    }

    this.setState((prevState) => {
      return {
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            valid: validate(value, prevState.controls[key].validationRules, connectedValue),
            touched: true,
          },
        },
      };
    });
  };

  handleRoleChange = (e) => {
    this.setState({ selectedRole: e.target.value });
  };

  handleShareholderChange = (e) => {
    this.setState({ selectedShareholder: e.target.value, selectedShareholderError: false });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.controls.firstname.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le prénom de l'utilisateur ne peut être vide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            firstname: {
              ...prevState.controls["firstname"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            firstname: {
              ...prevState.controls["firstname"],
              error: false,
            },
          },
        };
      });
    }

    if (!this.state.controls.lastname.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le nom de l'utilisateur ne peut être vide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            lastname: {
              ...prevState.controls["lastname"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            lastname: {
              ...prevState.controls["lastname"],
              error: false,
            },
          },
        };
      });
    }

    if (!this.state.controls.email.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "L'email de l'utilisateur n'est pas valide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            email: {
              ...prevState.controls["email"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            email: {
              ...prevState.controls["email"],
              error: false,
            },
          },
        };
      });
    }

    if (!this.state.controls.mobile.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le numéro de téléphone de l'utilisateur n'est pas valide.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            mobile: {
              ...prevState.controls["mobile"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            mobile: {
              ...prevState.controls["mobile"],
              error: false,
            },
          },
        };
      });
    }

    if (this.state.selectedRole === "shareholder" && this.state.selectedShareholder === 0) {
      this.setState({ selectedShareholderError: true });
      this.props.setSnackbar({ open: true, severity: "error", message: "Sélectionnez l'actionnaire auquel cet utilisateur est lié.", duration: 3000 });
      return;
    } else {
      this.setState({ selectedShareholderError: false });
    }

    const user = {
      firstname: this.state.controls.firstname.value.trim(),
      lastname: this.state.controls.lastname.value.trim(),
      email: this.state.controls.email.value.trim(),
      mobile: this.state.controls.mobile.value.trim(),
      role: this.state.selectedRole,
      shareholder: this.state.selectedShareholder,
      isActive: this.state.isActive,
    };

    this.props.setLoading(true);
    this.props
      .beginSaveUser(user)
      .then((res) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: "Nouvel utilisateur crée avec succès.", duration: 3000 });
        this.props.history.push("/users");
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.log("UsersAdd, onSubmit err=", err);
        this.props.setSnackbar({ open: true, severity: "error", message: err.data.errors[0].msg, duration: 3000 });
      });
  };

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <Grid container spacing={3} style={{ marginTop: "1rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Nouvel Utilisateur</p>
            </Grid>
            <Grid item>
              <ButtonGroup>
                <Button size="small" color="primary" variant="contained" disableElevation onClick={this.onSubmit}>
                  Enregistrer
                </Button>
                <Button size="small" color="default" variant="contained" disableElevation component={Link} to={`/users`}>
                  Annuler
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={6}>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Nom</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="firstname"
                    value={this.state.controls.firstname.value}
                    error={this.state.controls.firstname.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("firstname", e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Prénom</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="lastname"
                    value={this.state.controls.lastname.value}
                    error={this.state.controls.lastname.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("lastname", e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Adresse e-mail</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="email"
                    value={this.state.controls.email.value}
                    error={this.state.controls.email.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("email", e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Téléphone mobile</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="mobile"
                    value={this.state.controls.mobile.value}
                    error={this.state.controls.mobile.error}
                    fullWidth
                    onChange={(e) => this.updateInputState("mobile", e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Rôle de l'utilisateur</p>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <Select id="user-role" value={this.state.selectedRole} fullWidth onChange={this.handleRoleChange}>
                      {this.props.roles &&
                        this.props.roles.map((role, index) => {
                          return (
                            <MenuItem key={index} value={role.name}>
                              {role.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {this.state.selectedRole === "shareholder" ? (
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel">Nom de l'actionnaire</p>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <Select
                        id="user-shareholder"
                        value={this.state.selectedShareholder}
                        fullWidth
                        onChange={this.handleShareholderChange}
                        error={this.state.selectedShareholderError}
                        placeholder="Selectionnez un actionnaire"
                      >
                        <MenuItem key={0} value={0}>
                          <p className="italic">Sélectionnez un actionnaire</p>
                        </MenuItem>
                        {this.props.shareholders &&
                          this.props.shareholders.map((shareholder, index) => {
                            return (
                              <MenuItem key={index} value={shareholder.id}>
                                {shareholder.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={4}>
                  <p className="inputlabel">Utilisateur activé</p>
                </Grid>
                <Grid item xs={8}>
                  <Switch
                    id="isActive"
                    color="primary"
                    checked={this.state.isActive}
                    onChange={(e) =>
                      this.setState((prevState) => {
                        return { ...prevState, isActive: !prevState.isActive };
                      })
                    }
                  />
                  {this.state.isActive ? (
                    <p className="text-sublabel">Un email avec mot de passe temporaire sera automatiquement envoyé à l'utilisateur.</p>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  user: state.user,
  settings: state.settings,
  roles: state.roles,
  shareholders: state.shareholders,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  beginSaveUser: (user) => dispatch(beginSaveUser(user)),
  beginLoadRoles: () => dispatch(beginLoadRoles()),
  beginLoadShareholders: () => dispatch(beginLoadShareholders()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersAdd));
