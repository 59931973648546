/**
 * AppEASA
 *
 * @file quotationsfilters.js
 * @version 1.0
 * @description redux quotations' filters actions
 *
 */

// import redux action type
import { SET_QUOTATIONS_TEXT_FILTER, SET_QUOTATIONS_STATUS_FILTER, SET_QUOTATIONS_PROSPECT_FILTER } from "./types";

// set quotations text filter
export const setQuotationsTextFilter = (text) => ({
  type: SET_QUOTATIONS_TEXT_FILTER,
  payload: text,
});

// set quotations status filter
export const setQuotationsStatusFilter = (status) => ({
  type: SET_QUOTATIONS_STATUS_FILTER,
  payload: status,
});

// set quotations prospects filter
export const setQuotationsProspectsFilter = (prospectId) => ({
  type: SET_QUOTATIONS_PROSPECT_FILTER,
  payload: prospectId,
});
