/**
 * @description Private route
 * Check whether or not the user is logged and display component or redirect to login view
 */

// Base component
import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// import ChangePassword from "../auth/ChangePassword";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  ...rest
}) => {
  // console.log("private route isAuthenticated = ", isAuthenticated);
  // console.log("private route loading = ", loading);
  return (
    <Route
      {...rest}
      render={(props) =>
        // !isAuthenticated && !loading ? <Redirect to="/login" /> : user && user.changepwd ? <ChangePassword {...props} /> : <Component {...props} />
        !isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Fragment>
            <Component {...props} />
          </Fragment>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
