/**
 * AppEASA
 *
 * @file Breadcrumb.js
 * @version 1.0
 * @description App's breadcrumb
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isNull } from "lodash";
import { Link } from "react-router-dom";

// load material UI components
import { Container } from "@material-ui/core";

class Breadcrumb extends Component {
  render() {
    if (this.props.isAuthenticated && !this.props.auth.loading) {
      return (
        <Container className="breadcrumb-wrapper">
          <div>
            {this.props.breadcrumb &&
              this.props.breadcrumb.map((b, index) => {
                if (!isNull(b.link)) {
                  return (
                    <Fragment key={index}>
                      <Link to={`${b.link}`} className="breadcrumb-links">
                        {b.label}
                      </Link>
                      <span className="breadcrumb-separator">/</span>
                    </Fragment>
                  );
                } else {
                  return (
                    <span key={index} className="breadcrumb-label">
                      {b.label}
                    </span>
                  );
                }
              })}
          </div>
        </Container>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  user: state.user,
  breadcrumb: state.breadcrumb,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
