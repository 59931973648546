/**
 * AppEASA
 *
 * @file historyprices.js
 * @version 1.0
 * @description historyprices reducer
 *
 */

// import redux action type
import { SET_HISTORYPRICES_TIMES, SET_HISTORYPRICES } from "../actions/types";

// set initial state
const initialState = {
  times: [],
  prices: [],
};

// export reducer
export default function historyPricesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_HISTORYPRICES_TIMES:
      return {
        ...state,
        times: payload,
      };

    case SET_HISTORYPRICES:
      return {
        ...state,
        prices: payload,
      };

    default:
      return state;
  }
}
