/**
 * AppEASA
 *
 * @file MarketStructurePanel.js
 * @version 1.0
 * @description Market structure configuration panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { beginLoadMarketstructures } from "../../../actions/marketstructures";

// load other libs
import NumberFormat from "react-number-format";
import moment from "moment";

// load material UI components
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";

class MarketStructurePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addMarketStructureDialogOpen: true,
    };
  }

  componentDidMount() {
    this.props.beginLoadMarketstructures();
  }

  handleAddMarketStructure = () => {
    console.log("handleAddMarketStructure...");
  };

  render() {
    return (
      <Fragment>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="text bold">Définition de la structure du marché SWX et FWD</p>
          </Grid>
          <Grid item>
            <ButtonGroup>
              <Button variant="contained" color="primary" size="small" onClick={this.handleAddMarketStructure} disabled>
                Ajouter nouvelle structure
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4} align="center" style={{ borderBottom: "none", borderRight: "dotted 1px #cccccc" }}>
                    <p className="text-small bold">SWX</p>
                  </TableCell>
                  <TableCell colSpan={4} align="center" style={{ borderBottom: "none" }}>
                    <p className="text-small bold">FWD</p>
                  </TableCell>
                  <TableCell rowSpan={2} align="center" style={{}}>
                    <p className="text-small bold">Ajouté le</p>
                  </TableCell>
                  <TableCell rowSpan={2} align="center" style={{}}>
                    <p className="text-small bold">Structure courante</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <p className="text-small bold">T1</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">T2</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">T3</p>
                  </TableCell>
                  <TableCell align="center" style={{ borderRight: "dotted 1px #cccccc" }}>
                    <p className="text-small bold">T4</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">T1</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">T2</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">T3</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">T4</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.marketstructures &&
                  this.props.marketstructures.map((marketstructure) => {
                    return (
                      <TableRow key={marketstructure.id}>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.swxQ1}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.swxQ2}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.swxQ3}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: "dotted 1px #cccccc" }}>
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.swxQ4}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.fwdQ1}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.fwdQ2}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.fwdQ3}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={marketstructure.fwdQ4}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">{moment(marketstructure.created_at).format("DD/MM/YYYY")}</p>
                        </TableCell>
                        <TableCell align="center">
                          {marketstructure.isActive === 1 ? <CheckIcon fontSize="small" style={{ color: "green" }} /> : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  marketstructures: state.marketstructures,
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  beginLoadMarketstructures: () => dispatch(beginLoadMarketstructures()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketStructurePanel);
