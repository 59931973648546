/**
 * AppEASA
 *
 * @file QuotationMarketTableComponent.js
 * @version 1.0
 * @description market details table component
 *
 */

// Base component
import React, { Component } from "react";

// load redux and functions
import { connect } from "react-redux";

// Other libs/components
import NumberFormat from "react-number-format";

// Material UI Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class QuotationMarketTableComponent extends Component {
  render() {
    return (
      <Table size="small" style={{ marginTop: "2rem" }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ borderBottom: "none" }}></TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderTop: "solid 5px #a0e6ff",
                borderLeft: "dashed 1px #CCCCCC",
              }}
            >
              T1
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderTop: "solid 5px #A7FC01",
                borderLeft: "dashed 1px #CCCCCC",
              }}
            >
              T2
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderTop: "solid 5px #FFFE00",
                borderLeft: "dashed 1px #CCCCCC",
              }}
            >
              T3
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderTop: "solid 5px #FF7F00",
                borderLeft: "dashed 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              T4
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              HP
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              HC
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              HP
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              HC
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              HP
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              HC
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              HP
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderLeft: "dotted 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              HC
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <p className="title2">Heures</p>
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hp.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hc.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hp.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hc.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hp.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hc.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hp.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderLeft: "dotted 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hc.hours}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p className="title2">Consomation MWh</p>
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hp.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hc.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hp.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hc.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hp.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hc.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hp.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderLeft: "dotted 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={0}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hc.consumption}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p className="title2">
                FCC
                <sub>
                  <em>SWX</em>
                </sub>
              </p>
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hp.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hc.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hp.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hc.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hp.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hc.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hp.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderLeft: "dotted 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hc.fccswx}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <p className="text-small italic">90 jours</p>
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <p className="text-small italic">91 jours</p>
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <p className="text-small italic">92 jours</p>
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderLeft: "dashed 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <p className="text-small italic">92 jours</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p className="title2">
                Structure marché
                <sub>
                  <em>SWX</em>
                </sub>
              </p>
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.swx}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.swx}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.swx}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderLeft: "dashed 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.swx}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p className="title2">
                Structure marché
                <sub>
                  <em>FWD</em>
                </sub>
              </p>
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.fwd}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.fwd}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.fwd}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderLeft: "dashed 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.fwd}
                prefix=""
                suffix="%"
                thousandSeparator="'"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p className="title2">Facteur</p>
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.factor}
                prefix=""
                suffix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.factor}
                prefix=""
                suffix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.factor}
                prefix=""
                suffix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              colSpan={2}
              align="center"
              style={{
                borderLeft: "dashed 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={1}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.factor}
                prefix=""
                suffix=""
                thousandSeparator="'"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <p className="title2">
                FCC
                <sub>
                  <em>FWD</em>
                </sub>
              </p>
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hp.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q1.hc.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hp.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q2.hc.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hp.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dotted 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q3.hc.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{ borderLeft: "dashed 1px #CCCCCC" }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hp.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderLeft: "dotted 1px #CCCCCC",
                borderRight: "dashed 1px #CCCCCC",
              }}
            >
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.marketStructure.q4.hc.fccfwd}
                prefix=""
                thousandSeparator="'"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationMarketTableComponent);
