/**
 * AppEASA
 *
 * @file TemplatesSettingsPanel.js
 * @version 1.0
 * @description document templates settings panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { setSetting } from "../../../actions/settings";
import {
  setTemplateContentBlocs,
  beginSaveTemplatesContentBloc,
  beginDeleteTemplatesContentBloc,
  beginUpdateTemplatesContentBloc,
} from "../../../actions/templatescontents";

// Other libs/components
import NumberFormat from "react-number-format";
import _ from "lodash";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CardActions from "@material-ui/core/CardActions";

class TemplatesSettingsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationFile: null,
      offerFile: null,
      isNewOfferFile: true,
      addContentBlockDialogOpen: false,
      blockContentFor: "",
      selectedBlockContent: "",
      positionX: 0,
      positionY: 0,
      blocPage: 0,
      blocIdToEdit: null,
      deleteTemplateDialogOpen: false,
      selectedTemplateToDelete: "",
      uploadFileEvent: null,
      deleteTContentBlocDialogOpen: false,
      blocIdToDelete: null,
    };
  }

  componentDidMount() {}

  // this is a trick to reset upload event in order to be able to upload, delete and re-upload the same file....
  componentDidUpdate(prevProps) {
    if (this.props.resetUpload !== prevProps.resetUpload) {
      let event = this.state.uploadFileEvent;
      if (event && event.target) {
        event.target.value = null;
      }
    }
  }

  /**
   * Template
   */
  handleSelectedFile = (fileType, exists, e) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          [fileType]: e.target.files[0],
          uploadFileEvent: e,
        };
      },
      () => {
        this.props.setTemplateContentBlocs([]);
        this.props.handleSelectedFile(fileType, exists, e);
      }
    );
  };

  handleDeleteTemplateDialogOpen = (template) => {
    this.setState({ deleteTemplateDialogOpen: true, selectedTemplateToDelete: template });
  };

  handleDeleteTemplateDialogClose = () => {
    this.setState({ deleteTemplateDialogOpen: false, selectedTemplateToDelete: "" });
  };

  handleDeleteTemplateConfirm = () => {
    this.props.handleDeleteFile(this.state.selectedTemplateToDelete);
    this.handleDeleteTemplateDialogClose();
  };

  /**
   * ADD/EDIT CONTENT BLOC
   */

  handleAddContentBlockDialogClose = () => {
    this.setState({ addContentBlockDialogOpen: false, blockContentFor: "", selectedBlockContent: "", positionX: 0, positionY: 0, blocPage: 0 });
  };

  handleAddContentBlockDialogOpen = (type) => {
    this.setState({ addContentBlockDialogOpen: true, blockContentFor: type });
  };

  addContentBlock = (type) => {
    this.props.setLoading(true);
    let body = {
      shareholderId: this.props.shareholderId,
      template: this.state.blockContentFor,
      positionX: this.state.positionX,
      positionY: this.state.positionY,
      page: this.state.blocPage,
      selectedBlockContent: this.state.selectedBlockContent,
    };

    this.props
      .beginSaveTemplatesContentBloc(body)
      .then((res) => {
        this.handleAddContentBlockDialogClose();
        this.props.setSnackbar({ open: true, severity: "success", message: "Bloc de contenu ajouté avec succès.", duration: 3000 });
        this.props.setUpSettings();
      })
      .catch((error) => {
        console.log(error);
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: error.msg, duration: 3000 });
      });
  };

  handleSelectedBlockContentChange = (e) => {
    this.setState({ selectedBlockContent: e.target.value });
  };

  handlePositionChange = (key, value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        [key]: !_.isNaN(parseFloat(value)) ? parseFloat(value) : 0,
      };
    });
  };

  handleSelectedPageChange = (e) => {
    this.setState({ blocPage: e.target.value });
  };

  handleEditContentBloc = (bloc) => {
    this.setState({
      blocIdToEdit: bloc.id,
      positionX: bloc.positionX,
      positionY: bloc.positionY,
      selectedBlockContent: bloc.key,
      blocPage: bloc.pageNumber,
      addContentBlockDialogOpen: true,
    });
  };

  updateContentBlock = (e) => {
    e.preventDefault();
    this.props.setLoading(true);

    let body = {
      blocId: this.state.blocIdToEdit,
      positionX: this.state.positionX,
      positionY: this.state.positionY,
      key: this.state.selectedBlockContent,
      pageNumber: this.state.blocPage,
    };
    this.props
      .beginUpdateTemplatesContentBloc(body)
      .then((res) => {
        this.setState(
          {
            blocIdToEdit: null,
            selectedBlockContent: "",
            positionX: 0,
            positionY: 0,
            blocPage: 0,
            addContentBlockDialogOpen: false,
          },
          () => {
            this.props.setSnackbar({ open: true, severity: "success", message: "Bloc de contenu mis à jour avec succès.", duration: 3000 });
            this.props.setUpSettings();
          }
        );
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: error.msg, duration: 3000 });
      });
  };

  /**
   * DELETE CONTENT BLOC
   */

  handleDeleteContentBlocDialogOpen = (blocId) => {
    this.setState({ deleteTContentBlocDialogOpen: true, blocIdToDelete: blocId });
  };

  handleDeleteContentBlocDialogClose = (blocId) => {
    this.setState({ deleteTContentBlocDialogOpen: false, blocIdToDelete: null });
  };

  handleDeleteContentBlocConfirm = (e) => {
    e.preventDefault();
    // console.log("handleDeleteContentBlocConfirm");
    this.props.setLoading(true);
    this.props
      .beginDeleteTemplatesContentBloc(this.state.blocIdToDelete)
      .then((res) => {
        this.handleDeleteContentBlocDialogClose();
        this.props.setSnackbar({ open: true, severity: "success", message: "Bloc de contenu supprimé avec succès.", duration: 3000 });
        this.props.setUpSettings();
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: error.msg, duration: 3000 });
      });
  };

  render() {
    // console.log("this.props.quotationFile = ", this.props.quotationFile);
    // console.log("this.props.offerFile = ", this.props.offerFile);

    let templatePages = [];
    if (this.props.offerFile)
      for (let index = 0; index < this.props.offerFile.pageCount; index++) {
        templatePages.push(index + 1);
      }

    return (
      <Fragment>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="title2"></p>
          </Grid>
          <Grid item></Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="center">
          <Grid item xs={5}>
            <p className="text bold">Modèle de document</p>
          </Grid>
          <Grid item xs={5}>
            {!_.isNull(this.props.offerFile) ? (
              <Fragment>
                <p className="text ">{this.props.offerFile && this.props.offerFile.name}</p>
                {this.props.offerFile && this.props.offerFile.pageCount ? (
                  <p className="text-small italic" style={{ marginTop: "0.5rem" }}>
                    {this.props.offerFile.pageCount} {parseInt(this.props.offerFile.pageCount) > 1 ? "pages" : "page"} -{" "}
                    <NumberFormat
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={parseFloat(this.props.offerFile.file.size) / 1000}
                      suffix=" Ko"
                    />
                  </p>
                ) : null}
              </Fragment>
            ) : (
              <p className="text-small italic">
                Aucun modèle de document n'a été enregisré, l'option de génération d'offres pour les cotation sera désactivée.
              </p>
            )}
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={3} direction="row" justify="flex-end" alignItems="center">
              <Grid>
                <input
                  accept="application/pdf"
                  style={{ display: "none" }}
                  id="offer-button-file"
                  type="file"
                  onChange={(e) => this.handleSelectedFile("offerFile", "offerFileExists", e)}
                />
                <label htmlFor="offer-button-file">
                  {!this.props.offerFileExists ? (
                    <IconButton color="primary" aria-label="upload offer" component="span" style={{ fontSize: "1.325rem" }}>
                      <DescriptionIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  ) : null}
                </label>
              </Grid>
              <Grid>
                {this.props.offerFileExists ? (
                  <IconButton color="secondary" onClick={(e) => this.handleDeleteTemplateDialogOpen("offer")}>
                    <DeleteForeverIcon fontSize="inherit" color="secondary" />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.props.offerFileExists ? (
          <Fragment>
            <Grid container spacing={3} direction="row" justify="space-between" alignItems="center" style={{ marginTop: "1rem" }}>
              <Grid item xs={10}>
                <p className="text bold">Blocs de contenus</p>
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={3} direction="row" justify="flex-end" alignItems="center">
                  <Grid>
                    <IconButton color="primary" onClick={(e) => this.handleAddContentBlockDialogOpen("offer")}>
                      <AddIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {this.props.templatescontents.length ? (
                  this.props.templatescontents &&
                  this.props.templatescontents.map((contentBloc) => {
                    let label = "";
                    switch (contentBloc.key) {
                      case "quotationDate":
                        label = "Date de l'offre";
                        break;

                      case "years":
                        label = "Durée de couverture";
                        break;

                      case "validityDuration":
                        label = "Durée de validité en jours";
                        break;

                      case "goLabel":
                        label = "Garantie d'origine";
                        break;

                      case "prospect":
                        label = "Information client";
                        break;

                      case "quattroPrices":
                        label = "Prix quattro";
                        break;

                      case "duoPrices":
                        label = "Prix duo";
                        break;

                      case "unoPrices":
                        label = "Prix uno";
                        break;

                      case "volumeMWh":
                        label = "Volume en MWh";
                        break;

                      default:
                        break;
                    }

                    return (
                      <Card key={contentBloc.id} style={{ marginBottom: "1rem" }} variant="outlined">
                        <CardContent>
                          <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={5}>
                              <p className="text-small bold">{label}</p>
                            </Grid>
                            <Grid item xs={7}>
                              <Grid container spacing={3} direction="row" alignContent="center" justify="space-between">
                                <Grid item>
                                  <p className="text-small">Position X :{contentBloc.positionX}</p>
                                </Grid>
                                <Grid item>
                                  <p className="text-small">Position Y :{contentBloc.positionY}</p>
                                </Grid>
                                <Grid item>
                                  <p className="text-small">Page : {contentBloc.pageNumber}</p>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Button size="small" color="secondary" onClick={(e) => this.handleDeleteContentBlocDialogOpen(contentBloc.id)}>
                            Supprimer
                          </Button>
                          <Button size="small" color="primary" onClick={(e) => this.handleEditContentBloc(contentBloc)}>
                            Editer
                          </Button>
                        </CardActions>
                      </Card>
                    );
                  })
                ) : (
                  <p className="text-small italic">Aucun bloc de contenu défini pour ce modèle. Cliquez sur le bouton + pour ajouter des blocs de contenus.</p>
                )}
              </Grid>
            </Grid>
          </Fragment>
        ) : null}

        {/* Add bloc content */}
        <Dialog
          open={this.state.addContentBlockDialogOpen}
          onClose={this.handleAddContentBlockDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">App EASA : contenu modèle de document</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="alert-dialog-description">
              Indiquer le type de contenu et les propritétés de ce dernier que vous souhaitez faire apparaître dans le modèle de document.
            </DialogContentText>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="bloc-content-selector-label">Bloc de contenu à afficher</InputLabel>
                  <Select
                    labelId="bloc-content-selector-label"
                    id="bloc-content-selector"
                    value={this.state.selectedBlockContent}
                    fullWidth
                    onChange={this.handleSelectedBlockContentChange}
                  >
                    <MenuItem value={"quotationDate"}>Date de l'offre</MenuItem>
                    <MenuItem value={"years"}>Durée de couverture</MenuItem>
                    <MenuItem value={"validityDuration"}>Durée de validité en jours</MenuItem>
                    <MenuItem value={"goLabel"}>Garantie d'origine</MenuItem>
                    <MenuItem value={"prospect"}>Information client</MenuItem>
                    <MenuItem value={"quattroPrices"}>Prix quattro</MenuItem>
                    <MenuItem value={"duoPrices"}>Prix duo</MenuItem>
                    <MenuItem value={"unoPrices"}>Prix uno</MenuItem>
                    <MenuItem value={"volumeMWh"}>Volume en MWh</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    id="bloc-content-postitionX"
                    label="Position X"
                    value={this.state.positionX}
                    onChange={(e) => this.handlePositionChange("positionX", e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    id="bloc-content-postitionY"
                    label="Position Y"
                    value={this.state.positionY}
                    onChange={(e) => this.handlePositionChange("positionY", e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="bloc-content-selector-label">Page</InputLabel>
                  <Select
                    labelId="bloc-content-selector-label"
                    id="bloc-content-selector"
                    value={this.state.blocPage}
                    fullWidth
                    onChange={this.handleSelectedPageChange}
                  >
                    {templatePages.map((page) => {
                      return (
                        <MenuItem key={`page_${page}`} value={page}>
                          Page {page}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAddContentBlockDialogClose} color="primary">
              Annuler
            </Button>
            {this.state.blocIdToEdit === null ? (
              <Button onClick={this.addContentBlock} color="primary" autoFocus>
                Ajouter
              </Button>
            ) : (
              <Button onClick={this.updateContentBlock} color="primary" autoFocus>
                Enregistrer
              </Button>
            )}
          </DialogActions>
        </Dialog>

        {/* Delete template */}
        <Dialog
          open={this.state.deleteTemplateDialogOpen}
          onClose={this.handleDeleteTemplateDialogClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">App EASA : Supprimer modèle de document</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="delete-dialog-description">Etes-vous sûr de vouloir supprimer ce modèle de document ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteTemplateDialogClose} color="primary">
              Annuler
            </Button>
            <Button onClick={this.handleDeleteTemplateConfirm} color="secondary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete content bloc */}
        <Dialog
          open={this.state.deleteTContentBlocDialogOpen}
          onClose={this.handleDeleteContentBlocDialogClose}
          aria-labelledby="delete-contentbloc-dialog-title"
          aria-describedby="delete-contentbloc-dialog-description"
        >
          <DialogTitle id="delete-contentbloc-dialog-title">App EASA : Supprimer bloc de contenu</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="delete-contentbloc-dialog-description">Etes-vous sûr de vouloir supprimer ce bloc de contenu ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteContentBlocDialogClose} color="primary">
              Annuler
            </Button>
            <Button onClick={this.handleDeleteContentBlocConfirm} color="secondary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.shareholderId)),
  user: state.user,
  config: state.config,
  settings: state.settings,
  templatescontents: state.templatescontents,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  setSetting: (key, value) => dispatch(setSetting(key, value)),
  setTemplateContentBlocs: (blocs) => dispatch(setTemplateContentBlocs(blocs)),
  beginSaveTemplatesContentBloc: (body) => dispatch(beginSaveTemplatesContentBloc(body)),
  beginDeleteTemplatesContentBloc: (blocId) => dispatch(beginDeleteTemplatesContentBloc(blocId)),
  beginUpdateTemplatesContentBloc: (body) => dispatch(beginUpdateTemplatesContentBloc(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesSettingsPanel);
