/**
 *
 * @file Graph.js
 * @version 1.0
 * @description Market info graphic UI component
 *
 */

// load base libs
import React, { useLayoutEffect, useRef, useState } from "react";

// import  plotly
import Plot from "react-plotly.js";

// load Material UI components
import Box from "@material-ui/core/Box";

const Graph = ({ traces, legend, graphHeight = 180 }) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(graphHeight);
  }, [graphHeight]);

  return (
    <Box sx={{ backgroundColor: "white" }} ref={ref}>
      <Plot
        data={traces}
        layout={{
          showlegend: legend,
          hovermode: "x unified",
          legend: {
            xanchor: "left",
            yanchor: "top",
            y: graphHeight === 180 ? -0.15 : -0.1,
            x: 0,
            orientation: "h",
            font: {
              size: 10,
              color: "rgb(128, 128, 128)",
            },
          },
          width: width,
          height: height,
          margin: { autoexpand: false, b: graphHeight === 180 ? 40 : 80, l: 40, r: 40, t: 0, pad: 0 },
          plot_bgcolor: "#fff",
          xaxis: {
            autorange: true,
            tickfont: {
              size: 10,
              color: "rgb(128, 128, 128)",
            },
            // range: ["2021-01-04", "2023-01-18"],
            // rangeselector: {
            //   buttons: [
            //     {
            //       count: 1,
            //       label: "1m",
            //       step: "month",
            //       stepmode: "backward",
            //     },
            //     {
            //       count: 6,
            //       label: "6m",
            //       step: "month",
            //       stepmode: "backward",
            //     },
            //     { step: "all" },
            //   ],
            // },
            // rangeslider: { range: [] },
            type: "date",
          },
          yaxis: {
            autorange: true,
            tickfont: {
              size: 10,
              color: "rgb(128, 128, 128)",
            },
            // range: [0, 500],
            type: "linear",
          },
        }}
        config={{ responsive: true, displayModeBar: false, displaylogo: false }}
      />
    </Box>
  );
};

export default Graph;
