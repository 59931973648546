/**
 * AppEASA
 *
 * @file reports.js
 * @version 1.0
 * @description reports reducer
 *
 */

// import redux action type
import { SET_REPORTS } from "../actions/types";

// set initial state
const initialState = [];

// export reducer
export default function reportsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_REPORTS:
      return payload;

    default:
      return state;
  }
}
