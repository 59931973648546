/**
 * AppEASA
 *
 * @file QuotationProspectPriceTable.js
 * @version 1.0
 * @description Customer Price table quattro/duo/uno
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";
import { beginGetQuattroPrices } from "../../actions/quotation";

// Other libs/components
import NumberFormat from "react-number-format";
import _ from "lodash";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";

class QuotationProspectPriceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      yearlyTotal: 0,
      yearlyHP: 0,
      yearlyHC: 0,
      summerTotal: 0,
      summerHP: 0,
      summerHC: 0,
      winterTotal: 0,
      winterHP: 0,
      winterHC: 0,
    };
  }

  componentDidMount() {
    //this.updatePrices();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedGo !== prevProps.selectedGo || this.props.updatePrices !== prevProps.updatePrices) {
      this.updatePrices();
    }
  }

  updatePrices = () => {
    this.props.beginGetQuattroPrices(this.props.quotation, this.props.selectedGo, this.props.years).then((response) => {
      // console.log("response = ", response);
      if (response.status === 200) {
        this.setState({
          yearlyTotal: response.data.yearlyTotal,
          yearlyHP: response.data.yearlyHP,
          yearlyHC: response.data.yearlyHC,
          summerTotal: response.data.summerTotal,
          summerHP: response.data.summerHP,
          summerHC: response.data.summerHC,
          winterTotal: response.data.winterTotal,
          winterHP: response.data.winterHP,
          winterHC: response.data.winterHC,
        });
      }
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuOpen = (e) => {
    // console.log("hanlde menu open");
    this.setState({ anchorEl: e.currentTarget });
  };

  handleGenerateOffer = (e, year) => {
    this.setState({ anchorEl: null }, () => {
      this.props.handleDownloadOffer(e, year);
    });
  };

  handleDeleteCombination = (years) => {
    this.setState({ anchorEl: null }, () => {
      this.props.handleDeleteCombinationOpen(years);
    });
  };

  handleValidateQuotation = (years) => {
    this.setState({ anchorEl: null }, () => {
      this.props.validateQuotation(years);
    });
  };

  setMarginPercentOpen = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        open: !prevState.open,
      };
    });
  };

  render() {
    // check whether or not an offer can be generated
    let offerButtonDisabled = true;
    let templateSetting = this.props.settings && this.props.settings.find((s) => s.key === "templateFiles");
    let templateSettingValue = templateSetting && JSON.parse(templateSetting.value);

    if (!_.isEmpty(templateSettingValue)) {
      offerButtonDisabled = false;
    }

    let opactity = "1"; //(this.props.quotation && this.props.quotation.status === "waiting") || !this.props.validated ? "1" : "0.7";

    if ((this.props.quotation && this.props.quotation.status === "waiting") || (this.props.quotation && this.props.quotation.status === "")) {
      opactity = "1";
    } else {
      if (this.props.validated) {
        opactity = "1";
      } else {
        opactity = "0.7";
      }
    }

    let yearLabel = "";
    if (this.props.years && this.props.years.length === 1) {
      yearLabel = this.props.years[0];
    } else if (!_.isNull(this.props.years)) {
      yearLabel = this.props.years[0] + " à " + this.props.years[this.props.years.length - 1];
    }

    return (
      <Fragment>
        <Table size="small" style={{ marginTop: "12px", opacity: opactity }}>
          <TableHead>
            <TableRow style={{ backgroundColor: this.props.validated ? "green" : "none" }}>
              <TableCell style={{ padding: "0px" }}>
                <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
                  {this.props.editable ? (
                    <Grid item style={{ padding: "0px", paddingRight: "0.5rem" }}>
                      <IconButton size="small" onClick={this.handleMenuOpen}>
                        <MenuIcon fontSize="inherit" color="primary" />
                      </IconButton>
                      <Menu id="simple-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
                        <MenuItem
                          onClick={(e) => this.handleGenerateOffer(e, this.props.years)}
                          disabled={offerButtonDisabled || (this.props.quotation && this.props.quotation.status !== "waiting")}
                        >
                          Générer offre
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => this.handleValidateQuotation(this.props.fullperiod ? null : this.props.years)}
                          disabled={this.props.quotation && this.props.quotation.status !== "waiting"}
                        >
                          Marqué comme gagné
                        </MenuItem>
                        {this.props.isCombination ? <Divider /> : null}
                        {this.props.isCombination ? (
                          <MenuItem
                            onClick={(e) => this.handleDeleteCombination(this.props.years)}
                            disabled={this.props.quotation && this.props.quotation.status !== "waiting"}
                          >
                            Supprimer regroupement
                          </MenuItem>
                        ) : null}
                      </Menu>
                    </Grid>
                  ) : null}
                  {this.props.validated ? (
                    <Grid item style={{ padding: "0px", paddingRight: "0.5rem" }}>
                      <DoneAllIcon style={{ color: "white" }} />
                    </Grid>
                  ) : null}
                  <Grid item>
                    <p className="text-small bold" style={{ color: this.props.validated ? "white" : "rgba(70, 70, 70, 0.8)" }}>
                      {yearLabel}
                    </p>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <p className="text-small bold" style={{ color: this.props.validated ? "white" : "rgba(70, 70, 70, 0.8)" }}>
                  Total
                </p>
              </TableCell>
              {this.props.pricingDisplayMode !== "uno" ? (
                <TableCell align="center">
                  <p className="text-small bold" style={{ color: this.props.validated ? "white" : "rgba(70, 70, 70, 0.8)" }}>
                    HP
                  </p>
                </TableCell>
              ) : null}

              {this.props.pricingDisplayMode !== "uno" ? (
                <TableCell align="center">
                  <p className="text-small bold" style={{ color: this.props.validated ? "white" : "rgba(70, 70, 70, 0.8)" }}>
                    HC
                  </p>
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ backgroundColor: this.props.colors.year }}>
              <TableCell style={{}}>
                <p className="text-small bold">Annuel</p>
              </TableCell>
              <TableCell align="center">
                <p className="text-small">
                  <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.yearlyTotal} prefix="" thousandSeparator="'" />
                </p>
              </TableCell>
              {this.props.pricingDisplayMode !== "uno" ? (
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.yearlyHP} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
              ) : null}

              {this.props.pricingDisplayMode !== "uno" ? (
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.yearlyHC} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
              ) : null}
            </TableRow>
            {this.props.pricingDisplayMode === "quattro" ? (
              <TableRow style={{ backgroundColor: this.props.colors.summer }}>
                <TableCell style={{}}>
                  <p className="text-small bold">Eté</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.summerTotal} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.summerHP} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.summerHC} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
              </TableRow>
            ) : null}
            {this.props.pricingDisplayMode === "quattro" ? (
              <TableRow style={{ backgroundColor: this.props.colors.winter }}>
                <TableCell style={{}}>
                  <p className="text-small bold">Hiver</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.winterTotal} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.winterHP} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small">
                    <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.state.winterHC} prefix="" thousandSeparator="'" />
                  </p>
                </TableCell>
              </TableRow>
            ) : null}

            {this.props.quotation.status !== "" ? (
              <TableRow>
                <TableCell
                  colSpan={this.props.pricingDisplayMode !== "uno" ? 4 : 2}
                  style={{ margin: "0", padding: "0", borderBottom: this.state.open ? "none" : "solid 1px #E0E0E0" }}
                >
                  <Grid container spacing={1} direction="row" alignContent="center" justify="space-between" style={{ marginTop: "0", marginBottom: "0" }}>
                    <Grid item>
                      <p className="text-small" style={{ marginTop: "3px" }}>
                        Total marge commerciale brute espérée : CHF{" "}
                        <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.props.expectedIncomes} thousandSeparator="'" />
                      </p>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={0} direction="row" alignContent="center" justify="flex-end">
                        <Grid item>
                          {this.props.markupMode === "global" ? (
                            <p className="text-small" style={{ marginRight: "6px", marginTop: "3px" }}>
                              Marge :{" "}
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={this.props.markup}
                                thousandSeparator="'"
                                suffix=" ct CHF / KWh"
                              />
                            </p>
                          ) : (
                            <p className="text-small" style={{ marginRight: "6px", marginTop: "3px" }}>
                              Marge{_.isNull(this.props.years) || (this.props.years && this.props.years.length > 1) ? " (moyenne)" : ""} :{" "}
                              <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={this.props.markup} thousandSeparator="'" />
                            </p>
                          )}
                        </Grid>
                        <Grid item>
                          <IconButton size="small" onClick={this.setMarginPercentOpen} style={{ padding: "0" }}>
                            {this.state.open ? (
                              <KeyboardArrowUpIcon fontSize="inherit" color="primary" />
                            ) : (
                              <KeyboardArrowDownIcon fontSize="inherit" color="primary" />
                            )}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <Box spacing={0} style={{ padding: "0rem" }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">
                              <p className="text-small bold">Total</p>
                            </TableCell>
                            {this.props.pricingDisplayMode !== "uno" ? (
                              <TableCell align="center">
                                <p className="text-small bold">HP</p>
                              </TableCell>
                            ) : null}

                            {this.props.pricingDisplayMode !== "uno" ? (
                              <TableCell align="center">
                                <p className="text-small bold">HC</p>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{}}>
                              <p className="text-small bold">Annuel</p>
                            </TableCell>
                            <TableCell align="center">
                              <p className="text-small">
                                <NumberFormat
                                  displayType="text"
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  value={(this.props.markup / this.state.yearlyTotal) * 100}
                                  suffix="%"
                                  thousandSeparator="'"
                                />
                              </p>
                            </TableCell>
                            {this.props.pricingDisplayMode !== "uno" ? (
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.yearlyHP) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                            ) : null}

                            {this.props.pricingDisplayMode !== "uno" ? (
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.yearlyHC) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                            ) : null}
                          </TableRow>
                          {this.props.pricingDisplayMode === "quattro" ? (
                            <TableRow>
                              <TableCell style={{}}>
                                <p className="text-small bold">Eté</p>
                              </TableCell>
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.summerTotal) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.summerHP) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.summerHC) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                            </TableRow>
                          ) : null}
                          {this.props.pricingDisplayMode === "quattro" ? (
                            <TableRow>
                              <TableCell style={{}}>
                                <p className="text-small bold">Hiver</p>
                              </TableCell>
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.winterTotal) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.winterHP) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                              <TableCell align="center">
                                <p className="text-small">
                                  <NumberFormat
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={(this.props.markup / this.state.winterHC) * 100}
                                    suffix="%"
                                    thousandSeparator="'"
                                  />
                                </p>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
  prospects: state.prospects,
  pods: state.pods,
  consumers: state.consumers,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  beginGetQuattroPrices: (quotation, selectedGo, years) => dispatch(beginGetQuattroPrices(quotation, selectedGo, years)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationProspectPriceTable);
