/**
 * AppEASA
 *
 * @file quotationsfilters.js
 * @version 1.0
 * @description quotations' filter reducer
 *
 */

// import redux action type
import { SET_QUOTATIONS_TEXT_FILTER, SET_QUOTATIONS_STATUS_FILTER, SET_QUOTATIONS_PROSPECT_FILTER } from "../actions/types";

// set initial state
const initialState = {
  text: "",
  status: "all",
  prospects: "all",
};

// export reducer
export default function quotationsFiltersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_QUOTATIONS_TEXT_FILTER:
      return {
        ...state,
        text: payload,
      };

    case SET_QUOTATIONS_STATUS_FILTER:
      return {
        ...state,
        status: payload,
      };

    case SET_QUOTATIONS_PROSPECT_FILTER:
      return {
        ...state,
        prospects: payload,
      };

    default:
      return state;
  }
}
