/**
 * @description One time password form
 * display otp field and ensure is not empty before submiting it to parent view (Login)
 */

// Base components
import React, { Component } from "react";
import _ from "lodash";

// Material UI Components
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Alert from "@material-ui/lab/Alert";

export default class OTPForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      otpErrorMsg: "",
    };
  }

  // check OTP length, update username and hide error message
  updateOTP = (e) => {
    if (e.target.value.length > 6) {
      this.setState({
        otpErrorMsg: "Le code de vérification ne peut contenir plus de 6 chiffres.",
      });
    } else {
      this.setState(
        {
          otp: e.target.value,
          otpErrorMsg: "",
        },
        () => {
          this.props.hideErrorMsg();
        }
      );
    }
  };

  // ensure otp field is not empty before submiting the otp to parent view (Login)
  onSubmit = () => {
    this.setState({ otpErrorMsg: "" }, () => {
      // check OTP is not empty
      if (_.isEmpty(this.state.otp.trim())) {
        this.setState({ otpErrorMsg: "Le code de vérification ne peut être vide." }, () => {
          return;
        });
      } else {
        // check OTP contains only digits
        let otp = this.state.otp.trim();
        let onlyDigits = /^\d+$/.test(otp);
        if (!onlyDigits || otp.length < 6) {
          this.setState({ otpErrorMsg: "Le code de vérification doit être composé de 6 chiffres." }, () => {
            return;
          });
        } else {
          this.props.submitOTPChallenge(this.state.otp);
        }
      }
    });
  };

  render() {
    let isAttemptsLimitReached = this.props.limit === 3 ? true : false;
    const methodLabel = this.props.doubleAuthMethod === "SMS" ? "SMS" : "e-mail";
    return (
      <Container style={{ padding: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <p className="text center">Veuillez saisir le code de vérification reçu par {methodLabel}.</p>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <OutlinedInput
                id="easa-otp"
                size="small"
                value={this.state.otp}
                onChange={this.updateOTP}
                error={this.state.otpError}
                inputProps={{
                  style: { textAlign: "center", fontSize: "1.5rem", padding: "0.3rem", width: "100%", letterSpacing: "1rem" },
                }}
              />
              {this.state.otpErrorMsg !== "" ? (
                <FormHelperText id="password-error-text" style={{ color: "red" }}>
                  {this.state.otpErrorMsg}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {this.props.errorMsg !== "" ? <Alert severity="error">{this.props.errorMsg}</Alert> : null}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Button color="primary" size="small" variant="contained" className="login-button" onClick={this.onSubmit} disabled={isAttemptsLimitReached}>
              Valider
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
