/**
 * AppEASA
 *
 * @file AddQuotationComponentStepOne.js
 * @version 1.0
 * @description wizzard step 1 component
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";
import { setLoading } from "../../actions/ui";
import { beginLoadConsumers } from "../../actions/consumers";
import { beginLoadPods } from "../../actions/pods";
import {
  getLCF,
  setStep2Loaded,
  setSelectedProspect,
  setNewProspectNumber,
  setNewProspectName,
  setNewProspectAddress1,
  setNewProspectAddress2,
  setNewProspectZipcode,
  setNewProspectLocation,
  setSelectedPod,
  setSelectedPriceType,
  setQuatoationMarketStructure,
  setSelectedFCCQuatro,
  setVolume,
} from "../../actions/quotation";

// load other libs
import _ from "lodash";

// load UI components
import QuotationFccFactorsTable from "./QuotationFccFactorsTable";
import QuotationMarketTableComponent from "./QuotationMarketTableComponent";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

class AddQuotationComponentStepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketTableExpanded: false,
      selectedProspect: "unselected",
      selectedPod: "unselected",
      selectedPriceType: "unselected",
      prospectPartnerNumber: "",
      prospectName: "",
      prospectAddress1: "",
      prospectAddress2: "",
      prospectZipcode: "",
      prospectLocation: "",
    };
  }

  componentDidMount() {
    //
    this.props.beginLoadConsumers();
    if (this.props.quotation.selectedProspect !== "unselected")
      this.setState({
        selectedProspect: this.props.quotation.selectedProspect,
        prospectPartnerNumber: this.props.quotation.prospectNumber,
        prospectName: this.props.quotation.prospectName,
        prospectAddress1: this.props.quotation.prospectAddress1,
        prospectAddress2: this.props.quotation.prospectAddress2,
        prospectZipcode: this.props.quotation.prospectZipcode,
        prospectLocation: this.props.quotation.prospectLocation,
      });

    if (this.props.quotation.selectedPod !== "unselected")
      this.setState({
        selectedPod: this.props.quotation.selectedPod,
      });

    if (this.props.quotation.selectedPriceType !== "unselected")
      this.setState({
        selectedPriceType: this.props.quotation.selectedPriceType,
      });

    this.props.setStep2Loaded(false);
  }

  handleProspectChange = (e) => {
    this.setState(
      {
        selectedProspect: e.target.value,
        selectedPod: "unselected",
        selectedPriceType: "unselected",
      },
      () => {
        // console.log("handleCustomerChange state = ", this.state);
        this.props.setSelectedProspect(this.state.selectedProspect).then(() => {
          this.state.selectedProspect !== "unselected" && this.state.selectedProspect !== "new" && this.props.beginLoadPods(this.state.selectedProspect);
          this.props.setSelectedPod(this.state.selectedPod);
          this.props.setSelectedPriceType(this.state.selectedPriceType);
        });
      }
    );
  };

  handlePODChange = (e) => {
    // console.log("handlePODChange...", e.target.value);
    this.setState({ selectedPod: e.target.value, selectedPriceType: "unselected" }, () => {
      this.props.setSelectedPod(this.state.selectedPod).then(() => {
        this.props.setSelectedPriceType(this.state.selectedPriceType).then(() => {
          if (this.state.selectedPod !== "pricetype" && this.state.selectedPod !== "unselected") {
            this.updateLCF();
          } else {
            this.props.handleStepCompleted("step1Completed", false);
          }
        });
      });
    });
  };

  handlePriceTypeChange = (e) => {
    this.setState({ selectedPriceType: e.target.value }, () => {
      this.props.setSelectedPriceType(this.state.selectedPriceType).then(() => {
        if (this.state.selectedPriceType !== "unselected") {
          this.updateLCF();
        } else {
          this.props.handleStepCompleted("step1Completed", false);
        }
      });
    });
  };

  toggleMarketTableDisplay = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        marketTableExpanded: !prevState.marketTableExpanded,
      };
    });
  };

  /**
   * Process load curve factors
   */
  updateLCF = () => {
    this.props.setLoading(true);

    let body = {
      shareholderId: this.props.shareholder.id,
      selectedProspect: this.props.quotation.selectedProspect,
      selectedPod: this.props.quotation.selectedPod,
      selectedPriceType: this.props.quotation.selectedPriceType,
    };
    this.props.getLCF(body).then(() => {
      this.props.handleStepCompleted("step1Completed", true);
      this.props.setLoading(false);
    });
  };

  render() {
    let prospectInfo =
      this.props.quotation.selectedProspect !== "unselected" &&
      this.props.quotation.selectedProspect !== "new" &&
      this.props.prospects.find((p) => p.id === this.props.quotation.selectedProspect);
    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid>
                <p className="title2">Client</p>
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select id="prospectId" value={this.state.selectedProspect} fullWidth onChange={this.handleProspectChange}>
                    <MenuItem value={"unselected"} style={{ fontStyle: "italic" }}>
                      Sélectionnez un client
                    </MenuItem>
                    <MenuItem value={"new"} style={{ fontStyle: "italic", fontWeight: "bold" }}>
                      Nouveau client
                    </MenuItem>
                    {this.props.prospects &&
                      this.props.prospects.map((prospect, index) => {
                        return (
                          <MenuItem key={index} value={prospect.id}>
                            {prospect.name}, {prospect.location}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {this.state.selectedProspect === "new" ? (
              <Fragment>
                <Grid container spacing={0} style={{ marginTop: "2rem" }}>
                  <Grid item xs={12}>
                    <p className="title2">Coordonnées nouveau prospect</p>
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel">Numéro partenaire</p>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="prospect-partnernumber-field"
                      value={this.state.prospectPartnerNumber}
                      fullWidth
                      onChange={(e) =>
                        this.setState({ prospectPartnerNumber: e.target.value }, () => this.props.setNewProspectNumber(this.state.prospectPartnerNumber))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel">Nom du prospect</p>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="prospect-name-field"
                      value={this.state.prospectName}
                      fullWidth
                      onChange={(e) => this.setState({ prospectName: e.target.value }, () => this.props.setNewProspectName(this.state.prospectName))}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel">Adresse</p>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="prospect-address1-field"
                      value={this.state.prospectAddress1}
                      fullWidth
                      onChange={(e) =>
                        this.setState({ prospectAddress1: e.target.value }, () => this.props.setNewProspectAddress1(this.state.prospectAddress1))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel"></p>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="prospect-address2-field"
                      value={this.state.prospectAddress2}
                      fullWidth
                      onChange={(e) =>
                        this.setState({ prospectAddress2: e.target.value }, () => this.props.setNewProspectAddress2(this.state.prospectAddress2))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={4}>
                    <p className="inputlabel">NPA &amp; Lieu</p>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="prospect-zipcode-field"
                      value={this.state.prospectZipcode}
                      onChange={(e) => this.setState({ prospectZipcode: e.target.value }, () => this.props.setNewProspectZipcode(this.state.prospectZipcode))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="prospect-location-field"
                      value={this.state.prospectLocation}
                      fullWidth
                      onChange={(e) =>
                        this.setState({ prospectLocation: e.target.value }, () => this.props.setNewProspectLocation(this.state.prospectLocation))
                      }
                      style={{ paddingLeft: "1rem" }}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}
            {this.state.selectedProspect !== "new" && this.state.selectedProspect !== "unselected" ? (
              <Fragment>
                <Grid container spacing={0} style={{ marginTop: "2rem" }}>
                  <Grid item xs={12}>
                    <p className="title2">Informations client</p>
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={6}>
                    <p className="text">Numéro de partenaire :</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className="text">{prospectInfo && !_.isEmpty(prospectInfo.partnerNumber) ? prospectInfo.partnerNumber : "n/a"}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={6}>
                    <p className="text">Coordonnées :</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className="text">{prospectInfo && !_.isEmpty(prospectInfo.address1) ? prospectInfo.address1 : "n/a"}</p>
                    {prospectInfo && !_.isEmpty(prospectInfo.address1) ? <p className="text">{prospectInfo.address2}</p> : "n/a"}
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "0.5rem" }}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <p className="text">
                      {prospectInfo && !_.isEmpty(prospectInfo.zipcode) && !_.isEmpty(prospectInfo.location)
                        ? prospectInfo.zipcode + " " + prospectInfo.location
                        : "n/a"}
                    </p>
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            {this.state.selectedProspect !== "unselected" && this.state.selectedProspect !== "new" ? (
              <Fragment>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <p className="title2">Profil de tarifs</p>
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select id="podId" value={this.state.selectedPod} fullWidth onChange={this.handlePODChange}>
                        <MenuItem value={"unselected"}>Sélectionnez un POD ou une tarification par branche</MenuItem>
                        <MenuItem value={"pricetype"}>Cotation avec prix par branche</MenuItem>
                        {this.props.pods &&
                          this.props.pods.map((pod, index) => {
                            return (
                              <MenuItem key={index} value={pod.id}>
                                {pod.number}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}
            {this.state.selectedPod === "pricetype" || this.state.selectedProspect === "new" ? (
              <Fragment>
                <Grid
                  container
                  spacing={0}
                  style={{
                    marginTop: this.state.selectedProspect !== "new" ? "2rem" : "0rem",
                  }}
                >
                  <Grid item xs={12}>
                    <p className="title2">Tarification (prix par branche)</p>
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select id="pricingType" value={this.state.selectedPriceType} fullWidth onChange={this.handlePriceTypeChange}>
                        <MenuItem value={"unselected"}>Sélectionnez un secteur d'activité</MenuItem>
                        {this.props.consumers &&
                          this.props.consumers.map((consumer, index) => {
                            return (
                              <MenuItem key={index} value={consumer.id}>
                                {consumer.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {(this.state.selectedProspect === "new" && this.state.selectedPod === "unselected" && this.state.selectedPriceType !== "unselected") ||
            (this.state.selectedProspect !== "unselected" &&
              this.state.selectedProspect !== "new" &&
              this.state.selectedPod !== "unselected" &&
              this.state.selectedPod !== "pricetype" &&
              this.state.selectedPriceType === "unselected") ||
            (this.state.selectedProspect !== "unselected" &&
              this.state.selectedProspect !== "new" &&
              this.state.selectedPod === "pricetype" &&
              this.state.selectedPriceType !== "unselected") ? (
              <Fragment>
                <Grid container spacing={0} style={{ marginTop: "2rem" }}>
                  <Grid item xs={12}>
                    <p className="title2">Facteurs de courbe de charge utilisés pour la cotation</p>
                  </Grid>
                </Grid>
                <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <QuotationFccFactorsTable colors={this.props.colors} fccQuattro={this.props.fccQuattro} />
                  </Grid>
                </Grid>

                <Grid container spacing={0} style={{ marginTop: "2rem" }} direction="row" justify="flex-start" alignItems="center">
                  {this.state.marketTableExpanded ? (
                    <Fragment>
                      <Grid item>
                        <IconButton size="small" onClick={this.toggleMarketTableDisplay}>
                          <VisibilityOffIcon fontSize="inherit" color="primary" />
                        </IconButton>
                      </Grid>
                      <Grid item style={{ paddingLeft: "0.5rem" }}>
                        <p className="text-small">Masquer détails marché</p>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item>
                        <IconButton size="small" onClick={this.toggleMarketTableDisplay}>
                          <VisibilityIcon fontSize="inherit" color="primary" />
                        </IconButton>
                      </Grid>
                      <Grid item style={{ paddingLeft: "0.5rem" }}>
                        <p className="text-small">Afficher détails marché</p>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>

                <Grid>
                  <Grid>
                    <Collapse in={this.state.marketTableExpanded} timeout="auto" unmountOnExit>
                      <Grid container spacing={3} style={{ marginTop: "2rem" }}>
                        <Grid item xs={12}>
                          <p className="title2">Consomation &amp; structure du marché</p>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <QuotationMarketTableComponent />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
  prospects: state.prospects,
  pods: state.pods,
  consumers: state.consumers,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  getLCF: (shareholderId) => dispatch(getLCF(shareholderId)),
  setStep2Loaded: (isLoaded) => dispatch(setStep2Loaded(isLoaded)),
  beginLoadConsumers: () => dispatch(beginLoadConsumers()),
  beginLoadPods: (prospectId) => dispatch(beginLoadPods(prospectId)),
  setSelectedProspect: (prospecId) => dispatch(setSelectedProspect(prospecId)),
  setNewProspectNumber: (number) => dispatch(setNewProspectNumber(number)),
  setNewProspectName: (name) => dispatch(setNewProspectName(name)),
  setNewProspectAddress1: (address1) => dispatch(setNewProspectAddress1(address1)),
  setNewProspectAddress2: (address2) => dispatch(setNewProspectAddress2(address2)),
  setNewProspectZipcode: (zipcode) => dispatch(setNewProspectZipcode(zipcode)),
  setNewProspectLocation: (location) => dispatch(setNewProspectLocation(location)),
  setSelectedPod: (podId) => dispatch(setSelectedPod(podId)),
  setSelectedPriceType: (priceTypeId) => dispatch(setSelectedPriceType(priceTypeId)),
  setQuatoationMarketStructure: (data) => dispatch(setQuatoationMarketStructure(data)),
  setSelectedFCCQuatro: (fcc) => dispatch(setSelectedFCCQuatro(fcc)),
  setVolume: (volume) => dispatch(setVolume(volume)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuotationComponentStepOne);
