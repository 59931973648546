/**
 * AppEASA
 *
 * @file users.js
 * @version 1.0
 * @description redux users's actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { DELETE_USER, SET_USERS, UPDATE_USER } from "./types";

// load users
export const beginLoadUsers = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/users", config);
      DEBUG && console.log("GET /api/users res = ", res.data);

      if (res.status === 200) {
        dispatch(setUsers(res.data.users));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des utilisateurs"],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      const errors = err.response.data.errors;
      reject({ loginStatus: false, msg: errors[0].msg });
    }
  });

// set users
export const setUsers = (users) => ({
  type: SET_USERS,
  payload: users,
});

// begin save user
export const beginSaveUser = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios.post("/api/users", body, config).then((result) => {
        DEBUG && console.log("POST /api/users res = ", result.data);

        if (result.status === 200) {
          dispatch(saveUser(result.data.user));
          resolve({ status: result.status });
        } else {
          let err = {
            response: {
              data: {
                errors: ["Impossible d'enregistrer le nouvel utilisateur."],
              },
            },
          };
          throw err;
        }
      });
    } catch (err) {
      const errors = err.response.data.errors;
      reject({ err, msg: errors[0].msg });
    }
  });

// save user
export const saveUser = (user) => ({
  type: SET_USERS,
  payload: user,
});

// update users
export const beginUpdateUser = (id, update) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios.put("/api/users/" + id, update, config).then((result) => {
        DEBUG && console.log("PUT /api/users res = ", result.data);
        dispatch(updateUser(id, result.data.user));
        resolve({ status: result.status });
      });
    } catch (err) {
      // console.log("ACTION update user err:");
      // console.log(err);
      reject(err.response);
    }
  });

// update user
export const updateUser = (id, update) => ({
  type: UPDATE_USER,
  id,
  update,
});

// delete user
export const beginDeleteUser = (id) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios.delete("/api/users/" + id, config).then((result) => {
        DEBUG && console.log("DELETE /api/users res = ", result.data);
        dispatch(deleteUser(id));
        resolve({ status: result.status });
      });
    } catch (err) {
      reject(err.response);
    }
  });

// delete user
export const deleteUser = (id) => ({
  type: DELETE_USER,
  payload: id,
});
