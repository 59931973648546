/**
 * AppEASA
 *
 * @file HistoryPricesAccess.js
 * @version 1.0
 * @description admin check access component for history prices
 *
 */

// base component
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// load components
import Can from "../../rbac/Can";

// load UI Components
import HistoryPrices from "./HistoryPrices";
import RestrictedAccess from "../../rbac/RestrictedAccess";

class HistoryPricesAccess extends Component {
  render() {
    return (
      <Can
        permissions={this.props.user.permissions}
        action={"history-prices:access"}
        yes={() => {
          return <HistoryPrices />;
        }}
        no={() => <RestrictedAccess />}
      />
    );
  }
}

HistoryPricesAccess.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  //navbar: state.navbar,
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPricesAccess);
