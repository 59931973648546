/**
 * AppEASA
 *
 * @file breadcrumb.js
 * @version 1.0
 * @description breadcrumb reducer
 *
 */

// import redux action type
import { SET_BREADCRUMB } from "../actions/types";

// set initial state
const initialState = [];

// export reducer
export default function breadCrumReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_BREADCRUMB:
      return payload;

    default:
      return state;
  }
}
