/**
 * AppEASA
 *
 * @file index.js
 * @version 1.0
 * @description export combined reducers used in the app
 *
 */

// load combine reducers
import { combineReducers } from "redux";

// import redux reducers
import ui from "./ui";
import snackbar from "./snackbar";
import breadcrumb from "./breadcrumb";
import auth from "./auth";
import user from "./user";
import users from "./users";
import roles from "./roles";
import config from "./appconfig";
import settings from "./settings";
import shareholders from "./shareholders";
import consumers from "./consumers";
import prospects from "./prospects";
import pods from "./pods";
import prices from "./prices";
import quotation from "./quotation";
import quotations from "./quotations";
import quotationsfilters from "./quotationsfilters";
import templatescontents from "./templatescontents";
import marketstructures from "./marketstructures";
import volatilities from "./volatilities";
import historyprices from "./historyprices";
import reports from "./reports";

// export combined reducers
export default combineReducers({
  ui,
  snackbar,
  breadcrumb,
  auth,
  user,
  config,
  consumers,
  marketstructures,
  prospects,
  pods,
  prices,
  quotation,
  quotations,
  quotationsfilters,
  reports,
  roles,
  settings,
  shareholders,
  templatescontents,
  users,
  volatilities,
  historyprices,
});
