/**
 * AppEASA
 *
 * @file EditQuotation.js
 * @version 1.0
 * @description edit quotation
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// Redux functions
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { beginLoadSettings } from "../../../actions/settings";
import {
  setShareholder,
  setReference,
  setSelectedProspect,
  setSelectedPod,
  setSelectedPriceType,
  setQuatoationMarketStructure,
  setSelectedFCCQuatro,
  setSelectedYears,
  setSelectedMarketEnergyPrices,
  setMarketEnergyPricesDate,
  setMarketEnergyPricesTime,
  setMarketEnergyPricesType,
  setSelectedEnergyPrices,
  setSelectedEnergyPrice,
  setSelectedGo,
  setSelectedGoPrices,
  setSelectedMarketGoPrices,
  setMarketGoPricesDate,
  setMarketGoPricesTime,
  setSelectedGoPrice,
  setSelectedGoCustomName,
  setMarketAdjustmentCosts,
  setAdjustmentCosts,
  setAdjustmentCost,
  setMarketRiskCosts,
  setRiskCosts,
  setRiskCost,
  setMarkupMode,
  setYearlyMarkups,
  setMarkup,
  setValidityDuration,
  setValidityMarkup,
  setValidityCost,
  setVolume,
  setStatus,
  beginDeleteQuotation,
  beginSaveCombination,
  setCombinations,
  beginDeleteCombination,
  beginValidateQuotation,
  beginUnValidateQuotation,
  beginSwapGo,
  beginUpdateMarkup,
  beginUpdateVolume,
} from "../../../actions/quotation";
import { beginLoadQuotations } from "../../../actions/quotations";
import { beginLoadPods } from "../../../actions/pods";

import NumberFormat from "react-number-format";
import QuotationProspectPriceTable from "../../quotation/QuotationProspectPriceTable";
import QuotationYearlyMarginTable from "../../quotation/QuotationYearlyMarginTable";
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

import _ from "lodash";
import moment from "moment";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
// import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Divider from "@material-ui/core/Divider";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import UpdateIcon from "@material-ui/icons/Update";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import TextField from "@material-ui/core/TextField";
// import EditIcon from "@material-ui/icons/Edit";
// import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import ShuffleIcon from "@material-ui/icons/Shuffle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      mask="_"
      fixedDecimalScale={true}
      decimalScale={3}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ fontSize: "0.8rem", textAlign: "center" }}
    />
  );
}

class EditQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorElDisplay: null,
      deleteQuotationDialogOpen: false,
      combinationsDialogOpen: false,
      combinationYears: [],
      deleteCombinationsDialogOpen: false,
      combinationToDelete: null,
      pricingDisplayMode: "quattro",
      quotationRefLabel: "",
      colors: {
        year: "rgb(236, 240, 222)",
        summer: "rgb(252, 249, 206)",
        winter: "rgb(219, 239, 243)",
      },
      validateQuotationDialogOpen: false,
      validateDialogLabel: "",
      yearsToValidate: null,
      typeToValidate: null,
      unValidateQuotationDialogOpen: false,
      goSwitchMenuAnchor: null,
      customGoDialogOpen: false,
      selectedGo: null,
      goPrices: [],
      goCustomName: "",
      goPrice: 0,
      updateMarkupDialogOpen: false,
      markupMode: "global",
      markup: 0,
      yearlyMarkups: [],
      updateVolumeDialogOpen: false,
      newVolume: 0,
      years: [],
      updatePrices: false,
    };
  }

  componentDidMount() {
    if (!this.props.selectedQuotation) {
      this.props.history.push(`/quotations/${this.props.match.params.id}`);
      return;
    }
    //console.log("this.props.selectedQuotation", this.props.selectedQuotation);
    this.props.setLoading(true);
    this.setState({
      quotationRefLabel: this.props.selectedQuotation.reference,
    });

    this.props.beginLoadSettings(this.props.selectedQuotation.shareholderId).then(() => {
      let displayPricesModeSetting = this.props.settings.find((s) => s.key === "displayPricesMode");
      this.setState({ pricingDisplayMode: displayPricesModeSetting && displayPricesModeSetting.value ? displayPricesModeSetting.value : "quattro" });
    });

    this.setUpCotation();

    // set breadcrumb
    let redirectTo = this.props.user && this.props.user.role === "admin" ? "/dashboard" : "/shareholders";
    this.props.setBreadcrumb([
      {
        label: this.props.user && this.props.user.role === "admin" ? "Tableau de bord" : "Actionnaires",
        link: redirectTo,
      },
      {
        label: `Cotations ${this.props.shareholder.name}`,
        link: `/quotations/${this.props.shareholder.id}`,
      },
      { label: `Editer cotation ${this.props.selectedQuotation && this.props.selectedQuotation.reference}`, link: null },
    ]);
  }

  setUpCotation = () => {
    // load data into quotation redux state
    this.props.setShareholder(this.props.selectedQuotation.shareholderId);
    this.props.beginLoadPods(this.props.selectedQuotation.prospectId);

    this.props.setSelectedProspect(this.props.selectedQuotation.prospectId);
    this.props.setReference(this.props.selectedQuotation.reference);
    this.props.setSelectedPod(this.props.selectedQuotation.selectedPod);
    this.props.setSelectedPriceType(this.props.selectedQuotation.selectedPriceType);
    this.props.setQuatoationMarketStructure(JSON.parse(this.props.selectedQuotation.marketStructure));
    this.props.setSelectedFCCQuatro(JSON.parse(this.props.selectedQuotation.fccQuattro));
    this.props
      .setSelectedYears(this.props.selectedQuotation.startYear, this.props.selectedQuotation.endYear, JSON.parse(this.props.selectedQuotation.years))
      .then(() => {
        this.setState({ years: JSON.parse(this.props.selectedQuotation.years) });
      });
    this.props.setSelectedMarketEnergyPrices(JSON.parse(this.props.selectedQuotation.marketEnergyPrices));
    this.props.setMarketEnergyPricesDate(this.props.selectedQuotation.marketEnergyPricesDate);
    this.props.setMarketEnergyPricesTime(this.props.selectedQuotation.marketEnergyPricesTime);
    this.props.setMarketEnergyPricesType(this.props.selectedQuotation.marketEnergyPricesType);
    this.props.setSelectedEnergyPrices(JSON.parse(this.props.selectedQuotation.energyPrices));
    this.props.setSelectedEnergyPrice(parseFloat(this.props.selectedQuotation.energyPrice));
    this.props.setSelectedMarketGoPrices(JSON.parse(this.props.selectedQuotation.marketGoPrices));
    this.props.setMarketGoPricesDate(this.props.selectedQuotation.marketGoPricesDate);
    this.props.setMarketGoPricesTime(this.props.selectedQuotation.marketGoPricesTime);
    this.props.setSelectedGo(this.props.selectedQuotation.selectedGo).then(() => {
      this.setState({ selectedGo: this.props.selectedQuotation.selectedGo });
    });
    this.props.setSelectedGoPrices(JSON.parse(this.props.selectedQuotation.goPrices));
    this.props.setSelectedGoPrice(parseFloat(this.props.selectedQuotation.goPrice));
    this.props.setSelectedGoCustomName(this.props.selectedQuotation.goCustomName);
    this.props.setMarketAdjustmentCosts(JSON.parse(this.props.selectedQuotation.marketAdjustmentCosts));
    this.props.setAdjustmentCosts(JSON.parse(this.props.selectedQuotation.adjustmentCosts));
    this.props.setAdjustmentCost(parseFloat(this.props.selectedQuotation.adjustmentCost));
    this.props.setMarketRiskCosts(JSON.parse(this.props.selectedQuotation.marketRiskCosts));
    this.props.setRiskCosts(JSON.parse(this.props.selectedQuotation.riskCosts));
    this.props.setRiskCost(parseFloat(this.props.selectedQuotation.riskCost));

    this.props.setMarkupMode(this.props.selectedQuotation.markupMode).then(() => {
      this.setState({
        markupMode: this.props.quotation.markupMode,
      });
    });
    this.props.setYearlyMarkups(JSON.parse(this.props.selectedQuotation.yearlyMarkups)).then(() => {
      this.setState({
        yearlyMarkups: this.props.quotation.yearlyMarkups,
      });
    });
    this.props.setMarkup(parseFloat(this.props.selectedQuotation.markup)).then(() => {
      this.setState({
        markup: this.props.quotation.markup,
      });
    });
    this.props.setValidityDuration(this.props.selectedQuotation.validityDuration);
    this.props.setValidityMarkup(parseFloat(this.props.selectedQuotation.validityMarkup));
    this.props.setValidityCost(parseFloat(this.props.selectedQuotation.validityCost));
    this.props.setVolume(parseFloat(this.props.selectedQuotation.volumeMWh));
    this.props.setStatus(this.props.selectedQuotation.status);
    this.props.setCombinations(JSON.parse(this.props.selectedQuotation.combinations));

    this.props.setLoading(false);
  };

  /**
   * Display Menu
   */
  handleActionDisplayMenuClick = (e) => {
    this.setState({ anchorElDisplay: e.currentTarget });
  };

  handleActionDisplayMenuClose = (e) => {
    this.setState({ anchorElDisplay: null });
  };

  handlePricingDisplayChange = (displayMode) => {
    this.setState({ pricingDisplayMode: displayMode, anchorElDisplay: null });
  };

  /**
   * Action Menu
   */
  handleActionMenuClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleActionMenuClose = (e) => {
    this.setState({ anchorEl: null });
  };

  /**
   * Delete
   */
  handleDeleteQuotationOpen = () => {
    this.setState({ deleteQuotationDialogOpen: true, anchorEl: null });
  };

  handleDeleteQuotationClose = () => {
    this.setState({ deleteQuotationDialogOpen: false });
  };

  handleDeleteQuotation = (e) => {
    e.preventDefault();
    this.props.setLoading(true);

    this.props
      .beginDeleteQuotation(this.props.selectedQuotation.id)
      .then(() => {
        this.setState({ deleteQuotationDialogOpen: false });
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: "Cotation supprimée avec succès", duration: 3000 });
        if (this.props.user && this.props.user.role && this.props.user.role === "shareholder") {
          this.props.history.push(`/quotations`);
        } else {
          this.props.history.push(`/quotations/${this.props.shareholder && this.props.shareholder.id}`);
        }
      })
      .catch((error) => {
        console.log("handleDeleteQuotation error = ", error.errors);
        this.setState({ deleteQuotationDialogOpen: false });
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Erreur lors de la suppression de la cotation.", duration: 3000 });
      });
  };

  /**
   * Download documents
   */
  handleDownloadQuotation = (e) => {
    e.preventDefault();

    // close menu
    this.handleActionMenuClose();

    // enable loading
    this.props.setLoading(true);

    // const today = moment(new Date()).format("DD-MM-YYYY_HHmmss");
    let fileName = `App-EASA-Cotation ${this.props.selectedQuotation.reference}.pdf`;
    fileName = fileName.replace(/\s/g, "-");

    const body = {
      quotationId: this.props.selectedQuotation.id,
      fileName,
      priceMode: this.state.pricingDisplayMode,
    };
    // console.log("handleDownloadQuotation body = ", body);
    fetch("/api/quotation/getquotation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "x-auth-token": localStorage.token,
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        // disable loading
        this.props.setLoading(false);

        link.click();
      })
      .catch(() => {
        // disable loading
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Impossible de générer le fichier de cotation", duration: 3000 });
      });
  };

  handleDownloadOffer = (e, year) => {
    e.preventDefault();
    // console.log("handleDownloadOffer");
    // // enable loading
    this.props.setLoading(true);

    // const today = moment(new Date()).format("DD-MM-YYYY_HHmmss");
    let fileName = `Offre ${this.props.selectedQuotation.reference}.pdf`;
    fileName = fileName.replace(/\s/g, "-");

    const body = {
      quotationId: this.props.selectedQuotation.id,
      fileName,
      priceMode: this.state.pricingDisplayMode,
      year,
    };
    console.log("handleDownloadQuotation body = ", body);
    fetch("/api/quotation/getoffer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "x-auth-token": localStorage.token,
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        // disable loading
        this.props.setLoading(false);

        link.click();
      })
      .catch(() => {
        // disable loading
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Impossible de générer le fichier de cotation", duration: 3000 });
      });
  };

  /**
   * Combinations
   */
  handleCreateCombinationsOpen = () => {
    this.setState({ combinationsDialogOpen: true, anchorEl: null });
  };

  handleCreateCombinationsClose = () => {
    this.setState({ combinationsDialogOpen: false, combinationYears: [] });
  };

  handleCreateCombinations = (e) => {
    // check whether or not selected years are following each other
    let selectedYears = _.sortBy(this.state.combinationYears);

    let startingYear = selectedYears[0];
    let isCombinationDoable = true;
    for (let index = 0; index < selectedYears.length; index++) {
      const currentSelectedYear = selectedYears[index];
      if (currentSelectedYear !== startingYear + index * 1) {
        isCombinationDoable = false;
        continue;
      }
    }

    if (!isCombinationDoable) {
      return this.props.setSnackbar({ open: true, severity: "error", message: "Les années doivent être continues.", duration: 3000 });
    }

    // check whether or not the selected combination already exists
    for (let index = 0; index < this.props.quotation.combinations.length; index++) {
      const currentCombination = this.props.quotation.combinations[index];

      let combinationToCompare = JSON.parse(currentCombination);

      if (_.isEqual(selectedYears.sort(), combinationToCompare.sort())) {
        isCombinationDoable = false;
      }
    }

    if (!isCombinationDoable) {
      return this.props.setSnackbar({ open: true, severity: "error", message: "Ce regroupement d'années existe déjà.", duration: 3000 });
    }

    // everything looks good, go ahead
    let data = {
      quotationId: this.props.selectedQuotation.id,
      combination: JSON.stringify(_.sortBy(this.state.combinationYears)),
    };

    this.props.setLoading(true);
    this.props
      .beginSaveCombination(data)
      .then(() => {
        this.handleCreateCombinationsClose();
        this.props
          .beginLoadQuotations(this.props.quotation.shareholder)
          .then(() => {
            this.setUpCotation();
            this.props.setLoading(false);
            this.props.setSnackbar({ open: true, severity: "success", message: "Nouveau regroupement enregistré avec succès.", duration: 3000 });
          })
          .then(() => {
            this.setState((prevState) => {
              return {
                updatePrices: !prevState.updatePrices,
              };
            });
          })
          .catch((err) => {
            this.props.setLoading(false);
            this.props.history.push(`/quotations/${this.props.match.params.id}`);
            return;
          });
      })
      .catch((err) => {
        console.log("err = ", err);
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: err.err.response.data[0], duration: 3000 });
      });
  };

  addRemoveYearToCombination = (year) => {
    if (_.includes(this.state.combinationYears, year)) {
      this.setState((prevState) => {
        return {
          ...prevState,
          combinationYears: prevState.combinationYears.filter((y) => y !== year),
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          combinationYears: [...prevState.combinationYears, year],
        };
      });
    }
  };

  handleDeleteCombinationOpen = (combination) => {
    console.log("combination = ", combination);
    this.setState({ combinationToDelete: combination, deleteCombinationsDialogOpen: true }, () => {
      console.log(this.state);
    });
  };

  handleDeleteCombinationClose = () => {
    this.setState({ combinationToDelete: [], deleteCombinationsDialogOpen: false });
  };

  handleDeleteCombination = () => {
    // everything looks good, go ahead
    let data = {
      quotationId: this.props.selectedQuotation.id,
      combination: JSON.stringify(this.state.combinationToDelete),
    };
    console.log("data = ", data);
    this.props.setLoading(true);
    this.props
      .beginDeleteCombination(data)
      .then(() => {
        this.handleDeleteCombinationClose();
        this.props
          .beginLoadQuotations(this.props.quotation.shareholder)
          .then(() => {
            this.setUpCotation();
            this.props.setLoading(false);
            this.props.setSnackbar({ open: true, severity: "success", message: "Regroupement supprimé avec succès.", duration: 3000 });
          })
          .catch((err) => {
            this.props.setLoading(false);
            this.props.history.push(`/quotations/${this.props.match.params.id}`);
            return;
          });
      })
      .catch((err) => {
        console.log("err = ", err);
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: err.err.response.data[0], duration: 3000 });
      });
  };

  /**
   * Update quotation
   */
  handleUpdateQuotationOpen = () => {
    console.log("handleUpdateQuotationOpen...");
  };

  /**
   * Validate quotation
   */
  validateQuotationOpen = (years) => {
    // console.log("validateQuotationOpen...", years);
    let validateDialogLabel = "";
    let typeToValidate = "";

    if (_.isNull(years)) {
      validateDialogLabel = `l'ensemble de la periode (de ${this.props.quotation.startYear} à ${this.props.quotation.endYear})`;
      typeToValidate = "full";
    } else if (_.isArray(years) && years.length === 1) {
      validateDialogLabel = `l'année ${years[0]}`;
      typeToValidate = "year";
    } else if (_.isArray(years) && years.length > 1) {
      validateDialogLabel = `les années de ${years[0]} à ${years[years.length - 1]}`;
      typeToValidate = "combination";
    }
    this.setState({ yearsToValidate: years, typeToValidate, validateDialogLabel, validateQuotationDialogOpen: true });
  };

  handleValidateQuotationClose = () => {
    this.setState({ validateQuotationDialogOpen: false, yearsToValidate: null, typeToValidate: null, validateDialogLabel: "" });
  };

  handleValidateQuotation = (e) => {
    e.preventDefault();
    // console.log("handleValidateQuotation...");
    this.props.setLoading(true);
    let body = {
      quotationId: this.props.selectedQuotation.id,
      yearsToValidate: _.isNull(this.state.yearsToValidate) ? this.state.yearsToValidate : JSON.stringify(this.state.yearsToValidate),
    };
    this.props
      .beginValidateQuotation(body)
      .then((res) => {
        this.props
          .beginLoadQuotations(this.props.quotation.shareholder)
          .then((res) => {
            this.setUpCotation();
            this.handleValidateQuotationClose();
            this.props.setLoading(false);
            this.props.setSnackbar({ open: true, severity: "success", message: "Quotation validée avec succès.", duration: 3000 });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: err.err.response.data[0], duration: 3000 });
      });
  };

  handleUnValidateQuotationtOpen = () => {
    this.setState({ unValidateQuotationDialogOpen: true, anchorEl: null });
  };

  handleUnValidateQuotationtClose = () => {
    this.setState({ unValidateQuotationDialogOpen: false });
  };

  handleUnValidateQuotationt = (e) => {
    e.preventDefault();
    // console.log("handleValidateQuotation...");
    this.props.setLoading(true);
    let body = {
      quotationId: this.props.selectedQuotation.id,
    };
    this.props
      .beginUnValidateQuotation(body)
      .then((res) => {
        this.props
          .beginLoadQuotations(this.props.quotation.shareholder)
          .then((res) => {
            this.setUpCotation();
            this.handleUnValidateQuotationtClose();
            this.props.setLoading(false);
            this.props.setSnackbar({ open: true, severity: "success", message: "Quotation marquée comme pérdue avec succès.", duration: 3000 });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: err.err.response.data[0], duration: 3000 });
      });
  };

  /**
   * SWAP/UPDATE GO
   */
  handleGOSwitchMenuOpen = (e) => {
    this.setState({ goSwitchMenuAnchor: e.currentTarget });
  };

  handleGOSwitchMenuClose = () => {
    this.setState({ goSwitchMenuAnchor: null });
  };

  handleGOSwitch = (e, goId) => {
    e.preventDefault();

    this.handleGOSwitchMenuClose();

    if (goId !== 0) {
      let newGo = this.props.quotation.marketGoPrices.find((go) => go.id === goId);
      let newGoPrices = [];
      let newGoPrice = 0;
      for (let index = 0; index < this.props.quotation.years.length; index++) {
        const currentYear = this.props.quotation.years[index];
        let yearlyPrice = newGo && newGo.prices.find((p) => p.year === currentYear);
        newGoPrices.push({
          year: currentYear,
          price: yearlyPrice ? yearlyPrice.price : 0,
        });
        newGoPrice += yearlyPrice ? parseFloat(yearlyPrice.price) : 0;
      }

      newGoPrice = newGoPrice / this.props.quotation.years.length / 10;

      // update go
      this.updateGo(goId, newGoPrices, newGoPrice);
    } else {
      // build goPrices array before to load dialog
      let goPrices = [];
      for (let index = 0; index < this.props.quotation.years.length; index++) {
        const currentYear = this.props.quotation.years[index];
        goPrices.push({
          year: currentYear,
          price: 0,
        });
      }
      this.setState({ customGoDialogOpen: true, goPrices });
    }
  };

  handleCustomGoClose = () => {
    this.setState({ customGoDialogOpen: false, selectedGo: null, goPrices: [], goCustomName: "", goPrice: 0 });
  };

  updateCustomizedGoPrice = (year, value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        goPrices: prevState.goPrices.map((p) => {
          if (p.year === year) {
            return {
              ...p,
              price: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
            };
          } else {
            return p;
          }
        }),
      };
    });
  };

  updateCustomizedGoName = (e) => {
    this.setState({ goCustomName: e.target.value });
  };

  handleCustomGoValidate = () => {
    // check value if custom GO
    let goPrice = 0;
    if (_.isEmpty(this.state.goCustomName.trim())) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le nom de la garantie d'origine personnalisée ne peut être vide", duration: 3000 });
      return;
    }

    // process go price
    for (let index = 0; index < this.props.quotation.years.length; index++) {
      const currentYear = this.props.quotation.years[index];
      let customGoYearlyPrice = this.state.goPrices.find((p) => p.year === currentYear);
      goPrice += customGoYearlyPrice ? parseFloat(customGoYearlyPrice.price) : 0;
    }
    goPrice = goPrice / this.props.quotation.years.length / 10;

    this.setState({ customGoDialogOpen: false }, () => {
      // update go
      this.updateGo(0, this.state.goPrices, goPrice);
    });
  };

  updateGo = (selectedGo, goPrices, goPrice) => {
    this.props.setLoading(true);
    let body = {
      quotationId: this.props.selectedQuotation.id,
      selectedGo,
      goPrices: JSON.stringify(goPrices),
      goPrice,
      goCustomName: this.state.goCustomName,
    };
    this.props
      .beginSwapGo(body)
      .then((result) => {
        // update current edited quotation element
        this.props.setSelectedGo(this.props.selectedQuotation.selectedGo);
        this.props.setSelectedGoPrices(JSON.parse(this.props.selectedQuotation.goPrices));
        this.props.setSelectedGoPrice(parseFloat(this.props.selectedQuotation.goPrice));
        this.props.setSelectedGoCustomName(this.props.selectedQuotation.goCustomName);
        this.props.setSnackbar({ open: true, severity: "success", message: "Garantie d'origine changée avec succès.", duration: 3000 });
      })
      .then(() => {
        this.setState({ selectedGo, goPrices, goPrice }, () => {
          this.props.setLoading(false);
        });
      })
      .catch((err) => {
        console.log("handleCustomGoValidate err = ", err);
        this.props.setLoading(false);
      });
  };

  /**
   * Volume
   */
  handleUpdateVolumeDialogOpen = () => {
    this.setState({ updateVolumeDialogOpen: true, anchorEl: null });
  };

  handleUpdateVolumeDialogClose = () => {
    this.setState({ updateVolumeDialogOpen: false, newVolume: 0 });
  };

  updateVolume = (e) => {
    this.setState({ newVolume: e.target.value === "" || _.isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value) });
  };

  saveNewVolume = (e) => {
    e.preventDefault();

    this.props.setLoading(true);
    let body = {
      quotationId: this.props.selectedQuotation.id,
      volume: this.state.newVolume,
    };

    this.props
      .beginUpdateVolume(body)
      .then((result) => {
        this.handleUpdateVolumeDialogClose();
        this.props.setVolume(parseFloat(this.props.selectedQuotation.volumeMWh));
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: "Volume mis à jour avec succès.", duration: 3000 });
      })
      .catch((err) => {
        console.log("validateVolume err = ", err);
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: err.err.response.data[0], duration: 3000 });
      });
  };

  /**
   * UPDATE MARKUP
   */
  handleUpdateMarkupDialogOpen = (e) => {
    this.setState({ updateMarkupDialogOpen: true, anchorEl: null });
  };

  handleUpdateMarkupDialogClose = () => {
    this.setState({ updateMarkupDialogOpen: false });
  };

  handleMarkupModeChange = (e) => {
    let yearlyMarkups = [];
    for (let index = 0; index < this.props.quotation.years.length; index++) {
      const currentYear = this.props.quotation.years[index];
      yearlyMarkups.push({
        year: currentYear,
        markup: 0,
      });
    }

    this.setState({ markupMode: e.target.value, markup: 0, yearlyMarkups }, () => {
      this.props.setMarkupMode(this.state.markupMode);
      this.props.setYearlyMarkups(this.state.yearlyMarkups);
      this.props.setMarkup(this.state.markup);
    });
  };

  updateMarkup = (value) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          markup: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
        };
      },
      () => {
        this.props.setMarkup(this.state.markup).then(() => {});
      }
    );
  };

  updateYearlyMargin = (year, value) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          yearlyMarkups: prevState.yearlyMarkups.map((m) => {
            if (m.year === year) {
              return {
                ...m,
                markup: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
              };
            } else {
              return m;
            }
          }),
        };
      },
      () => {
        this.props.setYearlyMarkups(this.state.yearlyMarkups).then(() => {
          let averageMarkup =
            _.sumBy(this.props.quotation.yearlyMarkups, function (m) {
              return parseFloat(m.markup);
            }) / parseInt(this.state.yearlyMarkups.length);
          this.setState({ markup: averageMarkup }, () => {
            this.props.setMarkup(this.state.markup);
          });
        });
      }
    );
  };

  saveNewMakup = (e) => {
    this.props.setLoading(true);
    // console.log(this.state);
    let body = {
      quotationId: this.props.selectedQuotation.id,
      markupMode: this.state.markupMode,
      yearlyMarkups: JSON.stringify(this.state.yearlyMarkups),
      markup: this.state.markup,
    };

    this.props
      .beginUpdateMarkup(body)
      .then((result) => {
        this.props.setMarkupMode(this.props.selectedQuotation.markupMode).then(() => {
          this.setState({
            markupMode: this.props.quotation.markupMode,
          });
        });
        this.props.setYearlyMarkups(JSON.parse(this.props.selectedQuotation.yearlyMarkups)).then(() => {
          this.setState({
            yearlyMarkups: this.props.quotation.yearlyMarkups,
          });
        });
        this.props.setMarkup(parseFloat(this.props.selectedQuotation.markup)).then(() => {
          this.setState(
            (prevState) => {
              return {
                markup: this.props.quotation.markup,
                updatePrices: !prevState.updatePrices,
              };
            },
            () => {
              this.handleUpdateMarkupDialogClose();
              this.props.setLoading(false);
              this.props.setSnackbar({ open: true, severity: "success", message: "Marge mise à jour avec succès.", duration: 3000 });
            }
          );
        });
      })
      .catch((err) => {
        console.log("saveNewMakup err = ", err);
        this.props.setLoading(false);
        //this.props.setSnackbar({ open: true, severity: "error", message: err.err.response.data[0], duration: 3000 });
      });
  };

  render() {
    let duration = parseInt(this.props.quotation.years.length);

    let selectedProspect = this.props.prospects.find((p) => p.id === parseInt(this.props.quotation.selectedProspect));

    let selectedPriceType = null;
    if (this.props.quotation.selectedPod === "pricetype") {
      selectedPriceType = this.props.consumers && this.props.consumers.find((c) => c.id === parseInt(this.props.quotation.selectedPriceType));
    }

    let selectedPOD = null;
    if (this.props.quotation.selectedPod !== "pricetype") {
      selectedPOD = this.props.pods && this.props.pods.find((p) => p.id === parseInt(this.props.quotation.selectedPod));
    }

    let selectedGo =
      this.props.quotation.marketGoPrices && this.props.quotation.marketGoPrices.find((go) => go.id === parseInt(this.props.quotation.selectedGo));

    let validatedData = null;
    if (this.props.quotation && this.props.quotation.status === "won") {
      validatedData = JSON.parse(this.props.selectedQuotation.validatedInfo);
    }

    let fullExpectedIncome = parseFloat(this.props.quotation.volumeMWh) * duration * parseFloat(this.props.quotation.markup) * 10;

    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <ShareholderHeaderBlock shareholderId={this.props.match.params.id} />
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={4}>
              <p className="title1">Cotations</p>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={0} direction="row" alignContent="center" justify="flex-end">
                <Grid>
                  {/* <ButtonGroup> */}
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    aria-controls="display-menu"
                    color="primary"
                    onClick={this.handleActionDisplayMenuClick}
                    startIcon={<AspectRatioIcon fontSize="inherit" />}
                    style={{ marginRight: "1rem" }}
                  >
                    Affichage prix {this.state.pricingDisplayMode}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    aria-controls="cotation-menu"
                    color="primary"
                    onClick={this.handleActionMenuClick}
                    startIcon={<ExpandMoreIcon fontSize="inherit" />}
                    style={{ marginRight: "1rem" }}
                  >
                    Outils
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    aria-controls="cotation-menu"
                    component={Link}
                    to={
                      this.props.user && this.props.user.role === "shareholder"
                        ? "/quotations"
                        : `/quotations/${this.props.shareholder && this.props.shareholder.id}`
                    }
                  >
                    Retour
                  </Button>
                  {/* </ButtonGroup> */}
                  <Menu
                    id="display-menu"
                    anchorEl={this.state.anchorElDisplay}
                    keepMounted
                    open={Boolean(this.state.anchorElDisplay)}
                    onClose={this.handleActionMenuClose}
                  >
                    <MenuItem onClick={(e) => this.handlePricingDisplayChange("quattro")}>Prix quattro</MenuItem>
                    <MenuItem onClick={(e) => this.handlePricingDisplayChange("duo")}>Prix duo</MenuItem>
                    <MenuItem onClick={(e) => this.handlePricingDisplayChange("uno")}>Prix uno</MenuItem>
                  </Menu>
                  <Menu id="cotation-menu" anchorEl={this.state.anchorEl} keepMounted open={Boolean(this.state.anchorEl)} onClose={this.handleActionMenuClose}>
                    <MenuItem onClick={this.handleDownloadQuotation}>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <GetAppIcon color="primary" fontSize="inherit" />
                      </ListItemIcon>
                      Télecharger cotation
                    </MenuItem>
                    <MenuItem onClick={this.handleCreateCombinationsOpen} disabled={this.props.quotation && this.props.quotation.status !== "waiting"}>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <InsertLinkIcon color="primary" fontSize="inherit" />
                      </ListItemIcon>
                      Créer regroupement
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleUpdateMarkupDialogOpen} disabled={this.props.quotation && this.props.quotation.status !== "waiting"}>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <UpdateIcon color="primary" fontSize="inherit" />
                      </ListItemIcon>
                      Marge commerciale brute
                    </MenuItem>
                    <MenuItem onClick={this.handleUpdateVolumeDialogOpen} disabled={this.props.quotation && this.props.quotation.status !== "waiting"}>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <UpdateIcon color="primary" fontSize="inherit" />
                      </ListItemIcon>
                      Changer le volume annuel
                    </MenuItem>
                    <MenuItem onClick={this.handleUpdateQuotationOpen} disabled>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <UpdateIcon color="primary" fontSize="inherit" />
                      </ListItemIcon>
                      Actualiser prix
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleUnValidateQuotationtOpen} disabled={this.props.quotation && this.props.quotation.status !== "waiting"}>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <HighlightOffIcon style={{ color: "orange" }} fontSize="inherit" />
                      </ListItemIcon>
                      Marqué comme pérdue
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleDeleteQuotationOpen} disabled={this.props.quotation && this.props.quotation.status !== "waiting"}>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <DeleteForeverIcon color="secondary" fontSize="inherit" />
                      </ListItemIcon>
                      Supprimer cotation
                    </MenuItem>
                    {/* <Divider />
                    <MenuItem component={Link} to={`/quotations/${this.props.shareholder && this.props.shareholder.id}`}>
                      <ListItemIcon style={{ minWidth: "36px" }}>
                        <KeyboardBackspaceIcon color="primary" fontSize="inherit" />
                      </ListItemIcon>
                      Retour
                    </MenuItem> */}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.props.quotation.status === "lost" ? (
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Alert severity="warning">Cotation pérdue</Alert>
              </Grid>
            </Grid>
          ) : null}

          {this.props.quotation.status === "won" ? (
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Alert severity="success">Cotation validée</Alert>
              </Grid>
            </Grid>
          ) : null}

          {this.props.quotation.status === "waiting" ? (
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Alert severity="info">Cotation en attente de traitement</Alert>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={3} style={{ marginTop: "1rem" }}>
            <Grid item xs={6}>
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xs={12}>
                  <p className="title2">Informations partenaire</p>
                  <Table size="small" style={{ marginTop: "12px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <p className="text-small">Référence cotation</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">{this.props.selectedQuotation && this.props.selectedQuotation.reference}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p className="text-small">N° partenaire</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">{selectedProspect && selectedProspect.partnerNumber}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p className="text-small">Nom du partenaire</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">{selectedProspect && selectedProspect.name}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p className="text-small">Adresse</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            {selectedProspect && selectedProspect.address1}, {selectedProspect && selectedProspect.zipcode}{" "}
                            {selectedProspect && selectedProspect.location}
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xs={12}>
                  <p className="title2">Profil et type de prix</p>
                  <Table size="small" style={{ marginTop: "12px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <p className="text-small">Profil de tarifs :</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">{this.props.quotation.selectedPod !== "pricetype" ? "POD" : "Prix par branche"}</p>
                        </TableCell>
                      </TableRow>
                      {this.props.quotation.selectedPod === "pricetype" ? (
                        <TableRow>
                          <TableCell>
                            <p className="text-small">Branche type :</p>
                          </TableCell>
                          <TableCell align="right">
                            <p className="text-small">{selectedPriceType && selectedPriceType.label}</p>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow spacing={3}>
                          <TableCell>
                            <p className="text-small">POD :</p>
                          </TableCell>
                          <TableCell align="right">
                            <p className="text-small">{selectedPOD && selectedPOD.number}</p>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <p className="text-small">Version prix énergie</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            {moment(this.props.quotation.marketEnergyPricesDate).format("DD/MM/YYYY")} - {this.props.quotation.marketGoPricesTime}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <p className="text-small">Version prix GO</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            {moment(this.props.quotation.marketGoPricesDate).format("DD/MM/YYYY")} - {this.props.quotation.marketGoPricesTime}
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <p className="title2">Résumé</p>
                  <Table size="small" style={{ marginTop: "12px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ width: "24px" }}></TableCell>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small">Durée</p>
                        </TableCell>
                        <TableCell align="right" style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small bold">
                            {duration} {duration > 1 ? "ans" : "an"}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: "24px" }}></TableCell>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small">Période</p>
                        </TableCell>
                        <TableCell align="right" style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small bold">
                            de {this.props.quotation.startYear} à {this.props.quotation.endYear}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <IconButton size="small" onClick={this.handleGOSwitchMenuOpen} disabled={this.props.quotation.status !== "waiting"}>
                            <SwapHorizIcon color={this.props.quotation.status !== "waiting" ? "disabled" : "primary"} fontSize="inherit" />
                          </IconButton>
                          <Menu
                            id="go-switch-menu"
                            anchorEl={this.state.goSwitchMenuAnchor}
                            keepMounted
                            open={Boolean(this.state.goSwitchMenuAnchor)}
                            onClose={this.handleGOSwitchMenuClose}
                          >
                            {this.props.quotation.marketGoPrices &&
                              this.props.quotation.marketGoPrices.map((go) => {
                                return (
                                  <MenuItem
                                    key={go.id}
                                    onClick={(e) => this.handleGOSwitch(e, go.id)}
                                    style={{ color: go.id === this.props.quotation.selectedGo ? "rgba(0, 91, 151, 1)" : "black" }}
                                  >
                                    {go.label}
                                  </MenuItem>
                                );
                              })}
                            {this.props.quotation.selectedGo === 0 ? (
                              <MenuItem onClick={(e) => this.handleGOSwitch(e, 0)} style={{ color: "rgba(0, 91, 151, 1)" }}>
                                Autre : {this.props.quotation.goCustomName}
                              </MenuItem>
                            ) : (
                              <MenuItem onClick={(e) => this.handleGOSwitch(e, 0)}>Autre</MenuItem>
                            )}
                          </Menu>
                        </TableCell>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small">Garantie d'origine</p>
                        </TableCell>
                        <TableCell align="right" style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <Grid container spacing={0} direction="row" justify="flex-end" alignItems="center">
                            <Grid item>
                              <p className="text-small bold">
                                {this.props.quotation.selectedGo === 0 ? this.props.quotation.goCustomName : selectedGo && selectedGo.label}
                              </p>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" style={{ paddingLeft: "0", paddingRight: "0" }}></TableCell>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small">Volume annuel</p>
                        </TableCell>
                        <TableCell align="right" style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small bold">
                            <NumberFormat
                              displayType="text"
                              decimalScale={0}
                              fixedDecimalScale={true}
                              value={this.props.quotation.volumeMWh}
                              prefix=""
                              thousandSeparator="'"
                              style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                            />{" "}
                            MWh
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small">Date de cotation</p>
                        </TableCell>
                        <TableCell align="right" style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small bold ">
                            {this.props.selectedQuotation ? moment(this.props.selectedQuotation.created_at).format("DD/MM/YYYY") : ""}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small">Validité / échéance</p>
                        </TableCell>
                        <TableCell align="right" style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small bold ">{this.props.quotation.validityDuration} jour(s)</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <p className="title2">Détails des prix</p>
                  <Table size="small" style={{ marginTop: "12px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{ width: "24px", paddingLeft: "0", paddingRight: "0", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                          rowSpan={6}
                          align="center"
                        >
                          <p className="text-small bold" style={{ transform: "rotate(-90deg)", whiteSpace: "nowrap" }}>
                            Prix Appro
                          </p>
                        </TableCell>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small" style={{ marginLeft: "0.5rem" }}>
                            Énergie (pèriode complète)
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            <NumberFormat
                              displayType="text"
                              value={this.props.quotation.energyPrice}
                              decimalScale={3}
                              fixedDecimalScale
                              thousandSeparator="'"
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small" style={{ marginLeft: "0.5rem" }}>
                            Garantie d'origine
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            <NumberFormat displayType="text" value={this.props.quotation.goPrice} decimalScale={3} fixedDecimalScale thousandSeparator="'" />
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small" style={{ marginLeft: "0.5rem" }}>
                            Coûts d'ajustement
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            <NumberFormat
                              displayType="text"
                              value={this.props.quotation.adjustmentCost}
                              decimalScale={3}
                              fixedDecimalScale
                              thousandSeparator="'"
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small" style={{ marginLeft: "0.5rem" }}>
                            Risque SPOT
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            <NumberFormat displayType="text" value={this.props.quotation.riskCost} decimalScale={3} fixedDecimalScale thousandSeparator="'" />
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small" style={{ marginLeft: "0.5rem" }}>
                            Prime validité
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            <NumberFormat
                              displayType="text"
                              value={this.props.quotation.validityCost}
                              decimalScale={3}
                              fixedDecimalScale
                              thousandSeparator="'"
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
                          <p className="text-small bold" style={{ marginLeft: "0.5rem" }}>
                            Total
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            <NumberFormat
                              displayType="text"
                              value={
                                this.props.quotation.energyPrice +
                                this.props.quotation.goPrice +
                                this.props.quotation.adjustmentCost +
                                this.props.quotation.riskCost +
                                this.props.quotation.validityCost
                              }
                              decimalScale={3}
                              fixedDecimalScale
                              thousandSeparator="'"
                              style={{ fontWeight: "bold" }}
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <p className="text-small italic" style={{ marginTop: "6px", textAlign: "right", fontSize: "0.7rem" }}>
                    ct CHF/kWh
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={6}>
              <p className="title2">Marge commerciale brute</p>
              <Table size="small" style={{ marginTop: "12px" }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <p className="text-small">Type de marge</p>
                    </TableCell>
                    <TableCell align="right">
                      <p className="text-small">{this.props.quotation.markupMode === "yearly" ? "Différenciée par année" : "Globale"}</p>
                    </TableCell>
                  </TableRow>
                  {this.props.quotation.markupMode === "yearly" ? (
                    this.props.quotation.yearlyMarkups.map((markup, index) => {
                      return (
                        <TableRow key={`yearlyMarkup_${index}`}>
                          <TableCell>
                            <p className="text-small bold">{markup.year}</p>
                          </TableCell>
                          <TableCell align="right">
                            <p className="text-small">
                              <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={markup.markup} thousandSeparator="'" />
                            </p>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell>
                        <p className="text-small">Montant</p>
                      </TableCell>
                      <TableCell align="right">
                        <p className="text-small">
                          <NumberFormat
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={this.props.quotation.markup}
                            thousandSeparator="'"
                            suffix=" ct CHF/KWh"
                          />
                        </p>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {this.props.quotation.markupMode === "yearly" ? (
                <p className="text-small italic" style={{ marginTop: "6px", textAlign: "right", fontSize: "0.7rem" }}>
                  ct CHF/KWh
                </p>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
                <Grid>
                  <p className="title2">Prix client période complète</p>
                </Grid>
                <Grid>
                  <p className="text-small italic" style={{ marginTop: "6px", fontSize: "0.7rem" }}>
                    ct CHF/kWh
                  </p>
                </Grid>
              </Grid>
              <QuotationProspectPriceTable
                colors={this.state.colors}
                pricingDisplayMode={this.state.pricingDisplayMode}
                years={this.state.years}
                editable={this.props.quotation && this.props.quotation.status === "waiting"}
                handleDownloadOffer={this.handleDownloadOffer}
                isCombination={false}
                validateQuotation={this.validateQuotationOpen}
                validated={!_.isNull(validatedData) && validatedData && validatedData.type === "full"}
                expectedIncomes={fullExpectedIncome}
                markup={this.props.quotation && this.props.quotation.markup}
                markupMode={this.props.quotation.markupMode}
                selectedGo={this.state.selectedGo}
                updatePrices={this.state.updatePrices}
                fullperiod={true}
              />
            </Grid>
          </Grid>
          {this.props.quotation.years.length > 1 ? (
            <Fragment>
              <Grid container spacing={3} style={{ marginTop: "36px" }}>
                <Grid item>
                  <p className="title2">Prix client par année</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {this.props.quotation.years.map((year) => {
                  let isValidated = !_.isNull(validatedData) && validatedData.type === "year" && validatedData.years[0] === year;

                  let displayMarkup = 0;
                  let expectedIncomes = 0;

                  if (this.props.quotation.markupMode === "yearly") {
                    let yearlyMarkupData = this.props.quotation.yearlyMarkups.find((m) => m.year === year);
                    displayMarkup = yearlyMarkupData.markup;
                    expectedIncomes = parseFloat(this.props.quotation.volumeMWh) * parseFloat(displayMarkup) * 10;
                  } else {
                    displayMarkup = this.props.quotation.markup;
                    expectedIncomes = parseFloat(this.props.quotation.volumeMWh) * parseFloat(this.props.quotation.markup) * 10;
                  }

                  return (
                    <Grid item xs={4} key={year}>
                      <QuotationProspectPriceTable
                        colors={this.state.colors}
                        pricingDisplayMode={this.state.pricingDisplayMode}
                        years={[year]}
                        editable={this.props.quotation && this.props.quotation.status === "waiting"}
                        handleDownloadOffer={this.handleDownloadOffer}
                        isCombination={false}
                        validateQuotation={this.validateQuotationOpen}
                        validated={isValidated}
                        expectedIncomes={expectedIncomes}
                        markup={displayMarkup}
                        markupMode={this.props.quotation.markupMode}
                        selectedGo={this.state.selectedGo}
                        updatePrices={this.state.updatePrices}
                        fullperiod={false}
                      />
                      <Grid container spacing={3} direction="row" justify="space-between" alignContent="center">
                        <Grid item></Grid>
                        <Grid item>
                          <p className="text-small italic" style={{ marginTop: "6px", fontSize: "0.7rem" }}>
                            ct CHF/kWh
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Fragment>
          ) : null}
          {this.props.quotation.years.length > 1 ? (
            <Fragment>
              <Grid container spacing={3} style={{ marginTop: "36px" }}>
                <Grid item>
                  <p className="title2">Regroupements</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {this.props.quotation.combinations.map((combination, index) => {
                  let currentCombination = JSON.parse(combination);

                  let isValidated = false;
                  if (!_.isNull(validatedData) && validatedData.type !== "full" && _.isEqual(currentCombination.sort(), validatedData.years.sort())) {
                    isValidated = true;
                  }

                  // get average markup for the combination
                  let combinationMarkup = 0;
                  if (this.props.quotation.markupMode === "yearly") {
                    for (let index = 0; index < currentCombination.length; index++) {
                      const currentYear = currentCombination[index];
                      let currentYearMarkup = this.props.quotation.yearlyMarkups.find((m) => m.year === currentYear);
                      combinationMarkup += currentYearMarkup && currentYearMarkup.markup;
                    }
                    combinationMarkup = combinationMarkup / currentCombination.length;
                  } else {
                    combinationMarkup = this.props.quotation.markup;
                  }

                  let expectedIncomes = 0;

                  if (this.props.quotation.markupMode === "yearly") {
                    expectedIncomes = parseFloat(this.props.quotation.volumeMWh) * currentCombination.length * combinationMarkup * 10;
                  } else {
                    expectedIncomes = parseFloat(this.props.quotation.volumeMWh) * currentCombination.length * parseFloat(this.props.quotation.markup) * 10;
                  }

                  return (
                    <Grid item xs={4} key={index}>
                      <QuotationProspectPriceTable
                        colors={this.state.colors}
                        pricingDisplayMode={this.state.pricingDisplayMode}
                        years={currentCombination}
                        editable={this.props.quotation && this.props.quotation.status === "waiting"}
                        handleDownloadOffer={this.handleDownloadOffer}
                        isCombination={true}
                        handleDeleteCombinationOpen={this.handleDeleteCombinationOpen}
                        validateQuotation={this.validateQuotationOpen}
                        validated={isValidated}
                        expectedIncomes={expectedIncomes}
                        markup={combinationMarkup}
                        markupMode={this.props.quotation.markupMode}
                        selectedGo={this.state.selectedGo}
                        updatePrices={this.state.updatePrices}
                        fullperiod={false}
                      />
                      <Grid container spacing={3} direction="row" justify="space-between" alignContent="center">
                        <Grid item></Grid>
                        <Grid item>
                          <p className="text-small italic" style={{ marginTop: "6px", fontSize: "0.7rem" }}>
                            ct CHF/kWh
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                {this.props.quotation.combinations.length === 0 ? (
                  <Grid item xs={12}>
                    <p className="text-small italic">Aucun regroupement n'a été créé pour cette cotation.</p>
                  </Grid>
                ) : null}
              </Grid>
            </Fragment>
          ) : null}
        </Container>

        {/* Delete cotation */}
        <Dialog
          open={this.state.deleteQuotationDialogOpen}
          onClose={this.handleDeleteQuotationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">App EASA : Supprimer cotation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Êtes-vous certain de vouloir supprimer cette cotation ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteQuotationClose} color="primary">
              Anuler
            </Button>
            <Button onClick={this.handleDeleteQuotation} color="secondary" autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>

        {/* create combinations */}
        <Dialog onClose={this.handleCreateCombinationsClose} aria-labelledby="simple-dialog-title" open={this.state.combinationsDialogOpen}>
          <DialogTitle id="simple-dialog-title">App EASA : Combiner années</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Années disponibles :</DialogContentText>
          </DialogContent>
          <List>
            {this.props.quotation &&
              this.props.quotation.years &&
              this.props.quotation.years.map((year) => {
                let isYearSelected = _.includes(this.state.combinationYears, year);
                return (
                  <ListItem key={year} autoFocus dense button onClick={(e) => this.addRemoveYearToCombination(year)}>
                    <ListItemIcon>
                      <Checkbox edge="start" checked={isYearSelected} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": year }} color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={year} />
                  </ListItem>
                );
              })}
          </List>
          <DialogActions>
            <Button onClick={this.handleCreateCombinationsClose} color="default">
              Anuler
            </Button>
            <Button
              onClick={this.handleCreateCombinations}
              color="primary"
              autoFocus
              disabled={this.state.combinationYears.length < 2 || this.state.combinationYears.length === this.props.quotation.years.length}
            >
              Créer combinaison
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete combination */}
        <Dialog
          open={this.state.deleteCombinationsDialogOpen}
          onClose={this.handleDeleteCombinationClose}
          aria-labelledby="delete-combination-dialog-title"
          aria-describedby="delete-combination-dialog-description"
        >
          <DialogTitle id="delete-combination-dialog-title">App EASA : Supprimer regroupement</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-combination-dialog-description">Êtes-vous certain de vouloir supprimer ce regroupement ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteCombinationClose} color="primary">
              Anuler
            </Button>
            <Button onClick={this.handleDeleteCombination} color="secondary" autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Validate quotation */}
        <Dialog
          open={this.state.validateQuotationDialogOpen}
          onClose={this.handleValidateQuotationClose}
          aria-labelledby="validate-combination-dialog-title"
          aria-describedby="validate-combination-dialog-description"
        >
          <DialogTitle id="validate-combination-dialog-title">App EASA : Valider quotation</DialogTitle>
          <DialogContent>
            <DialogContentText id="validate-combination-dialog-description">
              Êtes-vous certain de valider cette quotation pour {this.state.validateDialogLabel}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleValidateQuotationClose} color="primary">
              Anuler
            </Button>
            <Button onClick={this.handleValidateQuotation} color="secondary" autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>

        {/* UnValidate quotation */}
        <Dialog
          open={this.state.unValidateQuotationDialogOpen}
          onClose={this.handleUnValidateQuotationtClose}
          aria-labelledby="unvalidate-quotation-dialog-title"
          aria-describedby="unvalidate-quotation-dialog-description"
        >
          <DialogTitle id="unvalidate-quotation-dialog-title">App EASA : Invalider quotation</DialogTitle>
          <DialogContent>
            <DialogContentText id="unvalidate-quotation-dialog-description">
              Êtes-vous certain de vouloir marquer cette quotation comme pérdue ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleUnValidateQuotationtClose} color="primary">
              Anuler
            </Button>
            <Button onClick={this.handleUnValidateQuotationt} color="secondary" autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Swap GO to custom GO dialog */}
        <Dialog
          open={this.state.customGoDialogOpen}
          onClose={this.handleCustomGoClose}
          aria-labelledby="delete-combination-dialog-title"
          aria-describedby="delete-combination-dialog-description"
        >
          <DialogTitle id="delete-combination-dialog-title">App EASA : Garantie d'origine personnalisée</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="delete-combination-dialog-description">
              Saisissez les informations ci-dessous pour enregistrer votre garantie d'origine persionalisée.
            </DialogContentText>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <p className="inputlabel bold">Prix en CHF/MWh</p>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {this.props.quotation.years.map((year) => {
                        return (
                          <TableCell key={`tableHead_${year}`} align="center">
                            <p className="text-small bold">{year}</p>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {this.props.quotation.years.map((year) => {
                        let yearlyPrice = this.state.goPrices.find((p) => p.year === year);
                        return (
                          <TableCell key={`tableRow_${year}`} align="center">
                            <TextField
                              id={`customized-go-price_${year}`}
                              value={yearlyPrice ? yearlyPrice.price : 0}
                              onChange={(e) => this.updateCustomizedGoPrice(year, e.target.value)}
                              style={{ width: "60px" }}
                              InputProps={{
                                inputComponent: CurrencyFormat,
                              }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item>
                <p className="inputlabel bold">Nom de la garantie d'origine</p>
              </Grid>
              <Grid item>
                <TextField id={`customized-go-name`} value={this.state.goCustomName} onChange={this.updateCustomizedGoName} fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCustomGoClose}>Anuler</Button>
            <Button onClick={this.handleCustomGoValidate} color="primary" autoFocus>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Update markup */}
        <Dialog
          open={this.state.updateMarkupDialogOpen}
          onClose={this.handleUpdateMarkupDialogClose}
          aria-labelledby="update-markup-dialog-title"
          aria-describedby="update-markup-dialog-description"
        >
          <DialogTitle id="update-markup-dialog-title">App EASA : Marge commerciale brute</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="update-markup-dialog-description">Configurer le type de marge et le montant de cette dernière ci-dessous.</DialogContentText>

            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={8}>
                <p className="inputlabel">Type de marge commerciale brute</p>
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: "100%" }}>
                  <Select id="markup-mode-selector" value={this.state.markupMode} onChange={this.handleMarkupModeChange} style={{ fontSize: "0.8rem" }}>
                    <MenuItem value="global">Global</MenuItem>
                    <MenuItem value="yearly">Par année</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {this.state.markupMode === "global" ? (
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xs={10}>
                  <p className="inputlabel">Saisissez la marge souhaitée en ct CHF/kWh</p>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="markup-field"
                    value={this.state.markup}
                    fullWidth
                    onChange={(e) => this.updateMarkup(e.target.value)}
                    InputProps={{
                      inputComponent: CurrencyFormat,
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                  <Grid item xs={8}>
                    <p className="inputlabel" style={{ lineHeight: "18px" }}>
                      Saisissez la marge souhaitée en ct CHF/kWh pour chacune des années de cotation
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <QuotationYearlyMarginTable updateYearlyMargin={this.updateYearlyMargin} />
                  </Grid>
                </Grid>

                <Grid container spacing={3} style={{ marginTop: "1rem" }}></Grid>
              </Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleUpdateMarkupDialogClose}>Anuler</Button>
            <Button onClick={this.saveNewMakup} color="primary" autoFocus>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Update volume */}
        <Dialog
          open={this.state.updateVolumeDialogOpen}
          onClose={this.handleUpdateVolumeDialogClose}
          aria-labelledby="update-volume-dialog-title"
          aria-describedby="update-volume-dialog-description"
        >
          <DialogTitle id="update-volume-dialog-title">App EASA : Volume annuel</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="update-volume-dialog-description">
              Le volume actuel est de{" "}
              <NumberFormat displayType="text" decimalScale={0} fixedDecimalScale={true} value={this.props.quotation.volumeMWh} thousandSeparator="'" />{" "}
              MWh/année. Saisissez le nouveau volume ci-dessous.
            </DialogContentText>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <p className="inputlabel">Nouveau volume annuel</p>
              </Grid>
              <Grid item xs={6}>
                <Input
                  id="update-volume-field"
                  value={this.state.volume}
                  onChange={this.updateVolume}
                  style={{ width: "100%" }}
                  endAdornment={<InputAdornment position="end">MWh/année</InputAdornment>}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleUpdateVolumeDialogClose}>Anuler</Button>
            <Button onClick={this.saveNewVolume} color="primary" autoFocus>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  settings: state.settings,
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  selectedQuotation: state.quotations.find((q) => q.id === parseInt(props.match.params.quotationId)),
  quotation: state.quotation,
  prices: state.prices,
  prospects: state.prospects,
  consumers: state.consumers,
  pods: state.pods,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  beginLoadSettings: (shareholderId) => dispatch(beginLoadSettings(shareholderId)),
  setShareholder: (shareholderId) => dispatch(setShareholder(shareholderId)),
  setReference: (ref) => dispatch(setReference(ref)),
  setSelectedProspect: (prospect) => dispatch(setSelectedProspect(prospect)),
  setSelectedPod: (pod) => dispatch(setSelectedPod(pod)),
  setSelectedPriceType: (pricetype) => dispatch(setSelectedPriceType(pricetype)),
  setQuatoationMarketStructure: (market) => dispatch(setQuatoationMarketStructure(market)),
  setSelectedFCCQuatro: (fccQuattro) => dispatch(setSelectedFCCQuatro(fccQuattro)),
  setSelectedYears: (start, end, years) => dispatch(setSelectedYears(start, end, years)),
  setSelectedEnergyPrices: (energyPrices) => dispatch(setSelectedEnergyPrices(energyPrices)),
  setSelectedMarketEnergyPrices: (prices) => dispatch(setSelectedMarketEnergyPrices(prices)),
  setMarketEnergyPricesDate: (date) => dispatch(setMarketEnergyPricesDate(date)),
  setMarketEnergyPricesTime: (time) => dispatch(setMarketEnergyPricesTime(time)),
  setMarketEnergyPricesType: (type) => dispatch(setMarketEnergyPricesType(type)),
  setSelectedEnergyPrice: (eneryPrice) => dispatch(setSelectedEnergyPrice(eneryPrice)),
  setSelectedGo: (goId) => dispatch(setSelectedGo(goId)),
  setSelectedMarketGoPrices: (prices) => dispatch(setSelectedMarketGoPrices(prices)),
  setMarketGoPricesDate: (date) => dispatch(setMarketGoPricesDate(date)),
  setMarketGoPricesTime: (time) => dispatch(setMarketGoPricesTime(time)),
  setSelectedGoPrices: (goPrices) => dispatch(setSelectedGoPrices(goPrices)),
  setSelectedGoPrice: (goPrice) => dispatch(setSelectedGoPrice(goPrice)),
  setSelectedGoCustomName: (name) => dispatch(setSelectedGoCustomName(name)),
  setMarketAdjustmentCosts: (costs) => dispatch(setMarketAdjustmentCosts(costs)),
  setAdjustmentCosts: (costs) => dispatch(setAdjustmentCosts(costs)),
  setAdjustmentCost: (costs) => dispatch(setAdjustmentCost(costs)),
  setMarketRiskCosts: (costs) => dispatch(setMarketRiskCosts(costs)),
  setRiskCosts: (costs) => dispatch(setRiskCosts(costs)),
  setRiskCost: (cost) => dispatch(setRiskCost(cost)),
  setMarkupMode: (markupMode) => dispatch(setMarkupMode(markupMode)),
  setYearlyMarkups: (yearlyMarkups) => dispatch(setYearlyMarkups(yearlyMarkups)),
  setMarkup: (markup) => dispatch(setMarkup(markup)),
  setValidityDuration: (validityDuration) => dispatch(setValidityDuration(validityDuration)),
  setValidityMarkup: (validityMarkup) => dispatch(setValidityMarkup(validityMarkup)),
  setValidityCost: (validityCost) => dispatch(setValidityCost(validityCost)),
  beginLoadPods: (prospectId) => dispatch(beginLoadPods(prospectId)),
  setVolume: (volumeMWh) => dispatch(setVolume(volumeMWh)),
  setStatus: (status) => dispatch(setStatus(status)),
  beginDeleteQuotation: (id) => dispatch(beginDeleteQuotation(id)),
  beginSaveCombination: (body) => dispatch(beginSaveCombination(body)),
  setCombinations: (combinations) => dispatch(setCombinations(combinations)),
  beginLoadQuotations: (shareholderId) => dispatch(beginLoadQuotations(shareholderId)),
  beginDeleteCombination: (body) => dispatch(beginDeleteCombination(body)),
  beginValidateQuotation: (body) => dispatch(beginValidateQuotation(body)),
  beginUnValidateQuotation: (body) => dispatch(beginUnValidateQuotation(body)),
  beginSwapGo: (body) => dispatch(beginSwapGo(body)),
  beginUpdateMarkup: (body) => dispatch(beginUpdateMarkup(body)),
  beginUpdateVolume: (body) => dispatch(beginUpdateVolume(body)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditQuotation));
