/**
 * AppEASA
 *
 * @file EuroChfSettingsPanel.js
 * @version 1.0
 * @description Euro/CFH exchange rate settings panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setSetting } from "../../../actions/settings";

// Other libs/components
import NumberFormat from "react-number-format";
import _ from "lodash";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //   prefix="$"
      mask="_"
      //   format="9#.####"
      fixedDecimalScale={true}
      decimalScale={3}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "center", fontSize: "0.8rem" }}
    />
  );
}

class EuroChfSettingsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      years: [],
    };
  }

  componentDidMount() {
    // get adjustmentCost setting
    let currentSettings = [];
    let setting = this.props.settings && this.props.settings.find((s) => s.key === "eurochf");
    if (setting.value) {
      currentSettings = JSON.parse(setting.value);
    }

    let maxDuration = this.props.config && this.props.config.maxDuration;

    // building object setting years
    let years = [];
    let startYear = parseInt(new Date().getFullYear());
    let endYear = startYear + maxDuration;

    for (let index = startYear; index <= endYear; index++) {
      let currentSetting = currentSettings.find((s) => s.year === index);
      years.push({
        year: index,
        value: currentSetting && currentSetting.value ? currentSetting.value : 0,
      });
    }

    this.setState({ years });
  }

  handleUpdateExchangeRate = (year, value) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          years: prevState.years.map((y) => {
            if (y.year === year) {
              return {
                ...y,
                value: !_.isNaN(parseFloat(value)) ? parseFloat(value) : 0,
              };
            } else {
              return y;
            }
          }),
        };
      },
      () => {
        this.props.setSetting("eurochf", JSON.stringify(this.state.years));
      }
    );
  };

  render() {
    return (
      <Fragment>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="title2"></p>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={6}>
            <p className="inputlabel">EUR/CHF</p>
          </Grid>
          <Grid item xs={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="text-small bold">Année</p>
                  </TableCell>
                  <TableCell>
                    <p className="text-small bold">Taux de change</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.years.map((year) => {
                  return (
                    <TableRow key={year.year}>
                      <TableCell>
                        <p className="text-small ">{year.year}</p>
                      </TableCell>
                      <TableCell>
                        <TextField
                          id={`adjustment-cost_${year.year}`}
                          value={year.value}
                          onChange={(e) => this.handleUpdateExchangeRate(year.year, e.target.value)}
                          style={{ width: "60px" }}
                          InputProps={{
                            inputComponent: CurrencyFormat,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.shareholderId)),
  user: state.user,
  config: state.config,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setSetting: (key, value) => dispatch(setSetting(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EuroChfSettingsPanel);
