import React, { Component, Fragment } from "react";
import moment from "moment";

// Material UI Components
import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default class ReportItem extends Component {
  render() {
    return (
      <Fragment>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <p className="title2">
                  {this.props.reportGroup && this.props.reportGroup.group.charAt(0).toUpperCase() + this.props.reportGroup.group.slice(1)}
                </p>
                <Table size="small" style={{ marginTop: "1rem" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={"50%"}>Raport</TableCell>
                      <TableCell>Mis à jour le</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.reportGroup &&
                      this.props.reportGroup.reports &&
                      this.props.reportGroup.reports.map((report) => {
                        return (
                          <TableRow>
                            <TableCell>{report.label}</TableCell>
                            <TableCell>{moment(report.lastDownload).format("DD MMMM YYYY")}</TableCell>
                            <TableCell align="right">
                              <IconButton size="small" onClick={(e) => this.props.downloadReport(e, report.id, report.filename, report.mimeType)}>
                                <GetAppIcon fontSize="inherit" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}
