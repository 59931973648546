/**
 * AppEASA
 *
 * @file Dashboard.js
 * @version 1.0
 * @description Admin app dashboard
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Redux functions
import { beginLoadShareholders } from "../../../actions/shareholders";
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";

// Custom UI components
import ShareholderDashboardCard from "../../shareholder/ShareholderDashboardCard";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

class Dashboard extends Component {
  componentDidMount() {
    this.props.beginLoadShareholders();
    this.props.setBreadcrumb([{ label: "Tableau de bord", link: null }]);
  }

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <Grid container spacing={3} style={{ marginTop: "0rem" }}>
            <Grid item xs={12}>
              <p className="title1">Actionnaires</p>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "1rem" }}>
            {this.props.shareholders &&
              this.props.shareholders.map((shareholder, index) => {
                return (
                  <Grid item xs={4} key={index}>
                    <ShareholderDashboardCard key={index} shareholder={shareholder} role={this.props.user.role} />
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  shareholders: state.shareholders,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  beginLoadShareholders: () => dispatch(beginLoadShareholders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
