import React, { Fragment } from "react";

// Custom UI Components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

// make avatar styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function EASAAvatar(props) {
  const classes = useStyles();
  return (
    <Fragment>
      <Avatar className={classes.small} variant="circular">
        {props.displayLabel}
      </Avatar>
    </Fragment>
  );
}
