/**
 * @description Footer
 *
 */

// Base components
import React, { Component } from "react";
import { connect } from "react-redux";

class Footer extends Component {
  render() {
    if (this.props.isAuthenticated && !this.props.auth.loading) {
      return (
        <div className="footer">
          <p className="copy">&copy; {new Date().getFullYear()} Enerdis Approvisionnement SA</p>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
