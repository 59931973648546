/**
 * AppEASA
 *
 * @file ui.js
 * @version 1.0
 * @description ui reducer
 *
 */

// import redux action type
import { SET_LOADING } from "../actions/types";

// set initial state
const initialState = false;

// export reducer
export default function uiReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return payload;

    default:
      return state;
  }
}
