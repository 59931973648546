/**
 * AppEASA
 *
 * @file quotations.js
 * @version 1.0
 * @description redux quotations' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_QUOTATIONS, UPDATE_QUOTATION } from "./types";

import setAuthToken from "../utils/setAuthToken";

// load app settings
export const beginLoadQuotations = (shareholderId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      const res = await axios.get("/api/quotations/" + shareholderId);

      if (res.status === 200) {
        dispatch(setQuotations(res.data.quotations));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les paramètres de l'application."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadSettingserr = ", err);
      reject({ status: false });
    }
  });

// set quotations
export const setQuotations = (quotations) => ({
  type: SET_QUOTATIONS,
  payload: quotations,
});

// update a quotation
export const updateQuotation = (quotation) => ({
  type: UPDATE_QUOTATION,
  quotation,
});
