/**
 * AppEASA
 *
 * @file config.js
 * @version 1.0
 * @description redux app config' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_CONFIG } from "./types";

// import redux actions
import { setLoading } from "./ui";
import setAuthToken from "../utils/setAuthToken";

// load app config
export const beginLoadAppConfig = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/appconfig", config);

      if (res.status === 200) {
        dispatch(setConfig(res.data.config));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger la configuration de l'application."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadConfig = ", err);
      dispatch(setLoading(false));
      reject({ status: false });
    }
  });

// set config
export const setConfig = (config) => ({
  type: SET_CONFIG,
  payload: config,
});
