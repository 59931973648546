/**
 * AppEASA
 *
 * @file EASASnackbar.js
 * @version 1.0
 * @description Snackbar UI component
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Redux functions
import { setSnackbar } from "../../actions/snackbar";

// Material UI Components
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

class EASASnackbar extends Component {
  onClose = () => {
    this.props.setSnackbar({ open: false, severity: "success", message: "", duration: 500 });
  };
  render() {
    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.props.snackbar.open}
          autoHideDuration={this.props.snackbar.duration}
          onClose={this.onClose}
          message="Note archived"
        >
          <MuiAlert elevation={6} variant="filled" severity={this.props.snackbar.severity}>
            {this.props.snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  snackbar: state.snackbar,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EASASnackbar);
