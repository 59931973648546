/**
 * AppEASA
 *
 * @file shareholders.js
 * @version 1.0
 * @description redux shareholders's actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_SHAREHOLDERS } from "./types";

// import token setter
import setAuthToken from "../utils/setAuthToken";

// load shareholders
export const beginLoadShareholders = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    if (localStorage.token) setAuthToken(localStorage.token);

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/shareholders", config);
      DEBUG && console.log("GET /api/shareholders res = ", res.data);

      dispatch(setShareholders(res.data.shareholders));
      resolve({ status: res.status });
    } catch (err) {
      reject(err.response);
    }
  });

// set shareholders
export const setShareholders = (shareholders) => ({
  type: SET_SHAREHOLDERS,
  payload: shareholders,
});
