/**
 * AppEASA
 *
 * @file quotations.js
 * @version 1.0
 * @description quotations reducer
 *
 */

// import redux action type
import { SET_QUOTATIONS, UPDATE_QUOTATION } from "../actions/types";

// set initial state
const initialState = [];

// export reducer
export default function customersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_QUOTATIONS:
      return payload;

    case UPDATE_QUOTATION:
      return state.map((q) => {
        if (q.id === action.quotation.id) {
          return action.quotation;
        } else {
          return q;
        }
      });

    default:
      return state;
  }
}
