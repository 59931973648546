/**
 * AppEASA
 *
 * @file QuotationFccFactorsTable.js
 * @version 1.0
 * @description FCC table
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";

// Other libs/components
import NumberFormat from "react-number-format";

// Material UI Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class QuotationFccFactorsTable extends Component {
  render() {
    return (
      <Fragment>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={3}
                align="center"
                style={{
                  borderLeft: "dashed 1px #CCCCCC",
                  backgroundColor: this.props.colors.year,
                }}
              >
                <p className="text-small bold">Année</p>
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{
                  borderLeft: "dashed 1px #CCCCCC",
                  backgroundColor: this.props.colors.summer,
                }}
              >
                <p className="text-small bold">Eté</p>
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                style={{
                  borderLeft: "dashed 1px #CCCCCC",
                  borderRight: "dashed 1px #CCCCCC",
                  backgroundColor: this.props.colors.winter,
                }}
              >
                <p className="text-small bold">Hiver</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={{ borderLeft: "dashed 1px #CCCCCC" }}
              >
                <p className="text-small bold">Total</p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small bold">HP</p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small bold">HC</p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dashed 1px #CCCCCC" }}
              >
                <p className="text-small bold">Total</p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small bold">HP</p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small bold">HC</p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dashed 1px #CCCCCC" }}
              >
                <p className="text-small bold">Total</p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small bold">HP</p>
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderLeft: "dotted 1px #CCCCCC",
                  borderRight: "dashed 1px #CCCCCC",
                }}
              >
                <p className="text-small bold">HC</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ borderLeft: "dashed 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.year.total}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.year.HP}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.year.HC}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dashed 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.summer.total}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.summer.HP}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.summer.HC}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dashed 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.winter.total}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{ borderLeft: "dotted 1px #CCCCCC" }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.winter.HP}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
              <TableCell
                align="center"
                style={{
                  borderLeft: "dotted 1px #CCCCCC",
                  borderRight: "dashed 1px #CCCCCC",
                }}
              >
                <p className="text-small">
                  <NumberFormat
                    displayType="text"
                    decimalScale={3}
                    fixedDecimalScale={true}
                    value={this.props.quotation.fccQuattro.winter.HC}
                    prefix=""
                    thousandSeparator="'"
                  />
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationFccFactorsTable);
