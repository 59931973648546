/**
 * AppEASA
 *
 * @file UpdatePassword.js
 * @version 1.0
 * @description update user password view
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../actions/breadcrumb";
import { beginUpdateUserPassword } from "../../actions/user";
import { setLoading } from "../../actions/ui";
import { setSnackbar } from "../../actions/snackbar";

// load fields validator
import validate from "../../utils/Validation";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      controls: {
        password: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
            minLength: 6,
          },
          touched: false,
          error: false,
        },
        confirmPassword: {
          value: "",
          isSecure: false,
          valid: false,
          validationRules: {
            notEmpty: true,
            minLength: 6,
          },
          touched: false,
          error: false,
        },
      },
    };
  }
  componentDidMount() {
    this.props.setBreadcrumb([{ label: "Mise à jour du mot de passe", link: null }]);
  }

  updateInputState = (key, value) => {
    // console.log("updateInputState key=", key);
    // console.log("updateInputState value=", value);
    // specific check for equalTo
    let connectedValue = {};
    if (this.state.controls[key].validationRules.equalTo) {
      const equalControl = this.state.controls[key].validationRules.equalTo;
      const equalValue = this.state.controls[equalControl].value;
      connectedValue = {
        ...connectedValue,
        equalTo: equalValue,
      };
    }

    this.setState((prevState) => {
      return {
        controls: {
          ...prevState.controls,
          [key]: {
            ...prevState.controls[key],
            value: value,
            valid: validate(value, prevState.controls[key].validationRules, connectedValue),
            touched: true,
          },
        },
      };
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.controls.password.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Le mot de passe doît avoir au minimum 6 caractères.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            password: {
              ...prevState.controls["password"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            password: {
              ...prevState.controls["password"],
              error: false,
            },
          },
        };
      });
    }

    if (!this.state.controls.confirmPassword.valid) {
      this.props.setSnackbar({ open: true, severity: "error", message: "Merci de confirmer votre mot de passe.", duration: 3000 });
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            confirmPassword: {
              ...prevState.controls["confirmPassword"],
              error: true,
            },
          },
        };
      });
      return;
    } else {
      this.setState((prevState) => {
        return {
          controls: {
            ...prevState.controls,
            confirmPassword: {
              ...prevState.controls["confirmPassword"],
              error: false,
            },
          },
        };
      });
    }

    if (this.state.controls.password.value !== this.state.controls.confirmPassword.value) {
      this.setState({ selectedShareholderError: true });
      this.props.setSnackbar({ open: true, severity: "error", message: "Les mots de passes ne correspondent pas.", duration: 3000 });
      return;
    }

    this.props.setLoading(true);
    this.props
      .beginUpdateUserPassword(this.props.user.email, this.state.controls.password.value, this.state.controls.confirmPassword.value)
      .then(() => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: "Votre mot de passe a été mis à jour avec succès.", duration: 3000 });
        this.props.history.push("/");
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.log("err = ", err);
        this.props.setSnackbar({ open: true, severity: "error", message: err.data.errors[0].msg, duration: 3000 });
      });
  };

  // toggle display clear password
  displayPassword = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showPassword: !prevState.showPassword,
      };
    });
  };

  // toggle display clear confirm password
  displayConfirmPassword = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showConfirmPassword: !prevState.showConfirmPassword,
      };
    });
  };

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <div style={{ width: "40%", marginLeft: "auto", marginRight: "auto" }}>
            <Grid container spacing={3} style={{ marginTop: "4rem" }}>
              <Grid item xs={12}>
                <p className="title1">Mise à jour de votre mot de passe...</p>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <p className="text">
                  Avant d'accèder à la plateforme EASA, merci de bien vouloir mettre à jour votre mot de passe au moyen du formulaire ci-dessous.
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <FormControl style={{ width: "100%" }} error={this.state.passwordError}>
                  <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
                  <Input
                    id="easa-password"
                    type={this.state.showPassword ? "text" : "password"}
                    error={this.state.controls.password.error}
                    value={this.state.controls.password.value}
                    onChange={(e) => this.updateInputState("password", e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={this.displayPassword} size="small">
                          {this.state.showPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <FormControl style={{ width: "100%" }} error={this.state.passwordError}>
                  <InputLabel htmlFor="standard-adornment-password">Confirmation du mot de passe</InputLabel>
                  <Input
                    id="easa-confirmpassword"
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    error={this.state.controls.confirmPassword.error}
                    value={this.state.controls.confirmPassword.value}
                    onChange={(e) => this.updateInputState("confirmPassword", e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={this.displayConfirmPassword} size="small">
                          {this.state.displayConfirmPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Button color="primary" size="small" variant="contained" className="login-button" style={{ marginTop: "1rem" }} onClick={this.onSubmit}>
                  Enregistrer nouveau mot de passe
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  beginUpdateUserPassword: (email, password, confirmPassword) => dispatch(beginUpdateUserPassword(email, password, confirmPassword)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdatePassword));
