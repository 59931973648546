/**
 * AppEASA
 *
 * @file settings.js
 * @version 1.0
 * @description redux settings' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_SETTINGS, SET_SETTING } from "./types";

// import redux actions
import { setLoading } from "./ui";
import setAuthToken from "../utils/setAuthToken";

// load app settings
export const beginLoadSettings = (shareholderId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/settings/" + shareholderId, config);

      if (res.status === 200) {
        dispatch(setSettings(res.data.settings));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les paramètres de l'application."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadSettingserr = ", err);
      dispatch(setLoading(false));
      reject({ status: false });
    }
  });

// update settings
export const beginUpdateSettings = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    //dispatch(setLoading(true));

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/settings", body, config)
        .then((res) => {
          //dispatch(setLoading(false));
          resolve({});
        })
        .catch((err) => {
          console.log("beginUpdateSettings err = ", err);
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginUpdateSettings = ", err);
      reject({ status: false });
    }
  });

// load app settings
export const beginLoadSettingss = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/settings", config);

      if (res.status === 200) {
        dispatch(setSettings(res.data.settings));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les paramètres de l'application."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadSettingserr = ", err);
      reject({ status: false });
    }
  });

// set settings
export const setSettings = (settings) => ({
  type: SET_SETTINGS,
  payload: settings,
});

// set setting
export const setSetting = (key, value) => ({
  type: SET_SETTING,
  key,
  value,
});

// update document templates
export const beginUpdateDocumentTemplates = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch(setLoading(true));

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/form-data",
      },
    };

    try {
      axios
        .post("/api/settings/templates", body, config)
        .then((res) => {
          //console.log(" ACTION POST /api/settings/templates res = ", res);
          dispatch(setSetting("templateFiles", res.data.templateFiles));
          dispatch(setLoading(false));
          resolve({});
        })
        .catch((err) => {
          dispatch(setLoading(false));
          reject({ status: false, msg: err.response.data });
          console.log("beginUpdateDocumentTemplates err = ", err);
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginUpdateDocumentTemplates = ", err);
      reject({ status: false });
    }
  });

// delete document templates
export const beginDeleteDocumentTemplates = ({ shareholderId, template }) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/form-data",
      },
    };

    try {
      axios
        .delete("/api/settings/templates/delete/" + shareholderId + "/" + template, config)
        .then((res) => {
          resolve({ status: true });
        })
        .catch((err) => {
          dispatch(setLoading(false));
          reject({ status: false, msg: err.response.data });
          console.log("beginDeleteDocumentTemplates err = ", err.response);
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginDeleteDocumentTemplates = ", err);
      reject({ status: false, msg: err });
    }
  });
