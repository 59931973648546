/**
 * AppEASA
 *
 * @file ShareholderHeaderBlock.js
 * @version 1.0
 * @description shareholder info block
 *
 */

// Base component
import React, { Component } from "react";
import { connect } from "react-redux";

// Material UI Components
import Grid from "@material-ui/core/Grid";

class ShareholderHeaderBlock extends Component {
  render() {
    return (
      <Grid container spacing={3} style={{ marginTop: "1rem" }}>
        <Grid item xs={6}>
          <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
            <Grid item>
              <img
                src={`/images/${this.props.shareholder && this.props.shareholder.logo}`}
                alt={`${this.props.shareholder && this.props.shareholder.name}`}
                style={{ height: "40px", width: "auto" }}
              />
            </Grid>
            <Grid item>
              {this.props.user && this.props.user.role !== "shareholder" ? <p className="text italic">Actionnaire</p> : null}
              <p className="title2">{this.props.shareholder && this.props.shareholder.name}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <p className="text italic">Gestionnaire</p>
          <p className="title2">
            {this.props.shareholder && this.props.shareholder.firstname} {this.props.shareholder && this.props.shareholder.lastname}
            <span className="text">
              {" "}
              (<a href={`mailto:${this.props.shareholder && this.props.shareholder.email}`}>{this.props.shareholder && this.props.shareholder.email}</a>
              {" - "}
              {this.props.shareholder && this.props.shareholder.mobile})
            </span>
          </p>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.shareholderId)),
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShareholderHeaderBlock);
