/**
 * AppEASA
 *
 * @file HelpAccess.js
 * @version 1.0
 * @description component used to check help's view access
 *
 */

// load requierd librairies
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// load components
import Can from "../../rbac/Can";

// load UI Components
import Help from "./Help";
import RestrictedAccess from "../../rbac/RestrictedAccess";

class HelpAccess extends Component {
  render() {
    return (
      <Can
        permissions={this.props.user.permissions}
        action={"help:access"}
        yes={() => {
          return <Help />;
        }}
        no={() => <RestrictedAccess />}
      />
    );
  }
}

HelpAccess.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  //navbar: state.navbar,
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HelpAccess);
