/**
 * AppEASA
 *
 * @file QuotationEnergyPricesTable.js
 * @version 1.0
 * @description electricity prices table including fields to update prices and exchange rates
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";

// Other libs/components
import NumberFormat from "react-number-format";

// Material UI Components
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //   prefix="$"
      mask="_"
      //   format="9#.####"
      fixedDecimalScale={true}
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "center", fontSize: "0.8rem" }}
    />
  );
}

function ExchangeRateFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //   prefix="$"
      mask="_"
      //   format="9#.####"
      fixedDecimalScale={true}
      decimalScale={3}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "center", fontSize: "0.8rem" }}
    />
  );
}
class QuotationEnergyPricesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  energyPriceTableRow = (year) => {
    // get prices from catalog
    let marketPrice = this.props.quotation.marketEnergyPrices && this.props.quotation.marketEnergyPrices.find((p) => p.year === year);

    let quotationPrice = this.props.quotation.energyPrices.find((ep) => ep.year === year);

    let yearlyPriceCH = quotationPrice ? parseFloat(quotationPrice.mwhEuro) * parseFloat(quotationPrice.exchangeRate) : 0;
    // console.log("yearlyPriceCH ", yearlyPriceCH);
    // yearlyPriceCH = Math.round((yearlyPriceCH + Number.EPSILON) * 100) / 100;
    // yearlyPriceCH = Number(Math.round(yearlyPriceCH + "e" + 2) + "e-" + 2);

    // console.log("quotationPrice.mwhEuro ", quotationPrice.mwhEuro);
    // console.log("quotationPrice.exchangeRate ", quotationPrice.exchangeRate);
    // console.log("yearlyPriceCH ", yearlyPriceCH);

    return (
      <TableRow key={year}>
        <TableCell>
          <p className="text-small bold">{quotationPrice && quotationPrice.year}</p>
        </TableCell>
        <TableCell align="center" style={{ borderLeft: "dashed 1px #AAAAAA" }}>
          <p className="text-small">
            <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={marketPrice.mwhEuro} prefix="" thousandSeparator="'" />
          </p>
        </TableCell>
        <TableCell align="center" style={{ borderLeft: "dotted 1px #CCCCCC" }}>
          <TextField
            id={`energy-price_${quotationPrice && quotationPrice.year}`}
            value={quotationPrice && quotationPrice.mwhEuro}
            onChange={(e) => this.props.updateCustomizedEnergyPrice(quotationPrice.year, "mwhEuro", e.target.value)}
            style={{ width: "60px" }}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </TableCell>
        <TableCell align="center" style={{ borderLeft: "dashed 1px #AAAAAA" }}>
          <p className="text-small">
            <NumberFormat displayType="text" decimalScale={3} fixedDecimalScale={true} value={marketPrice.exchangeRate} prefix="" thousandSeparator="'" />
          </p>
        </TableCell>
        <TableCell align="center" style={{ borderLeft: "dotted 1px #CCCCCC" }}>
          <TextField
            id={`energy-exchangerate_${quotationPrice && quotationPrice.year}`}
            value={quotationPrice && quotationPrice.exchangeRate}
            onChange={(e) => this.props.updateCustomizedEnergyPrice(quotationPrice.year, "exchangeRate", e.target.value)}
            style={{ width: "60px" }}
            InputProps={{
              inputComponent: ExchangeRateFormat,
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          style={{
            borderLeft: "dashed 1px #AAAAAA",
            borderRight: "dashed 1px #AAAAAA",
            backgroundColor: "rgba(17, 170, 0, 0.1)",
          }}
        >
          <p className="text-small ">
            <NumberFormat
              displayType="text"
              style={{ fontWeight: "bold" }}
              decimalScale={2}
              fixedDecimalScale={true}
              value={yearlyPriceCH}
              prefix=""
              thousandSeparator="'"
            />
          </p>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    return (
      <Fragment>
        <Collapse in={this.props.energyPricesTableExpanded} timeout="auto" unmountOnExit>
          <Table size="small" style={{ marginTop: "1rem" }}>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>
                  <p className="text-small italic">Année</p>
                </TableCell>
                <TableCell
                  colSpan={2}
                  align="center"
                  style={{
                    borderBottom: "none",
                    borderLeft: "dashed 1px #AAAAAA",
                  }}
                >
                  <p className="text-small bold">&euro;/MWh</p>
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={2}
                  style={{
                    borderBottom: "none",
                    borderLeft: "dashed 1px #AAAAAA",
                  }}
                >
                  <p className="text-small bold">Taux &euro;/CHF</p>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderBottom: "none",
                    borderLeft: "dashed 1px #CCC",
                    borderRight: "dashed 1px #AAAAAA",
                    //backgroundColor: "rgba(17, 170, 0, 0.1)",
                  }}
                >
                  <p className="text-small bold">CHF/MWh</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <p className="text-small ">Prix marché</p>
                </TableCell>
                <TableCell align="center" style={{ borderLeft: "dotted 1px #CCCCCC" }}>
                  <p className="text-small ">Prix cotation</p>
                </TableCell>
                <TableCell align="center" style={{ borderLeft: "dashed 1px #AAAAAA" }}>
                  <p className="text-small ">Taux marché</p>
                </TableCell>
                <TableCell align="center" style={{ borderLeft: "dotted 1px #CCCCCC" }}>
                  <p className="text-small ">Taux cotation</p>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    borderLeft: "dashed 1px #AAAAAA",
                    borderRight: "dashed 1px #AAAAAA",
                    //backgroundColor: "rgba(17, 170, 0, 0.1)",
                  }}
                >
                  <p className="text-small bold ">Tarif cotation</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.props.quotation.years.map((year) => this.energyPriceTableRow(year))}</TableBody>
          </Table>
        </Collapse>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
  prices: state.prices,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationEnergyPricesTable);
