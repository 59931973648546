/**
 * AppEASA
 *
 * @file historyprices.js
 * @version 1.0
 * @description redux history prices' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import setAuthToken from "../utils/setAuthToken";

// load history prices Power de
export const beginLoadPowerDePrices = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/market/powerde", config);

      if (res.status === 200) {
        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des prix de l'électricité en Allemagne."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadPowerDePrices err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// load history prices Power ch
export const beginLoadPowerChPrices = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/market/powerch", config);

      if (res.status === 200) {
        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des prix de l'électricité en Suisse."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadPowerDePrices err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// load history spread Power de ch
export const beginLoadPowerDeChSpreads = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/market/powerdechspread", config);

      if (res.status === 200) {
        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des spreads de l'électricité entre l'Allemagne et la Suisse."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadPowerDePrices err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// load fx rates data
export const beginLoadFxRates = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    // as we are unsing async function, ensure that the token is sent in the header
    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": localStorage.token,
      },
    };

    try {
      const res = await axios.get("/api/market/fx", config);
      DEBUG && console.log("GET /api/market res = ", res.data);

      if (res.status === 200) {
        // dispatch(setRoutes(res.data.routes));

        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des taux de changes."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("err =", err);
      reject({ status: false });
    }
  });

// load coal prices
export const beginLoadCoalPrices = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    // as we are unsing async function, ensure that the token is sent in the header
    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": localStorage.token,
      },
    };

    try {
      const res = await axios.get("/api/market/coal", config);
      DEBUG && console.log("GET /api/market res = ", res.data);

      if (res.status === 200) {
        // dispatch(setRoutes(res.data.routes));

        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des prix du charbon."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("err =", err);
      reject({ status: false });
    }
  });

// load brent prices
export const beginLoadBrentPrices = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    // as we are unsing async function, ensure that the token is sent in the header
    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": localStorage.token,
      },
    };

    try {
      const res = await axios.get("/api/market/brent", config);
      DEBUG && console.log("GET /api/market res = ", res.data);

      if (res.status === 200) {
        // dispatch(setRoutes(res.data.routes));

        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des prix du pétrole."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("err =", err);
      reject({ status: false });
    }
  });

// load gaz THE prices
export const beginLoadGazTHEPrices = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    // as we are unsing async function, ensure that the token is sent in the header
    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": localStorage.token,
      },
    };

    try {
      const res = await axios.get("/api/market/gaz-the", config);
      DEBUG && console.log("GET /api/market res = ", res.data);

      if (res.status === 200) {
        // dispatch(setRoutes(res.data.routes));

        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des prix du pétrole."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("err =", err);
      reject({ status: false });
    }
  });

// load brent prices
export const beginLoadCarbonPrices = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    // as we are unsing async function, ensure that the token is sent in the header
    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": localStorage.token,
      },
    };

    try {
      const res = await axios.get("/api/market/carbon", config);
      DEBUG && console.log("GET /api/market res = ", res.data);

      if (res.status === 200) {
        // dispatch(setRoutes(res.data.routes));

        resolve({ status: res.status, title: res.data.title, prices: res.data.prices, tendencies: res.data.tendencies, source: res.data.source });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger l'historique des prix du pétrole."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("err =", err);
      reject({ status: false });
    }
  });
