/**
 * AppEASA
 *
 * @file Users.js
 * @version 1.0
 * @description Admin users' view
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Redux functions
import { beginLoadUsers } from "../../../actions/users";
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { beginLoadRoles } from "../../../actions/roles";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

class Users extends Component {
  componentDidMount() {
    this.props.beginLoadRoles();
    this.props.beginLoadUsers();
    this.props.setBreadcrumb([{ label: "Utilisateurs", link: null }]);
  }

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <Grid container spacing={3} style={{ marginTop: "1rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Utilisateurs</p>
            </Grid>
            <Grid item>
              <Button size="small" color="primary" variant="contained" disableElevation component={Link} to={`/users/add`}>
                Ajouter un utilisateur
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Prénom</TableCell>
                      <TableCell>Nom</TableCell>
                      <TableCell>Nom d'utilisateur</TableCell>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Actionnaire</TableCell>
                      <TableCell align="center">Actif</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.users &&
                      this.props.users.map((user, index) => {
                        let role = this.props.roles.find((r) => r.name === user.role);
                        let userActive = <CheckIcon size="small" style={{ color: "green" }} />;
                        if (!user.isActive) {
                          userActive = <CloseIcon size="small" style={{ color: "red" }} />;
                        }
                        return (
                          <TableRow key={index}>
                            <TableCell>{user.firstname}</TableCell>
                            <TableCell>{user.lastname}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.mobile}</TableCell>
                            <TableCell>{role && role.label}</TableCell>
                            <TableCell>{user.shareholder}</TableCell>
                            <TableCell align="center">{userActive}</TableCell>
                            <TableCell>
                              <Link to={`/users/edit/${user.id}`}>
                                <IconButton aria-label="edit" size="small">
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  roles: state.roles,
});

const mapDispatchToProps = (dispatch) => ({
  beginLoadRoles: () => dispatch(beginLoadRoles()),
  beginLoadUsers: () => dispatch(beginLoadUsers()),
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
