/**
 *
 * @file GraphCard.js
 * @version 1.0
 * @description Market info graaphic card component point view
 *
 */

// load base libs
import React, { useState, forwardRef, Fragment } from "react";

// load lodash
import _ from "lodash";

// load UI Graph component
import Graph from "./Graph";
import GraphTrends from "./GraphTrends";

// load Material UI components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Tooltip from "@material-ui/core/Tooltip";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GraphCard = ({ title, traces, legend, tendencies, source, enableDownload, reportName }) => {
  const [fullScreen, setFullScreen] = useState(false);

  const handleOpenFullScreen = () => {
    setFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setFullScreen(false);
  };

  return (
    <Box style={{ padding: "1rem" }}>
      <Card variant="outlined">
        <CardHeader
          action={
            <Fragment>
              <Tooltip title="Mode plein écran">
                <IconButton size="small" aria-label="Full screen" onClick={handleOpenFullScreen}>
                  <FullscreenIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Fragment>
          }
          title={
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {title}
            </Typography>
          }
        />
        <CardMedia children={<Graph traces={traces} legend={true} />} />
        <CardContent style={{ paddingBottom: 0 }}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12}>
              {_.isArray(tendencies) ? <GraphTrends tendencies={tendencies} /> : null}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "1rem", paddingBottom: "0.5rem", textAlign: "right" }}>
              <Typography variant="caption" style={{ opacity: 0.5, fontStyle: "italic", fontSize: "0.625rem" }}>
                Source : {source !== undefined ? source : "n/a"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog fullScreen open={fullScreen} onClose={handleCloseFullScreen} TransitionComponent={Transition}>
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <Typography style={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <Tooltip title="Quitter mode plein écran">
              <IconButton edge="start" color="inherit" onClick={handleCloseFullScreen} aria-label="close">
                <FullscreenExitIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Graph traces={traces} legend={legend} graphHeight={500} />
          {_.isArray(tendencies) ? <GraphTrends tendencies={tendencies} /> : null}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default GraphCard;
