/**
 * @description Main App navigation
 *
 */

// Base components
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// load redux functions
import { logout } from "../../actions/auth";

// load resources
import EnerdisLogo from "../../resources/images/enerdis-logo.jpg";

// load components
import Can from "../rbac/Can";

// load custom UI Components
import Loader from "../ui/Loader";
import GradientSeparator from "../ui/GradientSeparator";
import EASAAvatar from "../ui/EASAAvatar";

// load material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "",
      headerShadow: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.updateScroll);
  }

  componentWillUnmountt() {
    window.removeEventListener("scroll", this.updateScroll);
  }

  updateScroll = () => {
    if (window.pageYOffset > 10) {
      this.setState({ headerShadow: true });
    } else {
      this.setState({ headerShadow: false });
    }
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
  };

  render() {
    if (this.props.isAuthenticated && !this.props.auth.loading) {
      return (
        <Fragment>
          <Loader display={this.props.ui} />
          <div className={`navbar ${this.state.headerShadow ? "navbar-shadow" : ""}`}>
            <Container>
              <Grid container spacing={0} direction="row" alignItems="center" justify="space-between">
                <Grid item xs={2}>
                  <Link to="/">
                    <img src={EnerdisLogo} alt="Enerdis Approvisionnement SA" className="navbar-logo" />
                  </Link>
                </Grid>
                <Grid item xs={8}>
                  <ul className="navbar-hrmenulist">
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"market:access"}
                        yes={() => {
                          return <Link to="/market">Infos marché</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"dashboard:access"}
                        yes={() => {
                          return <Link to="/dashboard">Tableau de bord</Link>;
                        }}
                        no={() => null}
                      />
                    </li>

                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"shareholders:access"}
                        yes={() => {
                          return <Link to="/shareholders">Actionnaires</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"portfolio:access"}
                        yes={() => {
                          return <Link to="/reporting">Mon portefeuille</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"quotation:access"}
                        yes={() => {
                          return <Link to="/quotations">Mes cotations</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"help:access"}
                        yes={() => {
                          return <Link to="/help">Aide &amp; Support</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"users:access"}
                        yes={() => {
                          return <Link to="/users">Utilisateurs</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"admin-config:access"}
                        yes={() => {
                          return <Link to="/config">Configuration</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                    <li>
                      <Can
                        permissions={this.props.user.permissions}
                        action={"history-prices:access"}
                        yes={() => {
                          return <Link to="/history">Prix</Link>;
                        }}
                        no={() => null}
                      />
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={1}>
                  <Grid container spacing={0} direction="row" alignItems="center" justify="flex-end">
                    <Grid item xs={6}>
                      {this.props.user && this.props.user.firstname ? (
                        <EASAAvatar displayLabel={this.props.user.firstname ? this.props.user.firstname.charAt(0) : ""} />
                      ) : null}
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                      <IconButton size="small" onClick={this.logout}>
                        <ExitToAppIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
            <GradientSeparator />
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  user: state.user,
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
