/**
 * AppEASA
 *
 * @file NewsletterFev2022.js
 * @version 1.0
 * @description Newsletter view
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";

// Custom UI components
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

class NewsletterFev2022 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.user && this.props.user.role === "shareholder") {
      this.props.setBreadcrumb([{ label: "Vous êtes ici : Newsletter Février 2022", link: null }]);
    } else {
      this.props.setBreadcrumb([{ label: "Newsletter Février 2022", link: null }]);
    }
  }

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          {this.props.user && this.props.user.role === "shareholder" ? (
            <ShareholderHeaderBlock shareholderId={this.props.user && this.props.user.shareholderId} />
          ) : null}
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="title0">Contexte global</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Le Cal23 suisse a augmenté la semaine dernière d’environ 10% avec des pics haussiers de 40% le mercredi 23 février alors que nous étions
                    déjà au plus haut historiques sur 2023 pour atteindre 155 EUR/MWh avec pic à 190.00 EUR/MWh.
                  </p>
                  <p className="text-newsletter">Le spread Allemagne/Suisse est passé à 8,50 EUR/MWh.</p>
                  <p className="text-newsletter">Bien évidemment, l'invasion de l'Ukraine par la Russie déclenche des craintes sur les marchés de l'énergie.</p>
                  <p className="text-newsletter">
                    Le contrat de mars pour le gaz naturel européen a temporairement augmenté mercredi et jeudi de plus de 50 EUR/MWh.{" "}
                  </p>
                  <p className="text-newsletter">Les marchés du charbon ont connu des mouvements similaires et dans une moindre mesure le pétrole également.</p>
                  <p className="text-newsletter">
                    Les prix ont certes nettement baissé vendredi, mais ils repartent ce matin la hausse avec la poursuite de l'escalade de la guerre au cours
                    de la journée et les incertitudes quant à son dénouement, sa durée, ses conséquences.
                  </p>
                  <p className="text-newsletter">A ce jour, il n'y a pas eu de nouvelle réduction des flux de gaz russe vers l'Europe.</p>
                  <p className="text-newsletter">
                    Les vastes sanctions de l'Europe et les risques géopolitiques très élevés font augmenter les primes de risque sur les marchés des matières
                    premières et continueront très probablement à augmenter ces prochaines semaines.
                  </p>
                  <p className="text-newsletter">Cela vaut aussi bien pour le court terme que pour le long terme.</p>
                  <p className="text-newsletter">
                    L'Europe achète environ 40% de ses besoins en gaz en provenance de Russie, pour le charbon de centrale, c'est de l’ordre de 60-70% et le
                    pétrole 50%.
                  </p>
                  <p className="text-newsletter">
                    Par ailleurs, des informations ont fait état d'une dégradation des centrales nucléaires françaises, en plus des problèmes de corrosion déjà
                    connus dans le système de sécurité.
                  </p>
                  <p className="text-newsletter">
                    Des problèmes similaires dans les circuits de refroidissement secondaires pourraient se produire. Ceux-ci sont notamment utilisés lors des
                    arrêts d'urgence.
                  </p>
                  <p className="text-newsletter">
                    EDF a confirmé vendredi qu'il faudrait deux ans pour vérifier les 56 réacteurs. Ces examens ont toutefois déjà été planifié et les nouveaux
                    objectifs de production ont été revus nettement plus bas pour cette année et 2023.
                  </p>
                  <p className="text-newsletter">
                    Le marché suisse, du fait des importations françaises, est plus fortement touché par cette incertitude que l'Allemagne ce qui a entraîné un
                    nouvel élargissement du spread Allemagne/Suisse qui oscille vers €8.00EUR/MWh.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Electricité</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    1. Les niveaux de stocks de gaz en Europe restent bas mais pas autant que redouté. Après l’évolution et la durée du conflit seront
                    déterminant. Pour l’instant les flux sont réguliers et dans la norme.
                  </p>
                  <p className="text-newsletter">
                    2. La guerre russo-ukrainienne semble avoir tué dans l’oeuf North stream2 pour les 2-3 prochaines années en tout cas.
                  </p>
                  <p className="text-newsletter">3. Indisponibilité d’une partie du parc nucléaire français.</p>
                  <p className="text-newsletter">
                    4. L’invasion russe de février-mars 2014 n’avait eu que très peu d’impact sur les prix du gaz, du pétrole ou du charbon. Cette fois, les
                    intentions russes sont nettement moins claires et un peu plus proche de l’aliénation du pouvoir. Et les Américains ont eux, semble-t-il,
                    bien préparé le coup depuis 7-8 mois car ils ont obtenu ce qu’ils cherchaient : abandon de Northstream2 et une très forte augmentation de
                    leur part en gaz liquéfié à destination de l’Europe à des prix inimaginables il y a 2 ans….
                  </p>
                  <p className="text-newsletter">5. Surveiller les sanctions et l’évolution du conflit.</p>
                  <p className="text-newsletter">6. Prévision aucune</p>
                  <p className="text-newsletter">Prix actuels (28.02.2022) du Baseload sur le marché suisse :</p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">Cal-2023 : 153.50 €/MWh</p>
                    </li>
                    <li>
                      <p className="text-newsletter">Cal-2024 : 104.00 €/MWh</p>
                    </li>
                    <li>
                      <p className="text-newsletter">Cal-2025 : 100.00 €/MWh</p>
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Gaz naturel</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Le marché du gaz évolue dans une extrême volatilité avec des variations de 30 à 40% sur une journée et reste à des cours extrêmement hauts.
                    Nous retrouvons les prix hallucinants de fin 2021.
                  </p>
                  <p className="text-newsletter">Mais à ce jour les flux sont stables et réguliers et l’hiver plus doux que prévu.</p>
                  <p className="text-newsletter">
                    En 2021, les recettes budgétaires de la Russie reposent pour plus de 35 % sur ses ventes de pétrole et de gaz. Ce pays a retrouvé depuis 20
                    ans sa place sur l’échiquier énergétique mondial. Il est devenu un acteur important pour l’approvisionnement mondial en pétrole aux côtés de
                    l’Organisation des pays exportateurs de pétrole (OPEP), de même qu’un fournisseur stratégique de gaz, en Europe, mais aussi auprès de son
                    voisin chinois.
                  </p>
                  <p className="text-newsletter">
                    Or, le message envoyé en ce moment par Moscou à ses partenaires actuels et futurs est flou et contre-productif alors que tout était prêt
                    pour asseoir la main mise russe sur les énergies en Europe, les russes tuent North stream2 pour les 2-3 prochaines années en tout cas et
                    favorisent les intérêts américains{" "}
                  </p>
                  <p className="text-newsletter">Difficile de comprendre pourquoi Poutine tient tant à endosser le rôle du méchant dans ce mauvais film… </p>
                  <p className="text-newsletter">
                    Au surplus, en collant à la Russie une réputation exécrable comme fournisseur d’énergie, les actions de Poutine ouvrent davantage le
                    lucratif marché du gaz européen à son ennemi, les États-Unis.
                  </p>
                  <p className="text-newsletter">
                    En janvier, 50% du gaz naturel liquéfié importé par l’Europe est venu des États-Unis contre à peine 20%, 24 mois plutôt.{" "}
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">CO2</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    La baisse des prix du CO2, enfin plutôt sa stabilisation autour des 88-90 €/t est due pour une partie à l’incertitude sur une éventuelle
                    réforme et intervention du comité européen (un rapport d’audit est attendu courant mars) ainsi qu’aux augmentations d’appels de marge sur
                    les autres marchés avec les fortes variations boursières sur les taux obligataires, actions ou matières premières.
                  </p>
                  <p className="text-newsletter">
                    Les prix du carbone pourraient poursuivre leur ascension dans un contexte de prix élevés du gaz et d’un manque d'approvisionnement aux
                    enchères sans réforme européenne.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Charbon</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Le charbon comme pour le gaz est soumis aux aléas du conflit, en sus, des stocks mondiaux relativement bas et d’une demande chinoise et
                    indienne soutenue.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Pétrole</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Les prix du Brent et du WTI flirtent avec les 100.00 dollars depuis une dizaine de jour. Les pays arabes principaux producteurs vont se
                    réunir en urgence pour évaluer leur position avec la Russie dont ils se sont bien rapprochés ces dernières années… Normalement pas
                    grand-chose à attendre
                  </p>
                  <p className="text-newsletter">L’Europe est plus dépendante encore au pétrole russe qu’au gaz (Charbon 60% Pétrole 50%, Gaz 45%)</p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Garanties d’origine</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    L’hydraulicité européenne est dans sa courbe basse et un hiver sec en Norvège et dans les pays avoisinants maintient sous pression les prix
                    des certificats. L’Espagne et le Portugal connaissent une sécheresse hivernale rarement observée qui pourrait devenir très problématique à
                    l’approche de l’été si le printemps reste sec également.
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Taux de change €/CHF</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter">
                    Sous les craintes d’une forte inflation et d’une brusque remontée des taux obligataires en Europe, le CHF perdait un peu de sa vigueur pour
                    flirter avec les 1.06, mais au son des canons de Vladimir, notre monnaie a retrouvé tout son allant et se repose vers les 1.03.
                  </p>
                  <p className="text-newsletter">En période d'instabilité, telle que nous la vivons aujourd'hui, le CHF reste une valeur refuge.</p>
                  <p className="text-newsletter">Sans intervention forte de la BNS, prévision de marge de fluctuation hebdomadaire : 1.0300 - 1.0400. </p>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: "3rem" }}>
                <Grid item>
                  <p className="title0">Recommandations</p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Energie :
                  </p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">
                        Cal-2023, 2024 et 2025 en fonction du niveau de couverture de vos portefeuilles respectifs, adopter une position d’observateur et saisir
                        les opportunités baissières . Fenêtre temporelle =&gt; Mars-Juin 2022.
                      </p>
                    </li>
                    <li>
                      <p className="text-newsletter">Cal-2026, couvrir au minimum 15% à 20% de votre portefeuille en fonction des opportunités.</p>
                    </li>
                  </ol>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Paire Eur/CHF :
                  </p>
                  <ul className="list-newsletter">
                    <li>
                      <p className="text-newsletter">Acquérir 75% de vos besoins en Euro sur 2023 d’ici avril 2022</p>
                    </li>
                    <li>
                      <p className="text-newsletter">65% sur 2024 d’ici juin 2022</p>
                    </li>
                    <li>
                      <p className="text-newsletter">35% sur 2025 d’ici juin 2022</p>
                    </li>
                  </ul>
                  <p className="text-newsletter">Sinon opter pour option Call EUR/CHF</p>
                  <p className="text-newsletter" style={{ textDecoration: "underline" }}>
                    Garanties d'origine :
                  </p>
                  <ol className="list-newsletter">
                    <li>
                      <p className="text-newsletter">Clôturer vos positions 2021 d’ici la fin mars 2022.</p>
                    </li>
                    <li>
                      <p className="text-newsletter">
                        Année de production 2022 : Couvrir le 50 à 75 % avant l’été 2022 pour éviter toute mauvaise surprise si sécheresse , puis suivre les
                        opportunités du marché jusqu’à la fin de l’année
                      </p>
                    </li>
                    <li>
                      <p className="text-newsletter">
                        Année de production 2023 à 2025 : Attendre avril-juin 2022 pour augmenter votre couverture si pas déjà fait avec l’espoir d’une détente
                        sur les prix Norvégiens avant l’été si les apports en eau augmentent.
                      </p>
                    </li>
                  </ol>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterFev2022);
