/**
 * AppEASA
 *
 * @file Help.js
 * @version 1.0
 * @description Shareholder help view
 *
 */

// base components
import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";

// Custom UI Components
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Alert, AlertTitle } from "@material-ui/lab";
import Link from "@material-ui/core/Link";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.setBreadcrumb([{ label: "Vous êtes ici : Aide & Support", link: null }]);
  }

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <ShareholderHeaderBlock shareholderId={this.props.user && this.props.user.shareholderId} />
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Aide &amp; Support</p>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={12}>
              <p className="text ">Il n'y a actuellement aucune documentation disponible.</p>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>Besoin d'aide ?</AlertTitle>En cas de besoin, vous pouvez contacter notre support à l'adresse{" "}
                <Link href="mailto:support@enerdis-appro.ch">support@enerdis-appro.ch</Link>.
              </Alert>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Help));
