/**
 * AppEASA
 *
 * @file reports.js
 * @version 1.0
 * @description redux reports' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_REPORTS } from "./types";

// load reports
export const beginLoadReports = (id) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/reports/" + id, config);
      DEBUG && console.log("GET /api/reports res = ", res.data);

      if (res.status === 200) {
        dispatch(setReports(res.data.reports));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des raports"],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      const errors = err.response.data.errors;
      reject({ msg: errors[0].msg });
    }
  });

// set reports
export const setReports = (reports) => ({
  type: SET_REPORTS,
  payload: reports,
});
