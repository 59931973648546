/**
 * AppEASA
 *
 * @file AddQuotationComponentStepThree.js
 * @version 1.0
 * @description wizzard step 3 component
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";
import { setLoading } from "../../actions/ui";

// load other libs
import NumberFormat from "react-number-format";

// UI components
import QuotationProspectPriceTable from "./QuotationProspectPriceTable";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Menu from "@material-ui/core/Menu";

class AddQuotationComponentStepThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingDisplayMode: "quattro",
      goSwitchMenuAnchor: null,
      selectedGo: null,
    };
  }

  componentDidMount() {
    // load default display prices
    let displayPricesModeSetting = this.props.settings.find((s) => s.key === "displayPricesMode");
    this.setState({
      pricingDisplayMode: displayPricesModeSetting && displayPricesModeSetting.value ? displayPricesModeSetting.value : "quattro",
      selectedGo: this.props.quotation && this.props.quotation.selectedGo,
    });
  }

  handlePricingDisplayChange = (e) => {
    this.setState({ pricingDisplayMode: e.target.value });
  };

  handleGOSwitchMenu = (e) => {
    this.setState({ goSwitchMenuAnchor: e.currentTarget });
  };

  handleGOSwitch = (goId) => {
    this.setState({ goSwitchMenuAnchor: null, selectedGo: goId }, () => {
      this.props.updateGoPrice(this.state.selectedGo);
    });
  };

  handleGOSwitchMenuClose = () => {
    this.setState({ goSwitchMenuAnchor: null });
  };

  render() {
    let selectedGo = this.props.quotation.marketGoPrices.find((go) => go.id === parseInt(this.props.quotation.selectedGo));

    let goAveragePrice = 0;
    for (let index = 0; index < this.props.quotation.years.length; index++) {
      const currentYear = this.props.quotation.years[index];
      let yearlyPrice = this.props.quotation.goPrices.find((p) => p.year === currentYear);
      if (yearlyPrice) goAveragePrice += yearlyPrice.price / 10;
    }
    goAveragePrice = goAveragePrice / this.props.quotation.years.length;

    let fullExpectedIncome = parseFloat(this.props.quotation.markup) * 1000 * parseFloat(this.props.quotation.volumeMWh) * this.props.quotation.years.length;
    return (
      <Fragment>
        <Grid container spacing={3} direction="row" alignItems="center">
          <Grid item xs={4}>
            <p className="title1">Cotation</p>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="price-display-mode-label">Sélectionnez un mode d'affichage des prix</InputLabel>
              <Select
                labelId="price-display-mode-label"
                id="price-display-mode"
                value={this.state.pricingDisplayMode}
                fullWidth
                onChange={this.handlePricingDisplayChange}
              >
                <MenuItem value={"uno"}>Uno</MenuItem>
                <MenuItem value={"duo"}>Duo</MenuItem>
                <MenuItem value={"quattro"}>Quattro</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "24px" }}>
          <Grid item xs={4}>
            <p className="title2">Résumé</p>
            <Table size="small" style={{ marginTop: "12px" }}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p className="text-small">Durée</p>
                  </TableCell>
                  <TableCell align="right">
                    <p className="text-small bold">
                      {this.props.quotation.endYear - this.props.quotation.startYear + 1} année(s), de {this.props.quotation.startYear} à{" "}
                      {this.props.quotation.endYear}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p className="text-small">Garantie d'origine</p>
                  </TableCell>
                  <TableCell align="right">
                    <Grid container spacing={0} direction="row" justify="flex-end" alignItems="center">
                      <Grid item>
                        <p className="text-small bold">
                          {parseInt(this.props.quotation.selectedGo) === 0 ? `Autre : ${this.props.quotation.goCustomName}` : selectedGo && selectedGo.label}
                        </p>
                      </Grid>
                      <Grid item>
                        <IconButton size="small" style={{ marginLeft: "1rem" }} onClick={this.handleGOSwitchMenu}>
                          <SwapHorizIcon fontSize="inherit" color="primary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.goSwitchMenuAnchor}
                      keepMounted
                      open={Boolean(this.state.goSwitchMenuAnchor)}
                      onClose={this.handleGOSwitchMenuClose}
                    >
                      {this.props.quotation.marketGoPrices &&
                        this.props.quotation.marketGoPrices.map((go) => {
                          return (
                            <MenuItem
                              key={go.id}
                              onClick={(e) => this.handleGOSwitch(go.id)}
                              style={{ color: go.id === this.props.quotation.selectedGo ? "rgba(0, 91, 151, 1)" : "black" }}
                            >
                              {go.label}
                            </MenuItem>
                          );
                        })}
                      {this.props.quotation.selectedGo === 0 ? (
                        <MenuItem onClick={(e) => this.handleGOSwitch(0)}>Autre : {this.props.quotation.goCustomName}</MenuItem>
                      ) : null}
                    </Menu>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p className="text-small">Volume</p>
                  </TableCell>
                  <TableCell align="right">
                    <p className="text-small bold">
                      <NumberFormat
                        displayType="text"
                        decimalScale={0}
                        fixedDecimalScale={true}
                        value={this.props.quotation.volumeMWh}
                        prefix=""
                        thousandSeparator="'"
                        style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                      />{" "}
                      MWh
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p className="text-small">Validité</p>
                  </TableCell>
                  <TableCell align="right">
                    <p className="text-small bold ">{this.props.quotation.validityDuration} jour(s)</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={4}>
            <p className="title2">Différence autres garanties d'origines</p>
            <Table size="small" style={{ marginTop: "12px" }}>
              <TableBody>
                {this.props.quotation.marketGoPrices.map((go, index) => {
                  if (go.id !== this.props.quotation.selectedGo) {
                    let currentGoAveragePrice = 0;

                    for (let index = 0; index < this.props.quotation.years.length; index++) {
                      const currentYear = this.props.quotation.years[index];

                      let currentGoYearlyPrice = go.prices.find((p) => p.year === currentYear);

                      if (currentGoYearlyPrice) currentGoAveragePrice += currentGoYearlyPrice.price / 10;
                    }
                    currentGoAveragePrice = currentGoAveragePrice / this.props.quotation.years.length;

                    return (
                      <TableRow key={`go_${index}`}>
                        <TableCell>
                          <p className="text-small">{go.label}</p>
                        </TableCell>
                        <TableCell align="right">
                          <p className="text-small">
                            <NumberFormat
                              displayType="text"
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={currentGoAveragePrice - goAveragePrice}
                              prefix=""
                              suffix=""
                              thousandSeparator="'"
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
            <p className="text-small italic right" style={{ marginTop: "5px" }}>
              en ct CHF/kWh
            </p>
          </Grid>
          <Grid item xs={4}>
            <p className="title2">Prix {this.state.pricingDisplayMode} en ct CHF/kWh</p>
            <QuotationProspectPriceTable
              colors={this.props.colors}
              pricingDisplayMode={this.state.pricingDisplayMode}
              years={this.props.quotation.years}
              editable={false}
              expectedIncomes={fullExpectedIncome}
              selectedGo={this.state.selectedGo}
            />
          </Grid>
        </Grid>

        {this.props.quotation.years.length > 1 ? (
          <Fragment>
            <Grid container spacing={3} style={{ marginTop: "12px" }}>
              <Grid item>
                <p className="title2">Détails par année</p>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {this.props.quotation.years.map((year) => {
                let yearlyMarkup = this.props.quotation.yearlyMarkups.find((m) => m.year === year);
                let expectedIncomes = 0;
                if (this.props.quotation.markupMode === "yearly") {
                  expectedIncomes = yearlyMarkup && (parseFloat(yearlyMarkup.markup) * 1000 * parseFloat(this.props.quotation.volumeMWh)) / 100;
                } else {
                  expectedIncomes = (parseFloat(this.props.quotation.markup) * 1000 * parseFloat(this.props.quotation.volumeMWh)) / 100;
                }

                return (
                  <Grid item xs={4} key={year}>
                    <QuotationProspectPriceTable
                      colors={this.props.colors}
                      pricingDisplayMode={this.state.pricingDisplayMode}
                      years={[year]}
                      editable={false}
                      validated={false}
                      expectedIncomes={expectedIncomes}
                      selectedGo={this.state.selectedGo}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
  prospects: state.prospects,
  pods: state.pods,
  consumers: state.consumers,
  settings: state.settings,
  prices: state.prices,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuotationComponentStepThree);
