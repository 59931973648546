/**
 * AppEASA
 *
 * @file QuotationYearlyMarginTable.js
 * @version 1.0
 * @description yearly margin table
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";

// Other libs/components
import NumberFormat from "react-number-format";
// import _ from "lodash";

// Material UI Components
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Collapse from "@material-ui/core/Collapse";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //   prefix="$"
      mask="_"
      //   format="9#.####"
      fixedDecimalScale={true}
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "center", fontSize: "0.8rem" }}
    />
  );
}

class QuotationYearlyMarginTable extends Component {
  render() {
    let years = this.props.quotation.years;

    return (
      <Fragment>
        <Table size="small">
          <TableBody>
            {years.map((year) => {
              let yearlyMarkup = this.props.quotation && this.props.quotation.yearlyMarkups.find((m) => m.year === year);

              return (
                <TableRow key={year}>
                  <TableCell>
                    <p className="text-small bold">{year}</p>
                  </TableCell>
                  <TableCell>
                    <TextField
                      id={`yearly-margin-${year}`}
                      value={yearlyMarkup && yearlyMarkup.markup ? yearlyMarkup.markup : 0}
                      onChange={(e) => this.props.updateYearlyMargin(year, e.target.value)}
                      style={{ width: "60px" }}
                      InputProps={{
                        inputComponent: CurrencyFormat,
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationYearlyMarginTable);
