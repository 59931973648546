/**
 * AppEASA
 *
 * @file AddQuotationComponent.js
 * @version 1.0
 * @description add new quotation parent component
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// Redux functions
import { connect } from "react-redux";
import { setLoading } from "../../actions/ui";
import { beginSaveQuotation, setSelectedGo, setSelectedGoPrices, setSelectedGoPrice, setSelectedGoCustomName } from "../../actions/quotation";
import { setSnackbar } from "../../actions/snackbar";

// Other libs/components
import _ from "lodash";

// UI Components
import AddQuotationComponentStepOne from "./AddQuotationComponentStepOne";
import AddQuotationComponentStepTwo from "./AddQuotationComponentStepTwo";
import AddQuotationComponentStepThree from "./AddQuotationComponentStepThree";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

class AddQuotationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: ["Client", "Paramètres", "Cotation"],
      activeStep: 0,
      step1Completed: false,
      step2Completed: false,
      colors: {
        year: "rgb(236, 240, 222)",
        summer: "rgb(252, 249, 206)",
        winter: "rgb(219, 239, 243)",
      },
    };
  }

  componentDidMount() {
    // this.props.beginLoadConsumers();

    // set default start and end year
    let startYear = new Date().getFullYear() + 1;
    let endYear = startYear + 2;

    // build customized Prices array
    let duration = endYear - startYear + 1;
    let customizedPrices = [];
    let cotationPrices = [];
    for (let index = 0; index < duration; index++) {
      customizedPrices.push({
        year: startYear + index * 1,
        mwhEuro: 0,
        exchangeRate: 0,
      });
      cotationPrices.push({
        year: startYear + index * 1,
        mwhEuro: 0,
        exchangeRate: 0,
      });
    }

    this.setState({ startYear, endYear, customizedPrices, cotationPrices }, () => {
      // console.log("componentDidMount", this.state.customizedPrices);
      // this.updatePrices();
    });
  }

  /**
   * Stepper functions
   */
  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <AddQuotationComponentStepOne
            shareholder={this.props.shareholder}
            updateFCC={this.updateFCC}
            fccQuattro={this.state.fccQuattro}
            colors={this.state.colors}
            handleStepCompleted={this.handleStepCompleted}
          />
        );

      case 1:
        return <AddQuotationComponentStepTwo handleStepCompleted={this.handleStepCompleted} />;

      case 2:
        return <AddQuotationComponentStepThree colors={this.state.colors} updateGoPrice={this.updateGoPrice} />;

      default:
        return null;
    }
  };

  handleNext = () => {
    // console.log("handleNext state = ", this.state);
    if (this.state.activeStep === 0) {
      // check whether or not the prosepct is new
      if (this.props.quotation.selectedProspect === "new") {
        if (!this.props.quotation.prospectNumber) {
          this.props.setSnackbar({ open: true, severity: "error", message: "Le numéro de partenaire du nouveau prospect ne peut être vide!", duration: 3000 });
          return;
        }

        if (!this.props.quotation.prospectName) {
          this.props.setSnackbar({ open: true, severity: "error", message: "Le nom du nouveau prospect ne peut être vide!", duration: 3000 });
          return;
        }

        if (!this.props.quotation.prospectAddress1) {
          this.props.setSnackbar({ open: true, severity: "error", message: "L'adresse du nouveau prospect ne peut être vide!", duration: 3000 });
          return;
        }

        if (!this.props.quotation.prospectZipcode) {
          this.props.setSnackbar({ open: true, severity: "error", message: "Le NPA du nouveau prospect ne peut être vide!", duration: 3000 });
          return;
        }

        if (!this.props.quotation.prospectLocation) {
          this.props.setSnackbar({ open: true, severity: "error", message: "Le lieu du nouveau prospect ne peut être vide!", duration: 3000 });
          return;
        }
      }

      this.setState(
        (prevState) => {
          return {
            ...prevState,
            activeStep: prevState.activeStep + 1,
          };
        },
        () => {
          this.props.updateCurrentStep(this.state.activeStep);
          // console.log("handleNext state = ", this.state);
        }
      );
    } else if (this.state.activeStep === 1) {
      // console.log("need to check custom name... ");
      // check if go is orher, name must be set
      if (this.props.quotation && this.props.quotation.selectedGo === 0 && !this.props.quotation.goCustomName) {
        this.props.setSnackbar({ open: true, severity: "error", message: "Le nom de la garantie d'origine ne peut être vide !", duration: 3000 });
        return;
      }

      if (this.props.quotation.volumeMWh === 0 || _.isNaN(this.props.quotation.volumeMWh)) {
        this.props.setSnackbar({ open: true, severity: "error", message: "Veuillez saisir le volume annuel en MWh !", duration: 3000 });
        return;
      }

      this.setState(
        (prevState) => {
          return {
            ...prevState,
            activeStep: prevState.activeStep + 1,
          };
        },
        () => {
          this.props.updateCurrentStep(this.state.activeStep);
          // console.log("handleNext state = ", this.state);
        }
      );
      // step 3
    } else {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            activeStep: prevState.activeStep + 1,
          };
        },
        () => {
          this.props.updateCurrentStep(this.state.activeStep);
          // console.log("handleNext state = ", this.state);
        }
      );
    }
  };

  handleBack = () => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          activeStep: prevState.activeStep - 1,
        };
      },
      () => {
        this.props.updateCurrentStep(this.state.activeStep);
      }
    );
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  handleStepCompleted = (key, value) => {
    this.setState({ [key]: value }, () => {
      // console.log(this.state);
    });
  };

  updateGoPrice = (goId) => {
    // console.log()
    // get go prices
    let selectedGoPrices = this.props.quotation.marketGoPrices && this.props.quotation.marketGoPrices.find((go) => go.id === parseInt(goId));
    // console.log("selectedGoPrices", selectedGoPrices);
    let goPrices = [];

    let goPrice = 0;
    for (let index = 0; index < this.props.quotation.years.length; index++) {
      const currentYear = this.props.quotation.years[index];

      if (parseInt(goId) !== 0) {
        let goYearlyPrice = selectedGoPrices && selectedGoPrices.prices.find((p) => p.year === currentYear);

        // keep current prices
        goPrices.push({
          year: goYearlyPrice.year,
          price: goYearlyPrice.price,
        });

        // process go price
        goPrice += parseFloat(goYearlyPrice.price);
      } else {
        // keep current prices
        goPrices.push({
          year: currentYear,
          price: 0,
        });
      }
      // get the yearly price
    }

    goPrice = goPrice / this.props.quotation.years.length / 10;
    // console.log("goPrice = ", goPrice);
    this.props.setSelectedGo(goId);
    this.props.setSelectedGoPrices(goPrices);
    this.props.setSelectedGoPrice(goPrice);

    if (this.props.quotation.selectedGo !== 0) {
      this.props.setSelectedGoCustomName("");
    }
  };

  saveQuotation = () => {
    this.props.setLoading(true);

    this.props
      .beginSaveQuotation(this.props.quotation)
      .then((result) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: "Cotation enregistrée avec succès", duration: 3000 });
        this.props.cancelNewQuotation();
      })
      .catch((error) => {
        console.log("save new quotation error = ", error.err.response.data[0]);
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: error.err.response.data[0], duration: 3000 });
      });
  };

  render() {
    let nextFinishButtonDisabled = true;
    if (this.state.activeStep === 0 && this.state.step1Completed) {
      nextFinishButtonDisabled = false;
    }

    if (this.state.activeStep === 1 && this.state.step2Completed) {
      nextFinishButtonDisabled = false;
    }

    if (this.state.activeStep === 2 && this.state.step1Completed && this.state.step2Completed) {
      nextFinishButtonDisabled = false;
    }
    return (
      <Fragment>
        {/* <Container> */}
        <Stepper activeStep={this.state.activeStep}>
          {this.state.steps.map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {this.state.activeStep === this.state.steps.length ? (
            <div>finished</div>
          ) : (
            <div>
              <div style={{ padding: "2rem" }}>{this.getStepContent(this.state.activeStep)}</div>
              <Container>
                <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    <Button variant="contained" size="small" color="primary" onClick={this.handleBack} disabled={this.state.activeStep === 0} disableElevation>
                      Retour
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={this.state.activeStep === this.state.steps.length - 1 ? this.saveQuotation : this.handleNext}
                      disableElevation
                      disabled={nextFinishButtonDisabled}
                    >
                      {this.state.activeStep === this.state.steps.length - 1 ? "Enregistrer" : "Suivant"}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}
        </div>
        {/* </Container> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.shareholderId)),
  prospects: state.prospects,
  pods: state.pods,
  consumers: state.consumers,
  quotation: state.quotation,
  prices: state.prices,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSelectedGo: (goId) => dispatch(setSelectedGo(goId)),
  setSelectedGoPrices: (goPrices) => dispatch(setSelectedGoPrices(goPrices)),
  setSelectedGoPrice: (goPrice) => dispatch(setSelectedGoPrice(goPrice)),
  setSelectedGoCustomName: (name) => dispatch(setSelectedGoCustomName(name)),
  beginSaveQuotation: (quotation) => dispatch(beginSaveQuotation(quotation)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuotationComponent);
