/**
 * AppEASA
 *
 * @file Settings.js
 * @version 1.0
 * @description settings list
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { beginLoadSettings, beginUpdateSettings, beginUpdateDocumentTemplates, beginDeleteDocumentTemplates } from "../../../actions/settings";
import { beginLoadTemplatesContentBlocs } from "../../../actions/templatescontents";

// load other libs
import _ from "lodash";
import validator from "validator";

// UI Components
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";
import DisplaySettingsPanel from "./DisplaySettingsPanel";
import AdjustmentCostSettingsPanel from "./AdjustmentCostSettingsPanel";
import RiskSpotSettingsPanel from "./RiskSpotSettingsPanel";
import QuotationSettingsPanel from "./QuotationSettingsPanel";
import EuroChfSettingsPanel from "./EuroChfSettingsPanel";
import TemplatesSettingsPanel from "./TemplatesSettingsPanel";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disaplyKey: "display",
      quotationFile: null,
      quotationFileExists: true,
      offerFile: null,
      offerFileExists: true,
      resetUpload: false,
    };
  }

  componentDidMount() {
    let redirectTo = this.props.user && this.props.user.role === "admin" ? "/dashboard" : "/shareholders";
    if (this.props.shareholder === undefined) {
      this.props.history.push(redirectTo);
      return;
    }

    // load settings
    this.setUpSettings();

    this.props.setBreadcrumb([
      {
        label: this.props.user && this.props.user.role === "admin" ? "Tableau de bord" : "Actionnaires",
        link: redirectTo,
      },
      { label: `Paramètres ${this.props.shareholder.name}`, link: null },
    ]);
  }

  setUpSettings = () => {
    this.props.setLoading(true);
    this.props.beginLoadSettings(this.props.shareholder.id).then((res) => {
      // check if templates
      let templateSettings = this.props.settings.find((s) => s.key === "templateFiles");
      if (templateSettings && templateSettings.value) {
        let templates = JSON.parse(templateSettings.value);
        let quotationFile = templates.find((t) => t.template === "quotation");
        let offerFile = templates.find((t) => t.template === "offer");

        this.setState(
          {
            quotationFile: !_.isUndefined(quotationFile) ? quotationFile : null,
            quotationFileExists: !_.isUndefined(quotationFile) ? true : false,
            offerFile: !_.isUndefined(offerFile) ? offerFile : null,
            offerFileExists: !_.isUndefined(offerFile) ? true : false,
          },
          () => {
            this.props.beginLoadTemplatesContentBlocs(this.props.shareholder.id).then((res) => {
              this.props.setLoading(false);
            });
          }
        );
      } else {
        this.props.setLoading(false);
      }
    });
  };

  loadSettingContent = (key) => {
    switch (key) {
      case "adjustmentCost":
        return <AdjustmentCostSettingsPanel shareholderId={this.props.match.params.id} />;

      case "display":
        return <DisplaySettingsPanel shareholderId={this.props.match.params.id} />;

      case "spotRisk":
        return <RiskSpotSettingsPanel shareholderId={this.props.match.params.id} />;

      case "eurochf":
        return <EuroChfSettingsPanel shareholderId={this.props.match.params.id} />;

      case "quotation":
        return <QuotationSettingsPanel shareholderId={this.props.match.params.id} />;

      case "templates":
        return (
          <TemplatesSettingsPanel
            shareholderId={this.props.match.params.id}
            handleSelectedFile={this.handleSelectedFile}
            quotationFile={this.state.quotationFile}
            quotationFileExists={this.state.quotationFileExists}
            offerFile={this.state.offerFile}
            offerFileExists={this.state.offerFileExists}
            handleDeleteFile={this.handleDeleteFile}
            resetUpload={this.state.resetUpload}
            setUpSettings={this.setUpSettings}
          />
        );

      default:
        break;
    }
  };

  handleUpdateSettings = (e) => {
    e.preventDefault();

    if (this.state.disaplyKey === "templates") {
      let templates = [];
      templates.push(this.state.quotationFile);
      templates.push(this.state.offerFile);

      let body = new FormData();
      body.append("shareholderId", this.props.shareholder.id);

      // check if quotation file has to be sent
      if (!_.isNull(this.state.quotationFile)) {
        body.append("templates", this.state.quotationFile);
        body.append("isQuotationFileExists", true);
      } else {
        body.append("isQuotationFileExists", false);
      }

      // check if offer file has to be sent
      if (!_.isNull(this.state.offerFile)) {
        body.append("templates", this.state.offerFile);
        body.append("isOfferFileExists", true);
      } else {
        body.append("isOfferFileExists", false);
      }

      this.props
        .beginUpdateDocumentTemplates(body)
        .then((res) => {
          this.setState({ resetUpload: true }, () => {
            this.setUpSettings();
            this.props.setSnackbar({ open: true, severity: "success", message: "Paramètres des modèles de documents mis à jour avec succès", duration: 3000 });
          });
        })
        .catch((error) => {
          this.props.setSnackbar({
            open: true,
            severity: "error",
            message: "Impossible d'enregistrer les paramètres des modèles de documents.",
            duration: 3000,
          });
        });
    } else {
      // check email addresses for quotation notidications
      if (this.state.disaplyKey === "quotation") {
        let addresses = [];

        // chcek emailTo
        let emailToIsValid = true;
        let emailToSetting = this.props.settings.find((s) => s.key === "emailTo");
        if (emailToSetting && !_.isEmpty(emailToSetting.value)) {
          addresses = _.split(emailToSetting.value, ";");
          for (let index = 0; index < addresses.length; index++) {
            const currentAddress = addresses[index];
            emailToIsValid = validator.isEmail(currentAddress);
          }
        }

        let emailCcSetting = this.props.settings.find((s) => s.key === "emailCc");
        if (emailCcSetting && !_.isEmpty(emailCcSetting.value)) {
          addresses = _.split(emailCcSetting.value, ";");
          for (let index = 0; index < addresses.length; index++) {
            const currentAddress = addresses[index];
            emailToIsValid = validator.isEmail(currentAddress);
          }
        }

        if (!emailToIsValid) {
          this.props.setSnackbar({ open: true, severity: "error", message: "Certaines des adresses email rentrées ne sont pas valides.", duration: 3000 });
          return;
        }
      }

      // build body object
      let body = {
        shareholderId: this.props.shareholder.id,
        updatedSettings: this.props.settings,
      };
      this.props.setLoading(true);
      this.props.beginUpdateSettings(body).then((res) => {
        this.props
          .beginLoadSettings(this.props.shareholder.id)
          .then(() => {
            this.props.setLoading(false);
            this.props.setSnackbar({ open: true, severity: "success", message: "Paramètres mis à jour avec succès", duration: 3000 });
          })
          .catch((err) => {
            console.log("handleUpdateSettings", err);
          });
      });
    }
  };

  handleSelectedFile = (fileType, exists, e) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          [fileType]: e.target.files[0],
          [exists]: false,
          resetUpload: false,
        };
      },
      () => {
        // console.log("state = ", this.state);
      }
    );
  };

  handleDeleteFile = (type) => {
    // build body object
    let body = {
      shareholderId: this.props.shareholder.id,
      template: type,
    };
    this.props.setLoading(true);
    this.props
      .beginDeleteDocumentTemplates(body)
      .then((res) => {
        this.props.setLoading(false);

        this.setUpSettings();

        this.props.setSnackbar({ open: true, severity: "success", message: "Modèle de document supprimé avec succès.", duration: 3000 });
      })
      .catch((error) => {
        this.props.setLoading(false);
        console.log("error", error);
        this.props.setSnackbar({ open: true, severity: "error", message: error.msg, duration: 3000 });
      });
  };

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <ShareholderHeaderBlock shareholderId={this.props.match.params.id} />
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Paramètres</p>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="small" onClick={this.handleUpdateSettings}>
                Enregistrer
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3} style={{ borderRight: "solid 1px #ccc" }}>
              <Grid container spacing={0} direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    variant={this.state.disaplyKey === "display" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "display" })}
                  >
                    Affichage
                  </Button>
                </Grid>
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    variant={this.state.disaplyKey === "quotation" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "quotation" })}
                  >
                    Cotation
                  </Button>
                </Grid>
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    variant={this.state.disaplyKey === "adjustmentCost" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "adjustmentCost" })}
                  >
                    Coûts d'ajustements
                  </Button>
                </Grid>
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    variant={this.state.disaplyKey === "spotRisk" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "spotRisk" })}
                  >
                    Risque SPOT
                  </Button>
                </Grid>
                {/* <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    variant={this.state.disaplyKey === "eurochf" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "eurochf" })}
                  >
                    Taux de change
                  </Button>
                </Grid> */}

                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    variant={this.state.disaplyKey === "templates" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "templates" })}
                  >
                    Offres
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              {this.loadSettingContent(this.state.disaplyKey)}
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  user: state.user,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  beginLoadSettings: (shareholderId) => dispatch(beginLoadSettings(shareholderId)),
  beginUpdateSettings: (body) => dispatch(beginUpdateSettings(body)),
  beginUpdateDocumentTemplates: (data) => dispatch(beginUpdateDocumentTemplates(data)),
  beginLoadTemplatesContentBlocs: (shareholderId) => dispatch(beginLoadTemplatesContentBlocs(shareholderId)),
  beginDeleteDocumentTemplates: (body) => dispatch(beginDeleteDocumentTemplates(body)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
