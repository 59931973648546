const validate = (val, rules, connectedValue) => {
  let isValid = true;
  for (let rule in rules) {
    switch (rule) {
      case "isEmail":
        isValid = isValid && emailValidator(val);
        break;
      case "minLength":
        isValid = isValid && minLengthValidator(val, rules[rule]);
        break;
      case "equalTo":
        isValid = isValid && equalToValidator(val, connectedValue[rule]);
        break;
      case "notEmpty":
        isValid = isValid && notEmptyValidator(val);
        break;
      case "requiredLength":
        isValid = isValid && requiredLengthValidator(val, rules[rule]);
        break;
      case "onlyNumbers":
        isValid = isValid && onlyNumbers(val);
        break;
      case "isNumber":
        isValid = isValid && isNumber(parseFloat(val));
        break;

      default:
        isValid = true;
    }
  }
  return isValid;
};

const emailValidator = (val) => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(val);
};

const minLengthValidator = (val, minLength) => {
  return val.length >= minLength;
};

const equalToValidator = (val, checkValue) => {
  return val === checkValue;
};

const notEmptyValidator = (val) => {
  return val.length > 0;
};

const requiredLengthValidator = (val, requiredLength) => {
  return val.length === requiredLength;
};

const onlyNumbers = (val) => {
  return /^\d+$/.test(val);
};

const isNumber = (val) => {
  return !isNaN(val);
};

export default validate;
