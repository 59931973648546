/**
 *
 * @file GraphTrends.js
 * @version 1.0
 * @description Market info graaphic trend component point view
 *
 */

// load base libs
import React from "react";

// load numeral
import Numeral from "numeral";

// load Material UI components
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { styled } from "@material-ui/core/styles";

const CondensedTableCell = styled(TableCell)({
  padding: 0,
  paddingTop: 2,
  paddingBottom: 2,
  textAlign: "center",
  // borderBottom: "dashed 1px rgba(0, 0, 0, 0.06)",
  borderBottomStyle: "dashed",
});

const GraphTrends = ({ tendencies }) => {
  const tableFontsize = "0.7rem";

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <CondensedTableCell></CondensedTableCell>
            <CondensedTableCell>
              <Typography style={{ fontSize: tableFontsize }}>{tendencies[0]?.daybeforePrice?.date}</Typography>
            </CondensedTableCell>
            <CondensedTableCell>
              <Typography style={{ fontSize: tableFontsize }}>{tendencies[0]?.lastPrice?.date}</Typography>
            </CondensedTableCell>
            <CondensedTableCell>
              <Typography style={{ fontSize: tableFontsize }}>Delta</Typography>
            </CondensedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tendencies?.map((tendency, index) => {
            return (
              <TableRow key={index}>
                <CondensedTableCell style={{ textAlign: "left" }}>
                  <Typography style={{ fontSize: tableFontsize }}>{tendency?.name}</Typography>
                </CondensedTableCell>
                <CondensedTableCell>
                  <Typography style={{ fontSize: tableFontsize }}>{Numeral(tendency?.daybeforePrice?.price).format("0.000")}</Typography>
                </CondensedTableCell>
                <CondensedTableCell>
                  <Typography style={{ fontSize: tableFontsize }}>{Numeral(tendency?.lastPrice?.price).format("0.000")}</Typography>
                </CondensedTableCell>
                <CondensedTableCell>
                  <Typography
                    style={{
                      fontSize: tableFontsize,
                      color: tendency?.trend === "up" ? "rgb(68, 145, 135)" : tendency?.trend === "down" ? "rgb(243, 95, 95)" : "rgb(128, 128, 128)",
                    }}
                  >
                    {Numeral(tendency.diff).format("0.000")}
                  </Typography>
                </CondensedTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GraphTrends;
