/**
 * AppEASA
 *
 * @file AdminReportingAccess.js
 * @version 1.0
 * @description component used to check admin reporting's view access
 *
 */

// base components
import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { beginLoadReports } from "../../../actions/reports";

// Custom UI Components
import ReportItem from "../../reportItem/ReportItem";
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let redirectTo = this.props.user && this.props.user.role === "admin" ? "/dashboard" : "/shareholders";
    if (this.props.shareholder === undefined) {
      this.props.history.push(redirectTo);
      return;
    }
    this.props.beginLoadReports(this.props.shareholder.id);
    this.props.setBreadcrumb([
      {
        label: this.props.user && this.props.user.role === "admin" ? "Tableau de bord" : "Actionnaires",
        link: redirectTo,
      },
      { label: `Portefeuille ${this.props.shareholder.name}`, link: null },
    ]);
  }

  downloadReport = (e, reportId, filename, mimeType) => {
    e.preventDefault();

    // console.log("downloadReport", this.props.match.params.id);
    // console.log("reportId", reportId);
    // console.log("filename", filename);
    // console.log("mimeType", mimeType);

    // enable loading
    this.props.setLoading(true);

    const body = {
      shareholderId: this.props.match.params.id,
      reportId,
      filename,
      mimeType,
    };
    // console.log("downloadReport body = ", body);

    fetch("/api/reports", {
      method: "POST",
      headers: {
        Accept: mimeType,
        "x-auth-token": localStorage.token,
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);

        // disable loading
        this.props.setLoading(false);

        link.click();
      })
      .catch((err) => {
        console.log("err = ", err);
        // disable loading
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Impossible télecharger le rapport demandé", duration: 3000 });
      });
  };

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <ShareholderHeaderBlock shareholderId={this.props.match.params.id} />
          {this.props.reports &&
            this.props.reports.length > 0 &&
            this.props.reports.map((reportGroup) => {
              return (
                <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <ReportItem reportGroup={reportGroup} downloadReport={this.downloadReport} />
                  </Grid>
                </Grid>
              );
            })}
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  user: state.user,
  reports: state.reports,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  beginLoadReports: (shareholderId) => dispatch(beginLoadReports(shareholderId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reporting));
