/**
 * AppEASA
 *
 * @file users.js
 * @version 1.0
 * @description users reducer
 *
 */

// import redux action type
import { SET_USERS, ADD_USER, UPDATE_USER, DELETE_USER } from "../actions/types";

// set initial state
const initialState = [];

// export reducer
export default function usersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_USERS:
      return payload;

    case ADD_USER:
      return [...state, payload];

    case UPDATE_USER:
      return state.map((user) => {
        if (user.id === action.id) {
          return { ...action.update };
        } else {
          return user;
        }
      });

    case DELETE_USER:
      return state.filter((user) => user.id !== payload);

    default:
      return state;
  }
}
