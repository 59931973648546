/**
 * AppEASA
 *
 * @file marketstructures.js
 * @version 1.0
 * @description redux market structures' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_MARKETSTRUCTURES } from "./types";

// import redux actions
import setAuthToken from "../utils/setAuthToken";

// load market structures
export const beginLoadMarketstructures = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/marketstructures", config);

      if (res.status === 200) {
        dispatch(setMarketstructures(res.data.marketstructures));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les paramètres de volatilité."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadVolatilities = ", err);
      reject({ status: false });
    }
  });

// load current market structure
export const beginLoadCurrentMarketstructure = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/marketstructures/current", config);

      if (res.status === 200) {
        dispatch(setMarketstructures(res.data.marketstructure));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les paramètres de volatilité."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadVolatilities = ", err);
      reject({ status: false });
    }
  });

// set market structures
export const setMarketstructures = (marketstructures) => ({
  type: SET_MARKETSTRUCTURES,
  payload: marketstructures,
});
