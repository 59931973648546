import axios from "axios";

import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_USER, SET_CONFIG, SET_SHAREHOLDERS, SET_SETTINGS, SET_USERS } from "./types";

// first step authentication (username and password)
export const beginLogin = (email, password) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const body = {
      email,
      password,
    };

    //DEBUG && console.log("beginLogin body = ", body);

    try {
      await axios
        .post("/api/auth", body, config)
        .then((response) => {
          DEBUG && console.log("/api/auth response = ", response);

          if (parseInt(response.data.doubleAuthFactorEnabled) === 1) {
            // second auth factor is required
            resolve({ status: response.status, otpToken: response.data.otpToken, doubleAuthFactorEnabled: true, authMethod: response.data.authMethod });
          } else {
            // second auth factor is not required
            // log user in
            dispatch({
              type: LOGIN_SUCCESS,
              payload: response.data.token,
            });

            // resolve
            resolve({ loginStatus: true, doubleAuthFactorEnabled: false, status: response.status });
          }
        })
        .catch((err) => {
          //console.log("/api/auth err = ", err.response.data);
          dispatch({
            type: LOGIN_FAIL,
          });
          reject({ loginStatus: false, msg: err.response.data.errors[0].msg });
        });
    } catch (err) {
      //console.log("err = ", err);
      dispatch({
        type: LOGIN_FAIL,
      });
      reject({ loginStatus: false, msg: "Connexion au serveur impossible, essayez plus tard ou contacter notre équipe de support." });
    }
  });

// second step authentication (OTP challenge)
export const beginOTPChallenge = (username, otp, otpToken) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": otpToken,
      },
    };

    const body = {
      email: username,
      otp,
    };

    DEBUG && console.log("beginOTPChallenge body = ", body);

    try {
      const res = await axios.post("/api/auth/otp", body, config);
      DEBUG && console.log("/api/auth/otp res = ", res);
      if (res.status === 200) {
        if (res.status === 200) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.token,
          });
          resolve({ loginStatus: true });
        } else {
          let err = {
            response: {
              data: {
                errors: ["Impossible de faire ceci"],
              },
            },
          };
          throw err;
        }
      }
      // console.log('ACTION login res.data=', res.data);
      // dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: res.data,
      // });
      // dispatch(setNavbarLocation(res.data.redirect));
      // dispatch(setRBAC(res.data.rbac));
      // dispatch(loadUser());
    } catch (err) {
      console.log("beginOTPChallenge catch err = ", err.response.data);

      reject(err.response.data);
    }
  });

// reset login process
export const beginResetLoginProcess = (username, otpToken) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": otpToken,
      },
    };

    const body = {
      email: username,
    };

    DEBUG && console.log("beginResetLoginProcess otpToken = ", otpToken);

    try {
      const res = await axios.post("/api/auth/reset", body, config);
      DEBUG && console.log("/api/auth/reset res = ", res);
      resolve();
    } catch (err) {
      reject();
    }
  });

// Logout user
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: SET_USER, payload: {} });
  dispatch({ type: SET_USERS, payload: [] });
  dispatch({ type: SET_CONFIG, payload: [] });
  dispatch({ type: SET_SETTINGS, payload: [] });
  dispatch({ type: SET_SHAREHOLDERS, payload: [] });
};

// reset user password
export const beginResetUserPassword = (email) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;
    DEBUG && console.log("beginResetUserPassword email = ", email);
    try {
      const res = await axios.get("/api/auth/newpassword/" + email);
      DEBUG && console.log("/api/auth/newpassword res = ", res);
      resolve({ resetStatus: true });
    } catch (err) {
      console.log(err.response);
      reject({ resetStatus: false, msg: err.response.data.errors[0].msg });
    }
  });
