/**
 * AppEASA
 *
 * @file Market.js
 * @version 1.0
 * @description market info view
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import {
  beginLoadPowerDePrices,
  beginLoadPowerChPrices,
  beginLoadPowerDeChSpreads,
  beginLoadFxRates,
  beginLoadCoalPrices,
  beginLoadBrentPrices,
  beginLoadGazTHEPrices,
  beginLoadCarbonPrices,
} from "../../../actions/market";

// Custom UI components
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

import GraphCard from "../../market/GraphCard";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      powerDETitle: "",
      powerDePrices: [],
      powerDePricesTendencies: [],
      powerDESource: "",
      powerChTitle: "",
      powerChPrices: [],
      powerChPricesTendencies: [],
      powerChSource: "",
      sdSpreadTitle: "",
      sdSpread: [],
      sdSpreadTendencies: [],
      sdSpreadSource: "",
      fxRatesTitle: "",
      fxRatesPrices: [],
      fxRatesTendencies: [],
      fxRatesSource: [],
      coalTitle: "",
      coalPrices: [],
      coalTendencies: [],
      coalSource: "",
      brentTitle: "",
      brentPrices: [],
      brentTendencies: [],
      brentSource: "",
      gasTHETitle: "",
      gasTHESource: "",
      gasTHEPrices: [],
      gasTHETendencies: [],
      carbonTitle: "",
      carbonPrices: [],
      carbonTendencies: [],
      carbonSource: "",
    };
  }

  componentDidMount() {
    this.props.setBreadcrumb([{ label: "Vous êtes ici : Infos marché", link: null }]);

    // load power ch prices
    this.props.beginLoadPowerChPrices().then((results) => {
      // console.log(results);
      this.setState({
        powerChTitle: results.title,
        powerChPrices: [results.prices.calYc1, results.prices.calYc2, results.prices.calYc3],
        powerChPricesTendencies: [results.tendencies.calYc1, results.tendencies.calYc2, results.tendencies.calYc3],
        powerChSource: results.source,
      });
    });

    // load power de prices
    this.props.beginLoadPowerDePrices().then((results) => {
      // console.log(results);
      this.setState({
        powerDETitle: results.title,
        powerDePrices: [results.prices.calYc1, results.prices.calYc2, results.prices.calYc3],
        powerDePricesTendencies: [results.tendencies.calYc1, results.tendencies.calYc2, results.tendencies.calYc3],
        powerDESource: results.source,
      });
    });

    // load spread de ch
    this.props.beginLoadPowerDeChSpreads().then((results) => {
      // console.log(results);
      this.setState({
        sdSpreadTitle: results.title,
        sdSpread: [results.prices.calYc1, results.prices.calYc2, results.prices.calYc3],
        sdSpreadTendencies: [results.tendencies.calYc1, results.tendencies.calYc2, results.tendencies.calYc3],
        sdSpreadSource: results.source,
      });
    });

    // load fx rates
    this.props.beginLoadFxRates().then((results) => {
      // console.log(results);
      this.setState({
        fxRatesTitle: results.title,
        fxRatesPrices: [results.prices.EUR_CHF, results.prices.EUR_USD],
        fxRatesTendencies: [results.tendencies.EUR_CHF, results.tendencies.EUR_USD],
        fxRatesSource: results.source,
      });
    });

    // load coal prices
    this.props.beginLoadCoalPrices().then((results) => {
      // console.log(results);
      this.setState({
        coalTitle: results.title,
        coalPrices: [results.prices],
        coalTendencies: [results.tendencies],
        coalSource: results.source,
      });
    });

    // load brent prices
    this.props.beginLoadBrentPrices().then((results) => {
      // console.log(results);
      this.setState({
        brentTitle: results.title,
        brentPrices: [results.prices.USD, results.prices.EUR],
        brentTendencies: [results.tendencies.USD, results.tendencies.EUR],
        brentSource: results.source,
      });
    });

    // load gaz THE prices
    this.props.beginLoadGazTHEPrices().then((results) => {
      // console.log(results);
      this.setState({
        gasTHETitle: results.title,
        gasTHEPrices: [results.prices.calYc1, results.prices.calYc2, results.prices.calYc3],
        gasTHETendencies: [results.tendencies.calYc1, results.tendencies.calYc2, results.tendencies.calYc3],
        gasTHESource: results.source,
      });
    });

    // load carbon prices
    this.props.beginLoadCarbonPrices().then((results) => {
      // console.log(results);
      this.setState({
        carbonTitle: results.title,
        carbonPrices: [results.prices],
        carbonTendencies: [results.tendencies],
        carbonSource: results.source,
      });
    });
  }

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          {this.props.user && this.props.user.role === "shareholder" ? (
            <ShareholderHeaderBlock shareholderId={this.props.user && this.props.user.shareholderId} />
          ) : null}
          <Grid container direction="row" alignItems="flex-start" justify="flex-start">
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.powerChTitle}
                traces={this.state.powerChPrices}
                legend={true}
                tendencies={this.state.powerChPricesTendencies}
                source={this.state.powerChSource}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.powerDETitle}
                traces={this.state.powerDePrices}
                legend={true}
                tendencies={this.state.powerDePricesTendencies}
                source={this.state.powerDESource}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="flex-start" justify="flex-start">
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.sdSpreadTitle}
                traces={this.state.sdSpread}
                legend={true}
                tendencies={this.state.sdSpreadTendencies}
                source={this.state.sdSpreadSource}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.fxRatesTitle}
                traces={this.state.fxRatesPrices}
                legend={true}
                tendencies={this.state.fxRatesTendencies}
                source={this.state.fxRatesSource}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="flex-start" justify="flex-start">
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.coalTitle}
                traces={this.state.coalPrices}
                legend={true}
                tendencies={this.state.coalTendencies}
                source={this.state.coalSource}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.brentTitle}
                traces={this.state.brentPrices}
                legend={true}
                tendencies={this.state.brentTendencies}
                source={this.state.brentSource}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="flex-start" justify="flex-start">
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.gasTHETitle}
                traces={this.state.gasTHEPrices}
                legend={true}
                tendencies={this.state.gasTHETendencies}
                source={this.state.gasTHESource}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <GraphCard
                title={this.state.carbonTitle}
                traces={this.state.carbonPrices}
                legend={true}
                tendencies={this.state.carbonTendencies}
                source={this.state.carbonSource}
              />
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  beginLoadPowerDePrices: () => dispatch(beginLoadPowerDePrices()),
  beginLoadPowerChPrices: () => dispatch(beginLoadPowerChPrices()),
  beginLoadPowerDeChSpreads: () => dispatch(beginLoadPowerDeChSpreads()),
  beginLoadFxRates: () => dispatch(beginLoadFxRates()),
  beginLoadCoalPrices: () => dispatch(beginLoadCoalPrices()),
  beginLoadBrentPrices: () => dispatch(beginLoadBrentPrices()),
  beginLoadGazTHEPrices: () => dispatch(beginLoadGazTHEPrices()),
  beginLoadCarbonPrices: () => dispatch(beginLoadCarbonPrices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Market);
