/**
 * AppEASA
 *
 * @file ShareholderDashboardCard.js
 * @version 1.0
 * @description Shareholder's card UI component used for admin and trader shareholder access
 *
 */

// Base component
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isNull } from "lodash";
// Material UI Components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default class ShareholderDashboardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  render() {
    return (
      <div>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <img
                  src={`/images/${this.props.shareholder.logo}`}
                  style={{ height: "40px", width: "auto" }}
                  alt={this.props.shareholder.name}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className="title2">{this.props.shareholder.name}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <a
                      href={`http://${this.props.shareholder.website}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {this.props.shareholder.website}
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={0} style={{ marginTop: "1rem" }}>
              <Grid item xs={6}>
                <p className="text-sublabel italic">Gestionnaire</p>
                <p className="text">
                  {!isNull(this.props.shareholder.firstname) &&
                  !isNull(this.props.shareholder.lastname)
                    ? this.props.shareholder.firstname +
                      " " +
                      this.props.shareholder.lastname
                    : "n/a"}
                </p>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              {/* <Grid item>
                <Button
                  color="primary"
                  size="small"
                  component={Link}
                  to={`/reporting/${this.props.shareholder.id}`}
                >
                  Portefeuille
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  component={Link}
                  to={`/quotations/${this.props.shareholder.id}`}
                >
                  Cotations
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  component={Link}
                  to={`/settings/${this.props.shareholder.id}`}
                >
                  Paramètres
                </Button>
              </Grid> */}
              <Grid>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  color="primary"
                  size="small"
                >
                  Outils
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={`/reporting/${this.props.shareholder.id}`}
                  >
                    Portefeuille
                  </MenuItem>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={`/quotations/${this.props.shareholder.id}`}
                  >
                    Cotations
                  </MenuItem>
                  <MenuItem
                    onClick={this.handleClose}
                    component={Link}
                    to={`/settings/${this.props.shareholder.id}`}
                  >
                    Paramètres
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
  }
}
