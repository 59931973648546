/**
 * AppEASA
 *
 * @file Login.js
 * @version 1.0
 * @description Login view
 * Manages the 2 step authentication process and user loading.
 * 1st step is LoginForm componenent
 * 2nd step is OTPForm
 */

// Base components
import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Redux functions
import { beginLogin, beginOTPChallenge, beginResetLoginProcess, beginResetUserPassword } from "../../actions/auth";
import { beginLoadUser } from "../../actions/user";
import { setLoading } from "../../actions/ui";
import { beginLoadAppConfig } from "../../actions/appconfig";

// Resources
import EnerdisLogo from "../../resources/images/enerdis-logo.jpg";
import Loader from "../../components/ui/Loader";

// Custom UI Components
import GradientSeparator from "../ui/GradientSeparator";
import LoginForm from "../login/LoginForm";
import OTPForm from "../login/OTPForm";
import ForgotPasswordForm from "../login/ForgotPasswordForm";

// Material UI Components
import Grid from "@material-ui/core/Grid";

const Login = ({
  beginLogin,
  beginOTPChallenge,
  beginResetLoginProcess,
  isAuthenticated,
  auth,
  user,
  ui,
  setLoading,
  beginLoadUser,
  beginLoadAppConfig,
  beginResetUserPassword,
}) => {
  const [state, updateState] = useState({
    authStep: 1,
    errorMsg: "",
    username: "",
    otpToken: "",
    doubleAuthMethod: "",
    limit: 0,
    resetPassword: false,
    resetPasswordError: "",
    resetPasswordEmail: "",
    successMsg: "",
  });

  const { authStep, errorMsg, username, otpToken, limit, resetPassword, resetPasswordError, resetPasswordEmail, successMsg, doubleAuthMethod } = state;

  // used as props for children components to hide error message
  const hideErrorMsg = () => {
    updateState({ ...state, errorMsg: "" });
  };

  // submit first step authentication
  const submit = (email, password) => {
    //console.log(`Login email:${email} - password: ${password}`);
    setLoading(true);

    beginLogin(email, password)
      .then((result) => {
        if (result.status === 200) {
          // check whether or not doubleAuthFactorEnabled is required
          if (result.doubleAuthFactorEnabled) {
            // set UI ready for second auth factor
            updateState({
              ...state,
              otpToken: result.otpToken,
              authStep: 2,
              username: email,
              doubleAuthMethod: result.authMethod,
            });
            setLoading(false);
          } else {
            // load app config

            beginLoadAppConfig().then(() => {
              // load user
              beginLoadUser().then((result) => {
                //console.log("begin load user result = ", result);
                setLoading(false);
              });
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        updateState({
          ...state,
          errorMsg: error.msg,
        });
        setTimeout(function () {
          hideErrorMsg();
        }, 5000);
      });
  };

  // submit OTP
  const submitOTPChallenge = (otp) => {
    // console.log("otp is = ", otp);
    // console.log("ready for challenge with otpToken = ", this.state.otpToken);
    updateState({
      ...state,
      errorMsg: "",
    });
    setLoading(true);

    beginOTPChallenge(username, otp, otpToken)
      .then((result) => {
        //console.log("result from beginOTPChallenge... ", result);

        // load app config
        beginLoadAppConfig().then(() => {
          // load user
          beginLoadUser().then((result) => {
            //console.log("begin load user result = ", result);
            setLoading(false);
          });
        });
      })
      .catch((error) => {
        console.log("err from beginOTPChallenge... ", error);
        setLoading(false);
        if (error.type === "TokenExpiredError") {
          // // reset login process
          updateState({
            ...state,
            errorMsg: error.msg,
            limit: 3,
          });
          // setLoading(false);
          setTimeout(function () {
            hideErrorMsg();
            resetLoginAttempts();
          }, 5000);
        } else if (error.type === "OTPChallengeError") {
          updateState({
            ...state,
            errorMsg: error.errors[0].msg,
            limit: error.attempts,
          });

          setTimeout(function () {
            hideErrorMsg();
          }, 2000);
        } else if (error.type === "OTPAttemptsError") {
          //
          updateState({
            ...state,
            errorMsg: error.errors[0].msg,
            limit: 3,
          });
          //
          setTimeout(function () {
            hideErrorMsg();
            resetLoginAttempts();
          }, 5000);
        } else {
          resetLoginAttempts();
        }
      });
  };

  // reset state to default when login attempts limit has been reached
  const resetLoginAttempts = () => {
    setLoading(true);
    beginResetLoginProcess(username, otpToken)
      .then(() => {
        updateState({
          ...state,
          authStep: 1,
          errorMsg: "",
          username: "",
          otpToken: null,
          limit: 0,
        });
        setLoading(false);
      })
      .catch((err) => {
        updateState({
          ...state,
          authStep: 1,
          errorMsg: "",
          username: "",
          otpToken: null,
          limit: 0,
        });
        setLoading(false);
      });
  };

  const requestNewPassword = () => {
    updateState({ ...state, resetPassword: true });
  };

  const cancelRequestNewPassword = () => {
    updateState({ ...state, resetPassword: false, resetPasswordEmail: "", resetPasswordError: "", successMsg: "" });
  };

  const updateResetPasswordEmail = (e) => {
    updateState({ ...state, resetPasswordEmail: e.target.value, resetPasswordError: "" });
  };

  const handleResetPassword = () => {
    //console.log("resetPassword...", resetPasswordEmail);

    let isValidEmail =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        resetPasswordEmail
      );

    if (!isValidEmail) {
      updateState({ ...state, resetPasswordError: "Adresse email non valide." });
      return;
    }

    setLoading(true);
    beginResetUserPassword(resetPasswordEmail)
      .then((result) => {
        setLoading(false);
        updateState({ ...state, resetPasswordError: "", successMsg: "Un nouveau mot de passe a été envoyé à l'adresse email indiquée." });
        setTimeout(() => {
          updateState({ ...state, resetPassword: false, resetPasswordError: "", resetPasswordEmail: "", successMsg: "" });
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        updateState({ ...state, resetPasswordError: err.msg });
      });
  };

  // if user is authenticated and not loading, redirect to either Market view or update password
  if (isAuthenticated && !auth.loading) {
    // console.log(user);
    // console.log("Login view isAuthenticated = ", isAuthenticated);
    // console.log("Login view auth.loading = ", auth.loading);
    if (user.setNewPassword === 1) {
      return <Redirect to={"/updatepassword"} />;
    } else {
      return <Redirect to={"/market"} />;
    }
  }

  return (
    <div className="login-wrapper">
      <Loader display={ui} />
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <img src={EnerdisLogo} alt="Enerdis Approvisionnement SA" className="login-logo" />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "1rem" }}>
          <GradientSeparator />
        </Grid>
        <Grid item xs={12}>
          {resetPassword ? (
            <ForgotPasswordForm
              resetPassword={handleResetPassword}
              unsetResetPassword={cancelRequestNewPassword}
              resetPasswordErrorMsg={resetPasswordError}
              updateResetPasswordEmail={updateResetPasswordEmail}
              resetPasswordEmail={resetPasswordEmail}
              successMsg={successMsg}
            />
          ) : authStep === 1 ? (
            <LoginForm submit={submit} errorMsg={errorMsg} hideErrorMsg={hideErrorMsg} setResetPassword={requestNewPassword} />
          ) : authStep === 2 ? (
            <OTPForm
              submitOTPChallenge={submitOTPChallenge}
              errorMsg={errorMsg}
              hideErrorMsg={hideErrorMsg}
              limit={limit}
              doubleAuthMethod={doubleAuthMethod}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  ui: state.ui,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  beginLogin: (username, password) => dispatch(beginLogin(username, password)),
  beginOTPChallenge: (username, otp, otpToken) => dispatch(beginOTPChallenge(username, otp, otpToken)),
  beginLoadAppConfig: () => dispatch(beginLoadAppConfig()),
  beginLoadUser: () => dispatch(beginLoadUser()),
  beginResetLoginProcess: (username, otpToken) => dispatch(beginResetLoginProcess(username, otpToken)),
  beginResetUserPassword: (email) => dispatch(beginResetUserPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
