/**
 * AppEASA
 *
 * @file AddQuotationComponentStepTwo.js
 * @version 1.0
 * @description wizzard step 2 component
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";
import { setLoading } from "../../actions/ui";
import {
  setStep2Loaded,
  setSelectedYears,
  setSelectedEnergyPrices,
  setSelectedEnergyPrice,
  setSelectedGo,
  setSelectedGoPrices,
  setSelectedGoPrice,
  setSelectedGoCustomName,
  setAdjustmentCosts,
  setAdjustmentCost,
  setRiskCosts,
  setRiskCost,
  setMarkupMode,
  setMarkup,
  setYearlyMarkups,
  setValidityMode,
  setValidityDate,
  setValidityTime,
  setValidityDuration,
  setValidityMarkup,
  setValidityCost,
  setVolume,
} from "../../actions/quotation";

// load other libs
import NumberFormat from "react-number-format";
import _ from "lodash";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";

// load UI components
import QuotationEnergyPricesTable from "./QuotationEnergyPricesTable";
import QuotationGoTable from "./QuotationGoTable";
import QuotationAdjustmentCostsTable from "./QuotationAdjustmentCostsTable";
import QuotationSpotRiskTable from "./QuotationSpotRiskTable";
import QuotationYearlyMarginTable from "./QuotationYearlyMarginTable";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import TextField from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="'"
      isNumericString
      mask="_"
      fixedDecimalScale={true}
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "right", fontSize: "0.8rem" }}
    />
  );
}

function VolumeFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="'"
      isNumericString
      mask="_"
      fixedDecimalScale={true}
      decimalScale={0}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "right", fontSize: "0.8rem" }}
    />
  );
}

class AddQuotationComponentStepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxduration: 5,
      startYear: "",
      endYear: "",
      years: [],
      energyPricesTableExpanded: false,
      energyPrices: [],
      energyPrice: 0,
      goPricesTableExpanded: false,
      selectedGo: 1,
      goPrices: [],
      goCustomName: "",
      adjustmentTableExpanded: false,
      adjustmentCosts: [],
      adjustmentCost: 0,
      spotTableExpanded: false,
      riskCosts: [],
      riskCost: 0,
      markupPanelExpanded: false,
      markupMode: "global",
      markup: 0,
      yearlyMarkups: [],
      volatility: 0.4,
      volatilitySD: 0.43,
      validityPanelExpanded: false,
      validityMode: "day",
      validityDate: null,
      validityTime: null,
      validityDuration: 0,
      validityMarkup: 0,
      validityCost: 0,
      volumeMWh: 0,
    };
  }

  componentDidMount() {
    /**
     * if step 2 has already been loaded before, load data from redux state (when comming back from step 3)
     */
    if (this.props.quotation.step2Loaded) {
      // console.log("load data from redux state...");

      let maxDurationSetting = this.props.settings.find((s) => s.key === "maxduration");
      let maxDuration = maxDurationSetting && parseInt(maxDurationSetting.value) ? parseInt(maxDurationSetting.value) : 5;

      // let defaultMarkupSetting = this.props.settings.find((s) => s.key === "defaultMarkup");
      // let defaultMarkup = defaultMarkupSetting && parseFloat(defaultMarkupSetting.value) ? parseFloat(defaultMarkupSetting.value) : 0;

      // let defaultDurationSetting = this.props.settings.find((s) => s.key === "defaultDuration");
      // let defaultDuration = defaultDurationSetting && parseInt(defaultDurationSetting.value) ? parseInt(defaultDurationSetting.value) : 0;

      this.setState(
        {
          maxDuration,
          startYear: this.props.quotation.startYear,
          endYear: this.props.quotation.endYear,
          years: this.props.quotation.years,
          energyPrices: this.props.quotation.energyPrices,
          energyPrice: this.props.quotation.eneryPrice,
          selectedGo: this.props.quotation.selectedGo,
          goPrices: this.props.quotation.goPrices,
          goCustomName: this.props.quotation.goCustomName,
          adjustmentCosts: this.props.quotation.adjustmentCosts,
          adjustmentCost: this.props.quotation.adjustmentCost,
          riskCosts: this.props.quotation.riskCosts,
          riskCost: this.props.quotation.riskCost,
          markupMode: this.props.quotation.markupMode,
          markup: this.props.quotation.markup,
          yearlyMarkups: this.props.quotation.yearlyMarkups,
          validityMode: this.props.quotation.validityMode,
          validityDate: moment(this.props.quotation.validityDate).format("YYYY-MM-YY"),
          validityTime: moment(this.props.quotation.validityTime).format("YYYY-MM-YY HH:mm"),
          validityDuration: this.props.quotation.validityDuration,
          validityMarkup: this.props.quotation.validityMarkup,
          validityCost: this.props.quotation.validityCost,
          volumeMWh: this.props.quotation.volumeMWh,
        },
        () => {
          this.props.setStep2Loaded(true);
          this.props.handleStepCompleted("step2Completed", true);
        }
      );
    } else {
      /**
       * otherwise, load default data
       */
      // console.log("setup data for first use...");
      // set default start and end year
      let startYear = new Date().getFullYear() + 1;
      let endYear = startYear + 2;

      // build customized Prices array
      let duration = endYear - startYear + 1;

      let years = [];

      let energyPrices = [];
      let energyPrice = 0;

      let adjustmentCosts = [];
      let adjustmentCost = 0;

      let riskCosts = [];
      let riskCost = 0;

      // first step markup setup
      let markupMode = "global";
      let markup = 0;
      let defaultMarkupModeSetting = this.props.settings.find((s) => s.key === "defaultMarkupMode");
      markupMode = defaultMarkupModeSetting.value;

      let yearlyMarkups = [];
      let yearlyMarkupsSetting = this.props.settings.find((s) => s.key === "yearlyMarkups");
      let defaultYearlyMarkups = yearlyMarkupsSetting ? JSON.parse(yearlyMarkupsSetting.value) : [];

      for (let index = 0; index < duration; index++) {
        let currentYear = startYear + index;

        years.push(currentYear);

        // energy prices
        let yearPrice = this.props.quotation.marketEnergyPrices && this.props.quotation.marketEnergyPrices.find((p) => p.year === currentYear);

        energyPrices.push({
          priceId: yearPrice.priceId,
          symbol: yearPrice.symbol,
          datasetId: yearPrice.datasetId,
          year: currentYear,
          mwhEuro: yearPrice.mwhEuro,
          exchangeRate: yearPrice.exchangeRate,
        });

        energyPrice +=
          parseFloat(yearPrice.mwhEuro) && parseFloat(yearPrice.exchangeRate) ? parseFloat(yearPrice.mwhEuro) * parseFloat(yearPrice.exchangeRate) : 0;

        // adjustment costs
        let yearlyAdjustmentCost = this.props.quotation.marketAdjustmentCosts && this.props.quotation.marketAdjustmentCosts.find((p) => p.year === currentYear);
        adjustmentCosts.push({
          year: yearlyAdjustmentCost.year,
          cost: yearlyAdjustmentCost.cost,
          exchangeRate: yearPrice.exchangeRate,
        });
        adjustmentCost +=
          parseFloat(yearlyAdjustmentCost.cost) && parseFloat(yearPrice.exchangeRate)
            ? parseFloat(yearlyAdjustmentCost.cost) * parseFloat(yearPrice.exchangeRate)
            : 0;

        // sport risk
        let yearlySpotRisk = this.props.quotation.marketRiskCosts && this.props.quotation.marketRiskCosts.find((p) => p.year === currentYear);
        riskCosts.push({
          year: yearlySpotRisk.year,
          cost: yearlySpotRisk.cost,
          exchangeRate: yearPrice.exchangeRate,
        });
        riskCost +=
          parseFloat(yearlySpotRisk.cost) && parseFloat(yearPrice.exchangeRate) ? parseFloat(yearlySpotRisk.cost) * parseFloat(yearPrice.exchangeRate) : 0;

        // yearly markup
        let defaultYearlyMarkup = defaultYearlyMarkups && defaultYearlyMarkups.find((m) => m.year === currentYear);
        yearlyMarkups.push({
          year: currentYear,
          markup: defaultYearlyMarkup ? parseFloat(defaultYearlyMarkup.markup) : 0,
        });
      }

      // adjust energy price
      energyPrice = energyPrice / years.length; // get average price
      energyPrice = energyPrice / 10; // convernt prices in CHF/MWh to prices in CHF ct/kWh

      // adjust adjustment costs
      adjustmentCost = adjustmentCost / years.length; // get average price
      adjustmentCost = adjustmentCost / 10; // convernt prices in CHF/MWh to prices in CHF ct/kWh

      // adjust risk costs
      riskCost = riskCost / years.length; // get average price
      riskCost = riskCost / 10; // convernt prices in CHF/MWh to prices in CHF ct/kWh

      // second step markup setup
      if (markupMode === "global") {
        let defaultMarkupSetting = this.props.settings.find((s) => s.key === "defaultMarkup");
        markup = defaultMarkupSetting.value;
      }

      // setup duration
      let maxDurationSetting = this.props.settings.find((s) => s.key === "maxduration");
      let maxDuration = maxDurationSetting && parseInt(maxDurationSetting.value) ? parseInt(maxDurationSetting.value) : 5;

      // manage GO
      let firstAvailableGo = this.props.quotation.marketGoPrices && this.props.quotation.marketGoPrices[0];

      // manage validity
      let defaultValidityModeSetting = this.props.settings.find((s) => s.key === "defaultValidityMode");
      let validityMode = defaultValidityModeSetting.value;
      //console.log("validityMode = ", validityMode);

      let validityDuration = 0;
      if (defaultValidityModeSetting.value === "day") {
        //console.log("cotation in day...");
        let defaultDurationSetting = this.props.settings.find((s) => s.key === "defaultDuration");
        validityDuration = defaultDurationSetting.value;
        // console.log(defaultDurationSetting);
      }

      // manage volume
      let volumeMWh = 0;
      if (this.props.quotation && this.props.quotation.selectedPod !== "pricetype") {
        for (let index = 0; index < 4; index++) {
          const quarter = this.props.quotation.marketStructure[`q${index + 1}`];
          volumeMWh += parseFloat(quarter.hp.consumption);
          volumeMWh += parseFloat(quarter.hc.consumption);
        }
      }

      this.setState(
        {
          maxDuration,
          startYear,
          endYear,
          years,
          energyPrices,
          energyPrice,
          adjustmentCosts,
          adjustmentCost,
          riskCosts,
          riskCost,
          markupMode,
          markup,
          yearlyMarkups,
          validityMode,
          validityDate: moment(),
          validityTime: moment(),
          validityDuration,
          volumeMWh,
        },
        () => {
          // console.log("this.state = ", this.state);
          this.updateGoPrice(firstAvailableGo.id);
          this.props.setSelectedYears(this.state.startYear, this.state.endYear, this.state.years);
          this.props.setSelectedEnergyPrice(this.state.energyPrice);
          this.props.setSelectedEnergyPrices(this.state.energyPrices);
          this.props.setAdjustmentCosts(this.state.adjustmentCosts);
          this.props.setAdjustmentCost(this.state.adjustmentCost);
          this.props.setRiskCosts(this.state.riskCosts);
          this.props.setRiskCost(this.state.riskCost);
          this.props.setMarkupMode(this.state.markupMode);
          this.updateMarkup(this.state.markup);
          this.props.setYearlyMarkups(this.state.yearlyMarkups);
          this.props.setValidityMode(this.state.validityMode);
          this.props.setValidityDate(moment(this.state.validityDate).format("YYYY-MM-DD"));
          this.props.setValidityTime(moment(this.state.validityTime).format("HH:mm"));
          this.props.setValidityDuration(this.state.validityDuration);
          if (this.state.validityMode === "day") {
            this.updateValidityCost();
          }
          this.props.setStep2Loaded(true);
          this.props.handleStepCompleted("step2Completed", true);
        }
      );
    }
  }

  /**
   * Duration
   */
  increaseStart = () => {
    let currentStartYear = this.state.startYear;
    if (currentStartYear + 1 <= this.state.endYear) {
      // need to remove one year
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            startYear: prevState.startYear + 1,
            years: prevState.years.filter((year) => year !== prevState.startYear),
            energyPrices: prevState.energyPrices.filter((ep) => ep.year !== prevState.startYear),
            goPrices: prevState.goPrices.filter((go) => go.year !== prevState.startYear),
            adjustmentCosts: prevState.adjustmentCosts.filter((ac) => ac.year !== prevState.startYear),
            riskCosts: prevState.riskCosts.filter((rc) => rc.year !== prevState.startYear),
            yearlyMarkups: prevState.yearlyMarkups.filter((m) => m.year !== prevState.startYear),
          };
        },
        () => {
          this.props.setAdjustmentCosts(this.state.adjustmentCosts);
          this.props.setRiskCosts(this.state.riskCosts);
          this.updateDuration();
          this.props.setYearlyMarkups(this.state.yearlyMarkups);
        }
      );
    }
  };

  decreaseStart = () => {
    let currentYear = new Date().getFullYear();
    let currentStartYear = this.state.startYear;
    if (currentStartYear - 1 > currentYear) {
      let newYearPrice = this.props.quotation.marketEnergyPrices.find((p) => p.year === currentStartYear - 1);

      let newYearGOPrice = { year: currentStartYear - 1, price: 0 };
      if (parseInt(this.state.selectedGo) !== 0) {
        let selectedGO = this.props.quotation.marketGoPrices.find((go) => go.id === parseInt(this.state.selectedGo));
        newYearGOPrice = selectedGO.prices.find((p) => p.year === currentStartYear - 1);
      }
      let newYearadjustmentCost = this.props.quotation.marketAdjustmentCosts.find((c) => c.year === currentStartYear - 1);
      newYearadjustmentCost.exchangeRate = newYearPrice.exchangeRate;

      let newYearRiskCost = this.props.quotation.marketRiskCosts.find((c) => c.year === currentStartYear - 1);
      newYearRiskCost.exchangeRate = newYearPrice.exchangeRate;

      this.setState(
        (prevState) => {
          return {
            ...prevState,
            startYear: prevState.startYear - 1,
            years: [prevState.startYear - 1, ...prevState.years],
            energyPrices: [newYearPrice, ...prevState.energyPrices],
            goPrices: [newYearGOPrice, ...prevState.goPrices],
            adjustmentCosts: [newYearadjustmentCost, ...prevState.adjustmentCosts],
            riskCosts: [newYearRiskCost, ...prevState.riskCosts],
            yearlyMarkups: [{ year: currentStartYear - 1, markup: 0 }, ...prevState.yearlyMarkups],
          };
        },
        () => {
          this.props.setSelectedGoPrices(this.state.goPrices);
          this.props.setAdjustmentCosts(this.state.adjustmentCosts);
          this.props.setRiskCosts(this.state.riskCosts);
          this.updateDuration();
          this.props.setYearlyMarkups(this.state.yearlyMarkups);
        }
      );
    }
  };

  increaseEnd = () => {
    let currentYear = new Date().getFullYear();
    let currentEndYear = this.state.endYear;
    if (currentEndYear + 1 <= currentYear + this.state.maxDuration) {
      let newYearPrice = this.props.quotation.marketEnergyPrices.find((p) => p.year === currentEndYear + 1);

      let newYearGOPrice = { year: currentEndYear + 1, price: 0 };
      if (parseInt(this.state.selectedGo) !== 0) {
        let selectedGO = this.props.quotation.marketGoPrices.find((go) => go.id === parseInt(this.state.selectedGo));
        newYearGOPrice = selectedGO.prices.find((p) => p.year === currentEndYear + 1);
      }

      let newYearadjustmentCost = this.props.quotation.marketAdjustmentCosts.find((c) => c.year === currentEndYear + 1);

      newYearadjustmentCost.exchangeRate = newYearPrice.exchangeRate;

      let newYearRiskCost = this.props.quotation.marketRiskCosts.find((c) => c.year === currentEndYear + 1);
      newYearRiskCost.exchangeRate = newYearPrice.exchangeRate;

      this.setState(
        (prevState) => {
          return {
            ...prevState,
            endYear: prevState.endYear + 1,
            years: [...prevState.years, currentEndYear + 1],
            energyPrices: [...prevState.energyPrices, newYearPrice],
            goPrices: [...prevState.goPrices, newYearGOPrice],
            adjustmentCosts: [...prevState.adjustmentCosts, newYearadjustmentCost],
            riskCosts: [...prevState.riskCosts, newYearRiskCost],
            yearlyMarkups: [...prevState.yearlyMarkups, { year: currentEndYear + 1, markup: 0 }],
          };
        },
        () => {
          this.props.setAdjustmentCosts(this.state.adjustmentCosts);
          this.props.setRiskCosts(this.state.riskCosts);
          this.updateDuration();
          this.props.setYearlyMarkups(this.state.yearlyMarkups);
        }
      );
    }
  };

  decreaseEnd = () => {
    let currentEndYear = this.state.endYear;
    if (currentEndYear - 1 >= this.state.startYear) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            endYear: prevState.endYear - 1,
            years: prevState.years.filter((year) => year !== prevState.endYear),
            energyPrices: prevState.energyPrices.filter((ep) => ep.year !== prevState.endYear),
            goPrices: prevState.goPrices.filter((go) => go.year !== prevState.endYear),
            adjustmentCosts: prevState.adjustmentCosts.filter((ac) => ac.year !== prevState.endYear),
            riskCosts: prevState.riskCosts.filter((rc) => rc.year !== prevState.endYear),
            yearlyMarkups: prevState.yearlyMarkups.filter((m) => m.year !== prevState.endYear),
          };
        },
        () => {
          this.props.setAdjustmentCosts(this.state.adjustmentCosts);
          this.props.setRiskCosts(this.state.riskCosts);
          this.updateDuration();
          this.props.setYearlyMarkups(this.state.yearlyMarkups);
        }
      );
    }
  };

  /**
   * update redux state about duration and corresponding prices
   */
  updateDuration = () => {
    // console.log("updateDuration ... ");
    this.props.setSelectedYears(this.state.startYear, this.state.endYear, this.state.years).then(() => {
      this.props.setSelectedEnergyPrices(this.state.energyPrices).then(() => {
        this.updateEnergyPrice();
        this.updateGoPrice(this.state.selectedGo);
      });
    });
  };

  /**
   * Energy prices
   */
  updateCustomizedEnergyPrice = (year, key, value) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          energyPrices: prevState.energyPrices.map(function (p) {
            if (p.year === year) {
              return {
                ...p,
                [key]: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
              };
            } else {
              return p;
            }
          }),
        };
      },
      () => {
        // this.updatePrices();
        this.props.setSelectedEnergyPrices(this.state.energyPrices).then(() => this.updateEnergyPrice());
      }
    );
  };

  handleEnergyPricesTableExpanded = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        energyPricesTableExpanded: !prevState.energyPricesTableExpanded,
      };
    });
  };

  /**
   * update energy price used in quotation
   */
  updateEnergyPrice = () => {
    let energyPrice = 0;
    let yearlyPrices = [];

    for (let index = 0; index < this.props.quotation.energyPrices.length; index++) {
      const currentPrice = this.props.quotation.energyPrices[index];
      let p = parseFloat(currentPrice.mwhEuro) / parseFloat(currentPrice.exchangeRate);
      yearlyPrices.push({ year: currentPrice.year, price: p });
      energyPrice += p;
    }

    energyPrice = energyPrice / this.props.quotation.energyPrices.length; // prices in CHF/MWh
    energyPrice = energyPrice / 10; // prices in CHF ct/KWh

    this.props.setSelectedEnergyPrice(energyPrice);
  };

  /**
   * GO price
   */
  handleGoTableExpanded = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        goPricesTableExpanded: !prevState.goPricesTableExpanded,
      };
    });
  };

  updateGoPrice = (goId) => {
    // set prices array
    let goPrices = [];

    // start go price at 0
    let goPrice = 0;

    // not a custom GO
    if (parseInt(goId) !== 0) {
      // get market go prices
      let selectedMarketGo = this.props.quotation.marketGoPrices && this.props.quotation.marketGoPrices.find((go) => go.id === parseInt(goId));

      for (let index = 0; index < this.state.years.length; index++) {
        const currentYear = this.state.years[index];

        // get market yearly price
        let marketGoYearlyPrice = selectedMarketGo && selectedMarketGo.prices.find((p) => p.year === currentYear);

        // keep current prices
        goPrices.push({
          year: currentYear,
          price: marketGoYearlyPrice && marketGoYearlyPrice.price ? parseFloat(marketGoYearlyPrice.price) : 0,
        });

        // process go price
        goPrice += marketGoYearlyPrice && marketGoYearlyPrice.price ? parseFloat(marketGoYearlyPrice.price) : 0;
      }
    } else {
      for (let index = 0; index < this.state.years.length; index++) {
        const currentYear = this.state.years[index];
        goPrices.push({
          year: currentYear,
          price: 0,
        });
      }
    }

    let averageGoPrice = goPrice / this.state.years.length;
    averageGoPrice = averageGoPrice / 10; // CHF/Mwh to CHF ct/kWh

    this.setState({ selectedGo: parseInt(goId), goPrices, goPrice: averageGoPrice }, () => {
      this.props.setSelectedGo(this.state.selectedGo);
      this.props.setSelectedGoPrices(this.state.goPrices);
      this.props.setSelectedGoPrice(this.state.goPrice);

      // reset custom name
      if (this.props.quotation.selectedGo !== 0) {
        this.setState({ goCustomName: "" }, () => {
          this.props.setSelectedGoCustomName("");
        });
      }
    });
  };

  updateCustomizedGoPrice = (year, value) => {
    if (value !== 0) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            goPrices: prevState.goPrices.map(function (p) {
              if (p.year === year) {
                return {
                  ...p,
                  price: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
                };
              } else {
                return p;
              }
            }),
          };
        },
        () => {
          this.props.setSelectedGoPrices(this.state.goPrices).then(() => {
            let goPrice = 0;
            for (let index = 0; index < this.props.quotation.goPrices.length; index++) {
              const yearlyPrice = this.props.quotation.goPrices[index];
              goPrice += parseFloat(yearlyPrice.price);
            }
            goPrice = goPrice / this.props.quotation.years.length;
            this.props.setSelectedGoPrice(goPrice / 10);
          });
        }
      );
    }
  };

  handleGoCustomNameChange = (e) => {
    this.setState({ goCustomName: e.target.value }, () => {
      this.props.setSelectedGoCustomName(this.state.goCustomName);
    });
  };

  /**
   * Adjsutment costs
   */
  handeAdjustmentTableExpanded = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        adjustmentTableExpanded: !prevState.adjustmentTableExpanded,
      };
    });
  };

  updateAdjustmentCost = (value) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          adjustmentCost: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
        };
      },
      () => {
        this.props.setAdjustmentCost(this.state.adjustmentCost).then(() => {});
      }
    );
  };

  /**
   * Risk costs
   */
  handleSpotTableExpanded = () => {
    this.setState((prevState) => {
      return { ...prevState, spotTableExpanded: !prevState.spotTableExpanded };
    });
  };

  updateRiskCost = (value) => {
    let riskCost = value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    riskCost = riskCost / 10; // convernt CHF/MWh in CHF ct/kWh
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          riskCost,
        };
      },
      () => {
        this.props.setRiskCost(this.state.riskCost).then(() => {});
      }
    );
  };

  /**
   * Validity
   */
  handleValidityPanelExpanded = () => {
    this.setState((prevState) => {
      return { ...prevState, validityPanelExpanded: !prevState.validityPanelExpanded };
    });
  };

  handleValidityModeChange = (e) => {
    this.setState({ validityMode: e.target.value }, () => this.props.setValidityMode(this.state.validityMode));
  };

  handleValidityDateSelectorChange = (date) => {
    this.setState({ validityDate: date }, () => {
      this.props.setValidityDate(moment(this.state.validityDate).format("YYYY-MM-DD")).then(() => {
        this.updateValidityDuration();
      });
    });
  };

  handleValidityTimeSelectorChange = (time) => {
    this.setState({ validityTime: time }, () => {
      this.props.setValidityTime(moment(this.state.validityTime).format("HH:mm")).then(() => {
        this.updateValidityDuration();
      });
    });
  };

  updateValidityDuration = () => {
    let now = moment();
    // console.log("now = ", now);
    let deadline = moment(`${this.props.quotation.validityDate} ${this.props.quotation.validityTime}`, "YYYY-MM-DD HH:mm");
    // console.log("deadline = ", deadline);
    let diffHours = deadline.diff(now, "hours");
    // console.log("diffHours = ", diffHours);
    let diffNbDays = Math.round(diffHours / 24);
    // console.log("diffNbDays = ", diffNbDays);
    this.setState({ validityDuration: _.isNaN(diffNbDays) ? 0 : diffNbDays }, () => {
      this.updateValidityCost();
    });
  };

  handleValidityChange = (action) => {
    let currentNbDays = this.state.validityDuration;

    if (action === "add") {
      // if (currentNbDays <= 7) {

      // }
      currentNbDays++;
    } else if (action === "remove") {
      if (currentNbDays > 0) {
        currentNbDays--;
      }
    }

    this.setState({ validityDuration: currentNbDays }, () => {
      this.props.setValidityDuration(this.state.validityDuration).then(() => this.updateValidityCost());
    });
  };

  updateValidityCost = () => {
    // console.log("updateValidityCost...");

    let validityMarkup = parseFloat(this.state.volatility) * Math.sqrt(parseInt(this.state.validityDuration) / 260) * parseFloat(this.state.volatilitySD);
    // console.log("validityMarkup = ", validityMarkup);
    let validityCost = parseFloat(this.state.energyPrice) * validityMarkup;
    // console.log("validityCost = ", validityCost);
    this.setState({ validityMarkup, validityCost }, () => {
      // console.log("state = ", this.state);
      this.props.setValidityMarkup(this.state.validityMarkup);
      this.props.setValidityCost(this.state.validityCost);
    });
  };

  /**
   * Markup
   */
  handleMarkupPanelExpanded = () => {
    this.setState((prevState) => {
      return { ...prevState, markupPanelExpanded: !prevState.markupPanelExpanded };
    });
  };

  handleMarkupModeChange = (e) => {
    let yearlyMarkups = [];
    for (let index = 0; index < this.state.years.length; index++) {
      const currentYear = this.state.years[index];
      yearlyMarkups.push({
        year: currentYear,
        markup: 0,
      });
    }

    this.setState({ markupMode: e.target.value, markup: 0, yearlyMarkups }, () => {
      this.props.setMarkupMode(this.state.markupMode);
      this.props.setYearlyMarkups(this.state.yearlyMarkups);
      this.props.setMarkup(this.state.markup);
    });
  };

  updateMarkup = (value) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          markup: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
        };
      },
      () => {
        this.props.setMarkup(this.state.markup).then(() => {});
      }
    );
  };

  updateYearlyMargin = (year, value) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          yearlyMarkups: prevState.yearlyMarkups.map((m) => {
            if (m.year === year) {
              return {
                ...m,
                markup: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
              };
            } else {
              return m;
            }
          }),
        };
      },
      () => {
        this.props.setYearlyMarkups(this.state.yearlyMarkups).then(() => {
          let averageMarkup =
            _.sumBy(this.props.quotation.yearlyMarkups, function (m) {
              return parseFloat(m.markup);
            }) / parseInt(this.state.yearlyMarkups.length);
          //console.log("averageMarkup = ", averageMarkup);
          this.setState({ markup: averageMarkup }, () => {
            this.props.setMarkup(this.state.markup);
          });
        });
      }
    );
  };

  /**
   * volume
   */
  updateVolume = (e) => {
    this.setState({ volumeMWh: !_.isNaN(e.target.value) ? parseInt(e.target.value) : 0 }, () => {
      this.props.setVolume(this.state.volumeMWh);
    });
  };

  render() {
    let currentYear = new Date().getFullYear();

    let increaseStartYearDisable = this.state.startYear + 1 <= this.state.endYear ? false : true;
    let decreaseStartYearDisable = this.state.startYear - 1 > currentYear ? false : true;

    let increaseEndYearDisable = this.state.endYear + 1 <= currentYear + this.state.maxduration ? false : true;
    let decreaseEndYearDisable = this.state.endYear - 1 >= this.state.startYear ? false : true;

    let validityDurationAddDisabled = true;
    if (parseInt(this.state.validityDuration) <= 7) {
      validityDurationAddDisabled = false;
    }

    let validityDurationRemoveDisabled = false;
    if (parseInt(this.state.validityDuration) === 0) {
      validityDurationRemoveDisabled = true;
    }

    return (
      <Fragment>
        {/* Durration */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={3}>
            <p className="inputlabel bold" style={{ marginLeft: "2rem" }}>
              Année(s) de cotation
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
              <Grid item xs={2}>
                <p className="inputlabel" style={{ textAlign: "right" }}>
                  Début
                </p>
              </Grid>
              <Grid item xs={4}>
                <Paper
                  component="form"
                  style={{
                    padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                  elevation={0}
                  variant="outlined"
                >
                  <InputBase
                    style={{
                      marginLeft: "0.5rem",
                      flex: "1",
                      fontSize: "0.8rem",
                    }}
                    value={this.state.startYear}
                  />
                  <Divider style={{ height: "28", margin: "4" }} orientation="vertical" />
                  <IconButton aria-label="start-add" style={{ padding: "10" }} size="small" disabled={increaseStartYearDisable} onClick={this.increaseStart}>
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                  <Divider style={{ height: "28", margin: "4" }} orientation="vertical" size="small" />
                  <IconButton aria-label="atart-remove" style={{ padding: "10" }} size="small" disabled={decreaseStartYearDisable} onClick={this.decreaseStart}>
                    <RemoveIcon fontSize="inherit" />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <p className="inputlabel" style={{ textAlign: "right" }}>
                  Fin
                </p>
              </Grid>
              <Grid item xs={4}>
                <Paper
                  component="form"
                  style={{
                    padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                  elevation={0}
                  variant="outlined"
                >
                  <InputBase
                    style={{
                      marginLeft: "0.5rem",
                      flex: "1",
                      fontSize: "0.8rem",
                    }}
                    value={this.state.endYear}
                  />
                  <Divider style={{ height: "28", margin: "4" }} orientation="vertical" />
                  <IconButton aria-label="start-add" style={{ padding: "10" }} size="small" disabled={increaseEndYearDisable} onClick={this.increaseEnd}>
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                  <Divider style={{ height: "28", margin: "4" }} orientation="vertical" />
                  <IconButton aria-label="atart-remove" style={{ padding: "10" }} size="small" disabled={decreaseEndYearDisable} onClick={this.decreaseEnd}>
                    <RemoveIcon fontSize="inherit" />
                  </IconButton>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Grid>
                <p className="title2">{this.state.endYear - this.state.startYear + 1} année(s)</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Base price */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <p className="inputlabel bold" style={{ marginLeft: "2rem" }}>
              Prix ruban CH
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
              {this.state.energyPricesTableExpanded ? (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleEnergyPricesTableExpanded}>
                      <VisibilityOffIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Masquer les détails concernant les prix de l'énergie et les taux de changes &euro;/CHF</p>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleEnergyPricesTableExpanded}>
                      <VisibilityIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Paramétrer les prix de l'énergie et les taux de changes &euro;/CHF</p>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <QuotationEnergyPricesTable
              energyPricesTableExpanded={this.state.energyPricesTableExpanded}
              updateCustomizedEnergyPrice={this.updateCustomizedEnergyPrice}
            />
          </Grid>
          <Grid item xs={3}>
            <p className="title2" style={{ textAlign: "right", color: "rgba(0, 91, 151, 1)" }}>
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.energyPrice}
                prefix=""
                suffix=" ct CHF/kWh"
                thousandSeparator="'"
              />
            </p>
          </Grid>
        </Grid>
        {/* GO */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <p className="inputlabel bold" style={{ marginLeft: "2rem" }}>
              Garantie d'origine
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
              {this.state.goPricesTableExpanded ? (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleGoTableExpanded}>
                      <VisibilityOffIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Masquer les détails concernant la garantie d'origine</p>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleGoTableExpanded}>
                      <VisibilityIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Paramétrer la garantie d'origine souhaitée</p>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <QuotationGoTable
              goPricesTableExpanded={this.state.goPricesTableExpanded}
              updateCustomizedGoPrice={this.updateCustomizedGoPrice}
              updateGoPrice={this.updateGoPrice}
              selectedGo={this.state.selectedGo}
            />
            {this.props.quotation && this.props.quotation.selectedGo === 0 && this.state.goPricesTableExpanded ? (
              <Fragment>
                <Grid container spacing={3} alignItems="center" style={{ marginTop: "1rem" }}>
                  <Grid item xs={6}>
                    <p className="inputlabel">Nom de la garantie d'origine :</p>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id={`custom-go-name`} fullWidth value={this.state.goCustomName} onChange={this.handleGoCustomNameChange} />
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
          <Grid item xs={3}>
            <p className="title2" style={{ textAlign: "right", color: "rgba(0, 91, 151, 1)" }}>
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.goPrice}
                prefix=""
                suffix=" ct CHF/kWh"
                thousandSeparator="'"
              />
            </p>
          </Grid>
        </Grid>
        {/* Adjustment costs */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <p className="inputlabel bold" style={{ marginLeft: "2rem" }}>
              Coût d'ajustement
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
              {this.state.adjustmentTableExpanded ? (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handeAdjustmentTableExpanded}>
                      <VisibilityOffIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Masquer les paramètres concernant les coûts de l'énergie d'ajustement</p>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handeAdjustmentTableExpanded}>
                      <VisibilityIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Paramétrer les coûts de l'énergie d'ajustement</p>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <QuotationAdjustmentCostsTable updateAdjustmentCost={this.updateAdjustmentCost} adjustmentTableExpanded={this.state.adjustmentTableExpanded} />
          </Grid>
          <Grid item xs={3}>
            <p className="title2" style={{ textAlign: "right", color: "rgba(0, 91, 151, 1)" }}>
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.adjustmentCost}
                prefix=""
                suffix=" ct CHF/kWh"
                thousandSeparator="'"
              />
            </p>
          </Grid>
        </Grid>
        {/* SPOT RISK */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <p className="inputlabel bold" style={{ marginLeft: "2rem" }}>
              Risque SPOT
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
              {this.state.spotTableExpanded ? (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleSpotTableExpanded}>
                      <VisibilityOffIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Masquer les détails concernant les coûts liés au risque SPOT</p>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleSpotTableExpanded}>
                      <VisibilityIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Paramétrer les coûts liés au risque SPOT</p>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <QuotationSpotRiskTable updateRiskCost={this.updateRiskCost} spotTableExpanded={this.state.spotTableExpanded} />
          </Grid>
          <Grid item xs={3}>
            <p className="title2" style={{ textAlign: "right", color: "rgba(0, 91, 151, 1)" }}>
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.riskCost}
                prefix=""
                suffix=" ct CHF/kWh"
                thousandSeparator="'"
              />
            </p>
          </Grid>
        </Grid>
        {/* Validity duration */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <p
              className="inputlabel bold"
              style={{
                marginLeft: "2rem",
              }}
            >
              Validité de l'offre
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
              {this.state.validityPanelExpanded ? (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleValidityPanelExpanded}>
                      <VisibilityOffIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Masquer les détails concernant la durée de validité</p>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleValidityPanelExpanded}>
                      <VisibilityIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Paramétrer la durée de validité</p>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <Collapse in={this.state.validityPanelExpanded} timeout="auto" unmountOnExit>
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xs={8}>
                  <p className="inputlabel">Choisissez le type de marge souhaitée</p>
                </Grid>
                <Grid item xs={4}>
                  <FormControl style={{ width: "100%" }}>
                    <Select id="markup-mode-selector" value={this.state.validityMode} onChange={this.handleValidityModeChange} style={{ fontSize: "0.8rem" }}>
                      <MenuItem value="day">Nombre de jours</MenuItem>
                      <MenuItem value="datetime">Date et heure spécifique</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {this.state.validityMode === "day" ? (
                <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" style={{ marginTop: "1rem" }}>
                  <Grid item>
                    <p className="inputlabel">Nombre de jours</p>
                  </Grid>
                  <Grid item>
                    <Paper
                      component="form"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                      elevation={0}
                      variant="outlined"
                    >
                      <InputBase
                        style={{
                          flex: "1",
                          width: "20px",
                          marginLeft: "12px",
                        }}
                        value={this.state.validityDuration}
                      />
                      <Divider style={{ height: "28", margin: "4" }} orientation="vertical" />
                      <IconButton
                        aria-label="start-add"
                        style={{ padding: "10" }}
                        size="small"
                        onClick={(e) => this.handleValidityChange("add")}
                        disabled={validityDurationAddDisabled}
                      >
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                      <Divider style={{ height: "28", margin: "4" }} orientation="vertical" size="small" />
                      <IconButton
                        aria-label="atart-remove"
                        style={{ padding: "10" }}
                        onClick={(e) => this.handleValidityChange("remove")}
                        disabled={validityDurationRemoveDisabled}
                      >
                        <RemoveIcon fontSize="inherit" />
                      </IconButton>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <p className="inputlabel" style={{ textAlign: "right" }}>
                      Prime:{" "}
                      <NumberFormat
                        displayType="text"
                        decimalScale={1}
                        fixedDecimalScale={true}
                        value={this.props.quotation.validityMarkup * 100}
                        prefix=""
                        suffix="%"
                        thousandSeparator="'"
                      />
                    </p>
                  </Grid>
                </Grid>
              ) : (
                <Fragment>
                  <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                    <Grid item>
                      <p className="inputlabel">Saisissez la date et l'heure de l'échéance</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} direction="row" justify="space-between" alignItems="center">
                    <Grid item xs={3}>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"fr"}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            format="DD/MM/YYYY"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date"
                            value={this.state.validityDate}
                            onChange={this.handleValidityDateSelectorChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            orientation="landscape"
                            cancelLabel="Annuler"
                            invalidDateMessage="Date non valide"
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"fr"}>
                        <Grid container justify="space-around">
                          <KeyboardTimePicker
                            format="HH:mm"
                            margin="normal"
                            id="time-picker"
                            label="Heure"
                            ampm={false}
                            value={this.state.validityTime}
                            onChange={this.handleValidityTimeSelectorChange}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                            cancelLabel="Annuler"
                            invalidDateMessage="Heure non valide"
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                      <p className="inputlabel" style={{ textAlign: "right" }}>
                        Prime:{" "}
                        <NumberFormat
                          displayType="text"
                          decimalScale={1}
                          fixedDecimalScale={true}
                          value={this.props.quotation.validityMarkup * 100}
                          prefix=""
                          suffix="%"
                          thousandSeparator="'"
                        />
                      </p>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Collapse>
          </Grid>
          <Grid item xs={3}>
            <p className="title2" style={{ textAlign: "right", color: "rgba(0, 91, 151, 1)" }}>
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.validityCost}
                prefix=""
                suffix=" ct CHF/kWh"
                thousandSeparator="'"
              />
            </p>
          </Grid>
        </Grid>
        {/* Markup */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={3}>
            <p
              className="inputlabel bold"
              style={{
                marginLeft: "2rem",
              }}
            >
              Marge commerciale brute
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
              {this.state.markupPanelExpanded ? (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleMarkupPanelExpanded}>
                      <VisibilityOffIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Masquer les détails concernant la marge commerciale</p>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item>
                    <IconButton size="small" onClick={this.handleMarkupPanelExpanded}>
                      <VisibilityIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid item style={{ paddingLeft: "0.5rem" }}>
                    <p className="text-small">Paramétrer la marge commerciale</p>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <Collapse in={this.state.markupPanelExpanded} timeout="auto" unmountOnExit>
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xs={8}>
                  <p className="inputlabel">Choisissez le type de marge souhaitée</p>
                </Grid>
                <Grid item xs={4}>
                  <FormControl style={{ width: "100%" }}>
                    <Select id="markup-mode-selector" value={this.state.markupMode} onChange={this.handleMarkupModeChange} style={{ fontSize: "0.8rem" }}>
                      <MenuItem value="global">Globale</MenuItem>
                      <MenuItem value="yearly">Par année</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {this.state.markupMode === "global" ? (
                <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                  <Grid item xs={10}>
                    <p className="inputlabel">Saisissez la marge souhaitée en ct CHF/kWh</p>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="markup-field"
                      value={this.state.markup}
                      fullWidth
                      onChange={(e) => this.updateMarkup(e.target.value)}
                      InputProps={{
                        inputComponent: CurrencyFormat,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Fragment>
                  <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                    <Grid item xs={8}>
                      <p className="inputlabel" style={{ lineHeight: "18px" }}>
                        Saisissez la marge souhaitée en ct CHF/kWh pour chacune des années de cotation
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <QuotationYearlyMarginTable updateYearlyMargin={this.updateYearlyMargin} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: "1rem" }}></Grid>
                </Fragment>
              )}
            </Collapse>
          </Grid>
          <Grid item xs={3}>
            <p className="title2" style={{ textAlign: "right", color: "rgba(0, 91, 151, 1)" }}>
              <NumberFormat
                displayType="text"
                decimalScale={3}
                fixedDecimalScale={true}
                value={this.props.quotation.markup}
                prefix={this.state.markupMode === "yearly" ? "Moyenne " : ""}
                suffix=" ct CHF/kWh"
                thousandSeparator="'"
              />
            </p>
          </Grid>
        </Grid>
        {/* Volume */}
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "2rem",
            paddingBottom: "1rem",
            borderBottom: "dashed 1px #DDDDDD",
          }}
        >
          <Grid item xs={3}>
            <p
              className="inputlabel bold"
              style={{
                marginLeft: "2rem",
              }}
            >
              Volume annuel
            </p>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <p className="inputlabel">Indiquez le volumne annuel en MWh</p>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="markup-field"
                  value={this.state.volumeMWh}
                  fullWidth
                  onChange={this.updateVolume}
                  InputProps={{
                    inputComponent: VolumeFormat,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
  prospects: state.prospects,
  pods: state.pods,
  consumers: state.consumers,
  settings: state.settings,
  prices: state.prices,
});

const mapDispatchToProps = (dispatch) => ({
  setStep2Loaded: (isLoaded) => dispatch(setStep2Loaded(isLoaded)),
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSelectedYears: (start, end, years) => dispatch(setSelectedYears(start, end, years)),
  setSelectedEnergyPrices: (energyPrices) => dispatch(setSelectedEnergyPrices(energyPrices)),
  setSelectedEnergyPrice: (eneryPrice) => dispatch(setSelectedEnergyPrice(eneryPrice)),
  setSelectedGo: (goId) => dispatch(setSelectedGo(goId)),
  setSelectedGoPrices: (goPrices) => dispatch(setSelectedGoPrices(goPrices)),
  setSelectedGoPrice: (goPrice) => dispatch(setSelectedGoPrice(goPrice)),
  setSelectedGoCustomName: (name) => dispatch(setSelectedGoCustomName(name)),
  setAdjustmentCosts: (costs) => dispatch(setAdjustmentCosts(costs)),
  setAdjustmentCost: (cost) => dispatch(setAdjustmentCost(cost)),
  setRiskCosts: (costs) => dispatch(setRiskCosts(costs)),
  setRiskCost: (cost) => dispatch(setRiskCost(cost)),
  setMarkupMode: (markupMode) => dispatch(setMarkupMode(markupMode)),
  setMarkup: (markup) => dispatch(setMarkup(markup)),
  setYearlyMarkups: (yearlyMarkups) => dispatch(setYearlyMarkups(yearlyMarkups)),
  setValidityMode: (validityMode) => dispatch(setValidityMode(validityMode)),
  setValidityDate: (date) => dispatch(setValidityDate(date)),
  setValidityTime: (time) => dispatch(setValidityTime(time)),
  setValidityDuration: (validityDuration) => dispatch(setValidityDuration(validityDuration)),
  setValidityMarkup: (validityMarkup) => dispatch(setValidityMarkup(validityMarkup)),
  setValidityCost: (validityCost) => dispatch(setValidityCost(validityCost)),
  setVolume: (volumeMWh) => dispatch(setVolume(volumeMWh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQuotationComponentStepTwo);
