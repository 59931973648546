/**
 * AppEASA
 *
 * @file auth.js
 * @version 1.0
 * @description auth reducer
 *
 */

// import redux action type
import { LOGIN_SUCCESS, LOGIN_FAIL, AUTH_ERROR, USER_LOADED, LOGOUT } from "../actions/types";

// set initial state
const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: localStorage.getItem("token") !== null ? true : false,
  loading: false,
};

// export reducer
export default function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        loading: false,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload);
      return {
        ...state,
        token: payload,
        isAuthenticated: true,
        loading: true,
      };

    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };

    //   case DISABLE_CHANGEPWD:
    //     return {
    //       ...state,
    //       user: {
    //         ...state.user,
    //         changepwd: false
    //       }
    //     };

    default:
      return state;
  }
}
