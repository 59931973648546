/**
 * AppEASA
 *
 * @file DisplaySettingsPanel.js
 * @version 1.0
 * @description display settings panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setSetting } from "../../../actions/settings";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class DisplaySettingsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayPricesMode: "quattro",
    };
  }

  componentDidMount() {
    let displayPricesModeSetting = this.props.settings.find((s) => s.key === "displayPricesMode");

    this.setState((prevState) => {
      return {
        displayPricesMode: displayPricesModeSetting && displayPricesModeSetting.value ? displayPricesModeSetting.value : prevState.displayPricesMode,
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.settings !== prevProps.settings) {
      this.setUpSettings();
    }
  }

  setUpSettings = () => {
    let displayPricesModeSetting = this.props.settings.find((s) => s.key === "displayPricesMode");

    this.setState((prevState) => {
      return {
        displayPricesMode: displayPricesModeSetting && displayPricesModeSetting.value ? displayPricesModeSetting.value : prevState.displayPricesMode,
      };
    });
  };

  handleDisplayPriceModeChange = (e) => {
    this.setState({ displayPricesMode: e.target.value }, () => this.props.setSetting("displayPricesMode", this.state.displayPricesMode));
  };

  render() {
    return (
      <Fragment>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="title2"></p>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={6}>
            <p className="inputlabel">Mode d'affichage des prix par défaut</p>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Select id="displayPricesMode" value={this.state.displayPricesMode} onChange={this.handleDisplayPriceModeChange}>
                <MenuItem value="uno">Uno</MenuItem>
                <MenuItem value="duo">Duo</MenuItem>
                <MenuItem value="quattro">Quattro</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.shareholderId)),
  user: state.user,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setSetting: (key, value) => dispatch(setSetting(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySettingsPanel);
