/**
 * AppEASA
 *
 * @file volatilities.js
 * @version 1.0
 * @description redux volatilities' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_VOLATILITIES } from "./types";

// import redux actions
import { setLoading } from "./ui";
import setAuthToken from "../utils/setAuthToken";

// load volatilities
export const beginLoadVolatilities = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/volatilities", config);

      if (res.status === 200) {
        dispatch(setVolatilities(res.data.volatilities));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les paramètres de volatilité."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadVolatilities = ", err);
      reject({ status: false });
    }
  });

// load current volatility
export const beginLoadCurrentVolatility = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/volatilities/current", config);

      if (res.status === 200) {
        dispatch(setVolatilities(res.data.volatility));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les paramètres de volatilité."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log(" beginLoadVolatilities = ", err);
      reject({ status: false });
    }
  });

// save new quotation
export const beginSaveVolatility = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch(setLoading(true));

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/volatilities", body, config)
        .then((res) => {
          dispatch(setVolatilities(res.data.volatilities));
          dispatch(setLoading(false));
          resolve({});
        })
        .catch((err) => {
          console.log("beginSaveVolatility err = ", err);
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log(" beginSaveVolatility = ", err);
      reject({ status: false });
    }
  });

// set volatilities
export const setVolatilities = (volatilities) => ({
  type: SET_VOLATILITIES,
  payload: volatilities,
});
