/**
 * AppEASA
 *
 * @file prices.js
 * @version 1.0
 * @description redux prices actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_PRICING } from "./types";

// load prices
export const beginLoadPrices = (shareholderId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/prices/" + shareholderId, config);

      if (res.status === 200) {
        dispatch(setPricing(res.data.prices));
        resolve({ status: res.status, prices: res.data.prices });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des prix"],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadPrices err = ", err);
      reject({ status: false });
    }
  });

// set prices
export const setPricing = (prices) => ({
  type: SET_PRICING,
  payload: prices,
});
