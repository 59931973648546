/**
 * @description Reset password form
 * display email field to request a new password
 */

// Base components
import React, { Component } from "react";

// Material UI Components
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Alert from "@material-ui/lab/Alert";

export default class ForgotPasswordForm extends Component {
  render() {
    // console.log(this.props);
    return (
      <Container style={{ padding: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <p className="text center">Veuillez saisir votre adresse email afin de réinitialiser votre mot de passe.</p>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <Input id="easa-email" size="small" value={this.props.resetPasswordEmail} onChange={this.props.updateResetPasswordEmail} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {this.props.resetPasswordErrorMsg !== "" ? <Alert severity="error">{this.props.resetPasswordErrorMsg}</Alert> : null}
            {this.props.successMsg !== "" ? <Alert severity="success">{this.props.successMsg}</Alert> : null}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              className="login-button"
              onClick={this.props.resetPassword}
              disabled={this.props.successMsg !== ""}
            >
              Nouveau mot de passe
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Button color="primary" size="small" className="login-button" onClick={this.props.unsetResetPassword}>
              {this.props.successMsg !== "" ? "Retour" : "Annuler"}
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
