/**
 * AppEASA
 *
 * @file quotation.js
 * @version 1.0
 * @description redux new quotation actions
 *
 */

// loading axios
import axios from "axios";
import _ from "lodash";

// import redux actions
import { setLoading } from "./ui";
import setAuthToken from "../utils/setAuthToken";
import { setPricing } from "./prices";
import { updateQuotation } from "./quotations";

// import redux action type
import {
  QUOTATION_SET_SHAREHOLDER,
  QUOTATION_SET_STEP2LOADED,
  QUOTATION_SET_REFERENCE,
  QUOTATION_SET_PROSPECT,
  QUOTATION_SET_PROSPECT_NUMBER,
  QUOTATION_SET_PROSPECT_NAME,
  QUOTATION_SET_PROSPECT_ADDRESS1,
  QUOTATION_SET_PROSPECT_ADDRESS2,
  QUOTATION_SET_PROSPECT_ZIPCODE,
  QUOTATION_SET_PROSPECT_LOCATION,
  QUOTATION_SET_POD,
  QUOTATION_SET_PRICETYPE,
  QUOTATION_SET_MARKET,
  QUOTATION_SET_FCCQUATRO,
  QUOTATION_RESET,
  QUOTATION_SET_YEARS,
  QUOTATION_SET_MARKETENERGYPRICES,
  QUOTATION_SET_MARKETENERGYPRICESDATE,
  QUOTATION_SET_MARKETENERGYPRICESTIME,
  QUOTATION_SET_MARKETENERGYPRICESTYPE,
  QUOTATION_SET_ENERGYPRICES,
  QUOTATION_SET_ENERGYPRICE,
  QUOTATION_SET_SELECTEDGO,
  QUOTATION_SET_MARKETGOPRICES,
  QUOTATION_SET_MARKETGOPRICESDATE,
  QUOTATION_SET_MARKETGOPRICESTIME,
  QUOTATION_SET_GOPRICES,
  QUOTATION_SET_GOPRICE,
  QUOTATION_SET_GOCUSTOMNAME,
  QUOTATION_SET_MARKETADJUSTMENTCOSTS,
  QUOTATION_SET_ADJUSTMENTCOSTS,
  QUOTATION_SET_ADJUSTMENTCOST,
  QUOTATION_SET_MARKETRISKCOSTS,
  QUOTATION_SET_RISKCOSTS,
  QUOTATION_SET_RISKCOST,
  QUOTATION_SET_MARKUPMODE,
  QUOTATION_SET_MARKUP,
  QUOTATION_SET_YEARLYMARKUPS,
  QUOTATION_SET_VALIDITYMODE,
  QUOTATION_SET_VALIDITYDATE,
  QUOTATION_SET_VALIDITYTIME,
  QUOTATION_SET_VALIDITYDURATION,
  QUOTATION_SET_VALIDITYMARKUP,
  QUOTATION_SET_VALIDITYCOST,
  QUOTATION_SET_VOLUME,
  QUOTATION_SET_STATUS,
  QUOTATION_SET_COMBINATIONS,
} from "./types";

/**
 * Get load curve factors and market structure
 * @param {*} body
 */
export const getLCF = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/getlcf", body, config)
        .then((res) => {
          dispatch(setQuatoationMarketStructure(res.data.marketStructure));
          dispatch(setSelectedFCCQuatro(res.data.lcf));

          resolve({});
        })
        .catch((err) => {
          console.log("getLCF err = ", err);
          reject({ status: false });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log(" getLCF = ", err);
      reject({ status: false });
    }
  });

/**
 * Get load curve factors and market structure
 * @param {*} body
 */
export const getPrices = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/getprices", body, config)
        .then((res) => {
          dispatch(setQuatoationMarketStructure(res.data.marketStructure));
          dispatch(setSelectedFCCQuatro(res.data.lcf));
          resolve({});
        })
        .catch((err) => {
          console.log("getLCF err = ", err);
          reject({ status: false });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log(" getLCF = ", err);
      reject({ status: false });
    }
  });

// save new quotation
export const beginSaveQuotation = (quotation) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    let body = {
      ...quotation,
      marketStructure: JSON.stringify(quotation.marketStructure),
      fccQuattro: JSON.stringify(quotation.fccQuattro),
      years: JSON.stringify(quotation.years),
      marketEnergyPrices: JSON.stringify(quotation.marketEnergyPrices),
      energyPrices: JSON.stringify(quotation.energyPrices),
      marketGoPrices: JSON.stringify(quotation.marketGoPrices),
      goPrices: JSON.stringify(quotation.goPrices),
      marketAdjustmentCosts: JSON.stringify(quotation.marketAdjustmentCosts),
      adjustmentCosts: JSON.stringify(quotation.adjustmentCosts),
      marketRiskCosts: JSON.stringify(quotation.marketRiskCosts),
      riskCosts: JSON.stringify(quotation.riskCosts),
      yearlyMarkups: JSON.stringify(quotation.yearlyMarkups),
    };

    try {
      axios
        .post("/api/quotation", body, config)
        .then((res) => {
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginSaveQuotation err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log(" beginLoadSettingserr = ", err);
      reject({ status: false });
    }
  });

// get quattro prices
export const beginGetQuattroPrices = (quotation, selectedGo, years) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    // manage go prices (when changed in new quotation step 3)
    // console.log("ACTION quotation = ", quotation);
    // console.log("ACTION years = ", years);
    // console.log("ACTION quotation.marketGoPrices = ", quotation.marketGoPrices);
    // let selectedGoPrices = quotation.marketGoPrices.find((go) => go.id === parseInt(selectedGo));
    // console.log("ACTION selectedGoPrices = ", selectedGoPrices);
    // let goPrice = 0;
    // for (let index = 0; index < years.length; index++) {
    //   const currentYear = years[index];
    //   let goYearlyPrice = selectedGoPrices && selectedGoPrices.prices.find((p) => p.year === currentYear);
    //   goPrice += goYearlyPrice && parseFloat(goYearlyPrice.price) ? parseFloat(goYearlyPrice.price) : 0;
    // }
    // goPrice = goPrice / years.length / 10;
    // console.log("ACTION goPrice = ", goPrice);
    let body = {
      // goPrice,
      goPrices: JSON.stringify(quotation.goPrices),
      adjustmentCost: quotation.adjustmentCost,
      riskCost: quotation.riskCost,
      validityCost: quotation.validityCost,
      markupMode: quotation.markupMode,
      markup: quotation.markup,
      yearlyMarkups: JSON.stringify(quotation.yearlyMarkups),
      energyPrice: quotation.energyPrice,
      energyPrices: JSON.stringify(quotation.energyPrices),
      fccQuattro: JSON.stringify(quotation.fccQuattro),
      years: JSON.stringify(years),
    };
    //console.log("ACTION body = ", body);
    try {
      axios
        .post("/api/quotation/getquattroprices", body, config)
        .then((res) => {
          // console.log("res", res);
          resolve(res);
        })
        .catch((err) => {
          //console.log("beginSaveQuotation err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log(" beginLoadSettingserr = ", err);
      reject({ status: false });
    }
  });

// Validate a quotation
export const beginValidateQuotation = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/validate", body, config)
        .then((res) => {
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginValidateQuotation err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginValidateQuotation = ", err);
      reject({ status: false });
    }
  });

// UnValidate a quotation
export const beginUnValidateQuotation = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/unvalidate", body, config)
        .then((res) => {
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginUnValidateQuotation err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginUnValidateQuotation = ", err);
      reject({ status: false });
    }
  });

// save new combination
export const beginSaveCombination = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/combination", body, config)
        .then((res) => {
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginSaveCombination err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginSaveCombination = ", err);
      reject({ status: false });
    }
  });

// delete combination
export const beginDeleteCombination = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/combination/delete", body, config)
        .then((res) => {
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginDeleteCombination err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginDeleteCombination = ", err);
      reject({ status: false });
    }
  });

// Swap go
export const beginSwapGo = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/swapgo", body, config)
        .then((res) => {
          dispatch(updateQuotation(res.data.quotation));
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginSwapGo err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginSwapGo = ", err);
      reject({ status: false });
    }
  });

// Update markup
export const beginUpdateMarkup = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/updatemarkup", body, config)
        .then((res) => {
          dispatch(updateQuotation(res.data.quotation));
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginSwapGo err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginSwapGo = ", err);
      reject({ status: false });
    }
  });

// Update volume
export const beginUpdateVolume = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .post("/api/quotation/updatevolume", body, config)
        .then((res) => {
          dispatch(updateQuotation(res.data.quotation));
          resolve({ res });
        })
        .catch((err) => {
          //console.log("beginSwapGo err = ", err);
          reject({ status: false, err });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log("beginSwapGo = ", err);
      reject({ status: false });
    }
  });

/**
 * Delete quotation
 * @param {*} id
 */
export const beginDeleteQuotation = (id) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      axios
        .delete("/api/quotation/" + id, config)
        .then((res) => {
          resolve({ status: true });
        })
        .catch((err) => {
          console.log("beginDeleteQuotation err = ", err);
          reject({ status: false });
        });
    } catch (err) {
      dispatch(setLoading(false));
      console.log(" beginDeleteQuotation = ", err);
      reject({ status: false });
    }
  });

// reset new quotation
export const resetNewQuotation = () => (dispatch) => {
  dispatch({ type: QUOTATION_RESET });
  dispatch(setPricing([]));
};

// set shareholder
export const setShareholder = (shareholderId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_SHAREHOLDER,
      payload: shareholderId,
    });
    resolve({});
  });

// set reference
export const setReference = (ref) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_REFERENCE,
      payload: ref,
    });
    resolve({});
  });

// set whether step 2 has been loaded
export const setStep2Loaded = (isLoaded) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_STEP2LOADED,
      payload: isLoaded,
    });
    resolve({});
  });

// set selected prospect
export const setSelectedProspect = (prospectId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_PROSPECT,
      payload: prospectId,
    });
    resolve({});
  });

// set new prospect number
export const setNewProspectNumber = (number) => ({
  type: QUOTATION_SET_PROSPECT_NUMBER,
  payload: number,
});

// set new prospect name
export const setNewProspectName = (name) => ({
  type: QUOTATION_SET_PROSPECT_NAME,
  payload: name,
});

// set new prospect address1
export const setNewProspectAddress1 = (address1) => ({
  type: QUOTATION_SET_PROSPECT_ADDRESS1,
  payload: address1,
});

// set new prospect address2
export const setNewProspectAddress2 = (address2) => ({
  type: QUOTATION_SET_PROSPECT_ADDRESS2,
  payload: address2,
});

// set new prospect zipcode
export const setNewProspectZipcode = (zipcode) => ({
  type: QUOTATION_SET_PROSPECT_ZIPCODE,
  payload: zipcode,
});

// set new prospect location
export const setNewProspectLocation = (location) => ({
  type: QUOTATION_SET_PROSPECT_LOCATION,
  payload: location,
});

// set selected pod
export const setSelectedPod = (podId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_POD,
      payload: podId,
    });
    resolve({});
  });

// set selected price type
export const setSelectedPriceType = (priceTypeId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_PRICETYPE,
      payload: priceTypeId,
    });
    resolve({});
  });

// set market structude
export const setQuatoationMarketStructure = (data) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_MARKET,
      payload: data,
    });
    resolve({});
  });

// set selected price type
export const setSelectedFCCQuatro = (fcc) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_FCCQUATRO,
      payload: fcc,
    });
    resolve({});
  });

// set years
export const setSelectedYears = (startYear, endYear, years) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_YEARS,
      startYear,
      endYear,
      years,
    });
    resolve({});
  });

/**
 * Energy
 */
// set market energy prices
export const setSelectedMarketEnergyPrices = (marketEnergyPrices) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_MARKETENERGYPRICES,
      payload: marketEnergyPrices,
    });
    resolve({});
  });

// set market energy prices date
export const setMarketEnergyPricesDate = (date) => ({
  type: QUOTATION_SET_MARKETENERGYPRICESDATE,
  payload: date,
});

// set market energy prices time
export const setMarketEnergyPricesTime = (time) => ({
  type: QUOTATION_SET_MARKETENERGYPRICESTIME,
  payload: time,
});

// set market energy prices type
export const setMarketEnergyPricesType = (type) => ({
  type: QUOTATION_SET_MARKETENERGYPRICESTYPE,
  payload: type,
});

// set energy prices
export const setSelectedEnergyPrices = (energyPrices) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_ENERGYPRICES,
      payload: energyPrices,
    });
    resolve({});
  });

// set energy price
export const setSelectedEnergyPrice = (energyPrice) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_ENERGYPRICE,
      payload: energyPrice,
    });
    resolve({});
  });

/**
 * GO
 */

// set market GO prices
export const setSelectedMarketGoPrices = (marketGoPrices) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_MARKETGOPRICES,
      payload: marketGoPrices,
    });
    resolve({});
  });

// set market go prices date
export const setMarketGoPricesDate = (date) => ({
  type: QUOTATION_SET_MARKETGOPRICESDATE,
  payload: date,
});

// set market go prices time
export const setMarketGoPricesTime = (time) => ({
  type: QUOTATION_SET_MARKETGOPRICESTIME,
  payload: time,
});

// set selected go
export const setSelectedGo = (goId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_SELECTEDGO,
      payload: goId,
    });
    resolve({});
  });

// set go custom name
export const setSelectedGoCustomName = (name) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_GOCUSTOMNAME,
      payload: name,
    });
    resolve({});
  });

// set go prices
export const setSelectedGoPrices = (goPrices) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_GOPRICES,
      payload: goPrices,
    });
    resolve({});
  });

// set go price
export const setSelectedGoPrice = (goPrice) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_GOPRICE,
      payload: goPrice,
    });
    resolve({});
  });

/**
 * Adjustment
 */
// set market adjustment costs
export const setMarketAdjustmentCosts = (marketAdjustmentCosts) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_MARKETADJUSTMENTCOSTS,
      payload: marketAdjustmentCosts,
    });
    resolve({});
  });

// set adjustment costs
export const setAdjustmentCosts = (adjustmentCosts) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_ADJUSTMENTCOSTS,
      payload: adjustmentCosts,
    });
    resolve({});
  });

// set adjustment cost
export const setAdjustmentCost = (adjustmentCost) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_ADJUSTMENTCOST,
      payload: adjustmentCost,
    });
    resolve({});
  });

/**
 * Risk
 */
// set market risk costs
export const setMarketRiskCosts = (marketRiskCosts) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_MARKETRISKCOSTS,
      payload: marketRiskCosts,
    });
    resolve({});
  });

// set risk costs
export const setRiskCosts = (riskCosts) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_RISKCOSTS,
      payload: riskCosts,
    });
    resolve({});
  });

// set risk cost
export const setRiskCost = (riskCost) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_RISKCOST,
      payload: riskCost,
    });
    resolve({});
  });

/**
 * Markup
 */

export const setMarkupMode = (markupMode) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_MARKUPMODE,
      payload: markupMode,
    });
    resolve({});
  });

export const setMarkup = (markup) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_MARKUP,
      payload: markup,
    });
    resolve({});
  });

export const setYearlyMarkups = (yearlyMarkups) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_YEARLYMARKUPS,
      payload: yearlyMarkups,
    });
    resolve({});
  });

/**
 * validity
 */

export const setValidityMode = (validityMode) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_VALIDITYMODE,
      payload: validityMode,
    });
    resolve({});
  });

export const setValidityDate = (validityDate) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_VALIDITYDATE,
      payload: validityDate,
    });
    resolve({});
  });

export const setValidityTime = (validityTime) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_VALIDITYTIME,
      payload: validityTime,
    });
    resolve({});
  });

export const setValidityDuration = (validityDuration) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_VALIDITYDURATION,
      payload: validityDuration,
    });
    resolve({});
  });

export const setValidityMarkup = (validityMarkup) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_VALIDITYMARKUP,
      payload: validityMarkup,
    });
    resolve({});
  });

export const setValidityCost = (validityCost) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_VALIDITYCOST,
      payload: validityCost,
    });
    resolve({});
  });

export const setVolume = (volumeMWh) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_VOLUME,
      payload: volumeMWh,
    });
    resolve({});
  });

// set combinations
export const setCombinations = (combinations) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_COMBINATIONS,
      payload: combinations,
    });
    resolve({});
  });

// set status
export const setStatus = (status) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    dispatch({
      type: QUOTATION_SET_STATUS,
      payload: status,
    });
    resolve({});
  });
