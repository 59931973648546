/**
 * AppEASA
 *
 * @file user.js
 * @version 1.0
 * @description redux user's actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_USER, USER_LOADED } from "./types";
import setAuthToken from "../utils/setAuthToken";

// import auth redux action
import { logout } from "./auth";
import { setShareholders } from "./shareholders";

// load user data including permissions
export const beginLoadUser = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    // as we are unsing async function, ensure that the token is sent in the header
    const config = {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": localStorage.token,
      },
    };

    try {
      const res = await axios.get("/api/user", config);
      DEBUG && console.log("GET /api/user res = ", res.data);

      if (res.status === 200) {
        dispatch(setUser(res.data.user));

        // check if user is shareholder
        if (res.data.user.role === "shareholder") {
          dispatch(setShareholders(res.data.user.shareholder));
        }
        dispatch(userLoaded());
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations utilisateur"],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("petit prob...");
      console.log("err =", err);
      dispatch(logout());
      //localStorage.removeItem("token");
      //const errors = err.response.data.errors;
      //reject({ loginStatus: false, msg: errors[0].msg });
    }
  });

// set user
export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

// user is loaded (auth reducer)
export const userLoaded = () => (dispatch) => {
  dispatch({
    type: USER_LOADED,
  });
};

// update user password
export const beginUpdateUserPassword = (username, password, confirmPassword) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const body = {
      email: username,
      password,
      confirmPassword,
    };

    DEBUG && console.log("beginUpdateUserPassword body = ", body);

    try {
      const res = await axios.post("/api/user", body, config);
      DEBUG && console.log("/api/user/ res = ", res);
      dispatch(beginLoadUser()).then(() => {
        resolve(res.response);
      });
    } catch (err) {
      reject(err.response);
    }
  });
