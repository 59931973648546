/**
 * AppEASA
 *
 * @file consumers.js
 * @version 1.0
 * @description redux consumers' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_CONSUMERS } from "./types";

// load customer types
export const beginLoadConsumers = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/consumers", config);
      DEBUG && console.log("GET /api/consumers res = ", res.data);

      if (res.status === 200) {
        dispatch(setConsumers(res.data.consumers));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: [
                "Impossible de charger les informations des clients type",
              ],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadConsumers err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// set consumers
export const setConsumers = (consumers) => ({
  type: SET_CONSUMERS,
  payload: consumers,
});
