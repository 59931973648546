/**
 * AppEASA
 *
 * @file pods.js
 * @version 1.0
 * @description redux pods' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_PODS } from "./types";

// load pods belonging to a prospect
export const beginLoadPods = (prospectId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;
    DEBUG && console.log("beginLoadPods, prospectId=", prospectId);
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/prospects/pods/" + prospectId, config);
      DEBUG && console.log("GET /api/prospects/pods/:prospectId res = ", res.data);

      if (res.status === 200) {
        dispatch(setPPods(res.data.pods));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des PODs"],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      const errors = err.response.data.errors;
      reject({ loginStatus: false, msg: errors });
    }
  });

// set pods
export const setPPods = (pods) => ({
  type: SET_PODS,
  payload: pods,
});
