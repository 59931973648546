/**
 * AppEASA
 *
 * @file Can.js
 * @version 1.0
 * @description grant access according to user's permissions
 *
 */

// load redux functions
import { connect } from "react-redux";

// load lodash
import _ from "lodash";

// set DEBUG mode
const DEBUG = false;

const check = (permissions, action, data) => {
  const requestedAction = action.split(":");
  if (!requestedAction || requestedAction.length !== 2) {
    return false;
  }
  DEBUG && console.log("permissions = ", permissions);
  DEBUG && console.log("action = ", action);
  DEBUG && console.log("requestedAction = ", requestedAction);

  // check whether or not the user is authorized to access the ressource and perform the action
  const hasAccess = _.some(permissions, { ressource: requestedAction[0], action: requestedAction[1] });
  DEBUG && console.log("hasAccess = ", hasAccess);

  if (hasAccess) {
    return true;
  }
  return false;
};

const Can = (props) => (check(props.permissions, props.action, props.data) ? props.yes() : props.no());

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Can);
