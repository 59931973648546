/**
 * AppEASA
 *
 * @file QuotationSelector.js
 * @version 1.0
 * @description filters component for quotations list
 *
 */

// Get visible quotation
const quotationFilterFunction = (quotations, { text = "", prospects = "all", status = "all" }) => {
  return quotations.filter((quotation) => {
    // console.log(quotation);

    const textMatch = text === "" ? true : quotation.reference.includes(text) ? true : false;

    const prospectMatch = prospects === "all" ? true : quotation.prospectId === prospects ? true : false;

    const statusMatch = status === "all" ? true : quotation.status === status ? true : false;

    return textMatch && prospectMatch && statusMatch;
  });
};

export default quotationFilterFunction;
