/**
 * AppEASA
 *
 * @file QuotationAdjustmentCostsTable.js
 * @version 1.0
 * @description Adjustment prices table
 *
 */

// Base component
import React, { Component, Fragment } from "react";

// load redux and functions
import { connect } from "react-redux";

// Other libs/components
import NumberFormat from "react-number-format";

// Material UI Components
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //   prefix="$"
      mask="_"
      //   format="9#.####"
      fixedDecimalScale={true}
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "center", fontSize: "0.8rem" }}
    />
  );
}

class QuotationAdjustmentCostsTable extends Component {
  adjustmentPriceTableRow = (year, index) => {
    let yearlPrice = this.props.quotation.adjustmentCosts.find((c) => c.year === year);

    let cost = parseFloat(yearlPrice.cost) * parseFloat(yearlPrice.exchangeRate);

    cost = Number(Math.round(cost + "e" + 2) + "e-" + 2);

    let duration = this.props.quotation.years.length;
    return (
      <TableRow key={`adjustmentCostRow_${year}`}>
        <TableCell>
          <p className="text-small bold">{yearlPrice && yearlPrice.year}</p>
        </TableCell>
        <TableCell align="center">
          <p className="text-small">
            <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={yearlPrice && yearlPrice.cost} prefix="" thousandSeparator="'" />
          </p>
        </TableCell>
        <TableCell align="center">
          <p className="text-small">
            <NumberFormat
              displayType="text"
              decimalScale={3}
              fixedDecimalScale={true}
              value={yearlPrice && yearlPrice.exchangeRate}
              prefix=""
              thousandSeparator="'"
            />
          </p>
        </TableCell>
        <TableCell align="center">
          <p className="text-small">
            <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} value={cost} prefix="" thousandSeparator="'" />
          </p>
        </TableCell>
        <TableCell align="center" style={{ borderBottom: index !== duration - 1 ? "none" : null }}></TableCell>
      </TableRow>
    );
  };

  render() {
    return (
      <Fragment>
        <Collapse in={this.props.adjustmentTableExpanded} timeout="auto" unmountOnExit>
          <Table size="small" style={{ marginTop: "1rem" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="text-small">Année</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small bold">Coût &euro;/MWh</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small bold">Taux &euro;/CHF</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-small bold">Coût CHF/MWh</p>
                </TableCell>
                <TableCell align="center" style={{ borderBottom: "none" }}>
                  {/* <p className="text-small bold">Coût moyen CHF/MWh</p> */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.quotation.years.map((year, index) => this.adjustmentPriceTableRow(year, index))}
              <TableRow>
                <TableCell colSpan={4}>
                  <p className="text-small bold right">Coût moyen d'ajustement en CHF/MWh</p>
                </TableCell>

                <TableCell align="center" style={{ backgroundColor: "rgba(17, 170, 0, 0.1)" }}>
                  <TextField
                    id={`adjustment-cost`}
                    value={this.props.quotation.adjustmentCost * 10}
                    onChange={(e) => this.props.updateAdjustmentCost(e.target.value)}
                    style={{ width: "60px" }}
                    InputProps={{
                      inputComponent: CurrencyFormat,
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotation: state.quotation,
  settings: state.settings,
  prices: state.prices,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationAdjustmentCostsTable);
