/**
 * AppEASA
 *
 * @file settings.js
 * @version 1.0
 * @description settings reducer
 *
 */

// import redux action type
import { SET_SETTINGS, SET_SETTING } from "../actions/types";

// set initial state
const initialState = [];

// export reducer
export default function settingsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SETTINGS:
      return payload;

    case SET_SETTING:
      return [
        ...state.map((setting) => {
          if (setting.key === action.key) {
            return {
              ...setting,
              value: action.value,
            };
          } else {
            return setting;
          }
        }),
      ];

    default:
      return state;
  }
}
