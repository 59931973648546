/**
 * AppEASA
 *
 * @file QuotationSettingsPanel.js
 * @version 1.0
 * @description quotation settings panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setSetting } from "../../../actions/settings";

// load other libs
import _ from "lodash";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class QuotationSettingsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultMarkupMode: "global",
      defaultMarkup: 0,
      yearlyMarkups: [],
      defaultValidityMode: "day",
      defaultDuration: 0,
      emailTo: "",
      emailCc: "",
    };
  }

  componentDidMount() {
    this.setUpSettings();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.settings !== prevProps.settings) {
  //     //this.setUpSettings();
  //   }
  // }

  setUpSettings = () => {
    // markup setting
    let defaultMarkupModeSetting = this.props.settings.find((s) => s.key === "defaultMarkupMode");
    let defaultMarkupSetting = this.props.settings.find((s) => s.key === "defaultMarkup");
    let yearlyMarkupsSetting = this.props.settings.find((s) => s.key === "yearlyMarkups");
    let currentYearlyMarkups = yearlyMarkupsSetting ? JSON.parse(yearlyMarkupsSetting.value) : [];

    let maxDuration = this.props.config && this.props.config.maxDuration;
    let startYear = parseInt(new Date().getFullYear());
    const endYear = startYear + maxDuration;
    let yearlyMarkups = [];
    for (let index = startYear; index <= endYear; index++) {
      let currentMarkupSetting = currentYearlyMarkups && currentYearlyMarkups.find((yearlyMarkup) => yearlyMarkup.year === index);
      yearlyMarkups.push({
        year: index,
        markup: currentMarkupSetting && currentMarkupSetting.markup ? parseFloat(currentMarkupSetting.markup) : 0,
      });
    }

    // validity setting
    let defaultValidityModeSetting = this.props.settings.find((s) => s.key === "defaultValidityMode");
    let defaultDurationSetting = this.props.settings.find((s) => s.key === "defaultDuration");
    let emailToSetting = this.props.settings.find((s) => s.key === "emailTo");
    let emailCcSetting = this.props.settings.find((s) => s.key === "emailCc");

    // set state
    this.setState((prevState) => {
      return {
        defaultMarkupMode: defaultMarkupModeSetting && defaultMarkupModeSetting.value ? defaultMarkupModeSetting.value : prevState.defaultMarkupMode,
        defaultMarkup: defaultMarkupSetting && defaultMarkupSetting.value ? defaultMarkupSetting.value : prevState.defaultMarkup,
        yearlyMarkups,
        defaultValidityMode: defaultValidityModeSetting && defaultValidityModeSetting.value ? defaultValidityModeSetting.value : prevState.defaultValidityMode,
        defaultDuration: defaultDurationSetting && defaultDurationSetting.value ? defaultDurationSetting.value : prevState.defaultDuration,
        emailTo: emailToSetting && emailToSetting.value ? emailToSetting.value : prevState.emailTo,
        emailCc: emailCcSetting && emailCcSetting.value ? emailCcSetting.value : prevState.emailCc,
      };
    });
  };

  handleUpdateDefaultMarkupMode = (e) => {
    let maxDuration = this.props.config && this.props.config.maxDuration;
    let startYear = parseInt(new Date().getFullYear());
    const endYear = startYear + maxDuration;
    let yearlyMarkups = [];
    for (let index = startYear; index <= endYear; index++) {
      yearlyMarkups.push({
        year: index,
        markup: 0,
      });
    }

    this.setState({ defaultMarkupMode: e.target.value, yearlyMarkups, defaultMarkup: 0 }, () => {
      this.props.setSetting("defaultMarkupMode", this.state.defaultMarkupMode);
    });
  };

  handleUpdateYearlyMarkup = (year, value) => {
    console.log("value = ", value);
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          yearlyMarkups: prevState.yearlyMarkups.map((yearlyMarkup) => {
            if (yearlyMarkup.year === year) {
              return {
                ...yearlyMarkup,
                markup: !_.isNaN(parseFloat(value)) ? value : 0,
              };
            } else {
              return yearlyMarkup;
            }
          }),
        };
      },
      () => {
        //console.log("state = ", this.state);
        this.props.setSetting("yearlyMarkups", JSON.stringify(this.state.yearlyMarkups));
      }
    );
  };

  handleUpdateDefaultMarkup = (e) => {
    this.setState({ defaultMarkup: !_.isNaN(parseFloat(e.target.value)) ? e.target.value : 0 }, () =>
      this.props.setSetting("defaultMarkup", parseFloat(this.state.defaultMarkup))
    );
  };

  handleUpdateDefaultValidityMode = (e) => {
    this.setState({ defaultValidityMode: e.target.value }, () => this.props.setSetting("defaultValidityMode", this.state.defaultValidityMode));
  };

  handleUpdateDefaultDuration = (e) => {
    this.setState({ defaultDuration: !_.isNaN(parseInt(e.target.value)) ? e.target.value : 0 }, () =>
      this.props.setSetting("defaultDuration", this.state.defaultDuration)
    );
  };

  handleUpdateMailTo = (e) => {
    this.setState({ emailTo: e.target.value }, () => this.props.setSetting("emailTo", this.state.emailTo));
  };

  handleUpdateMailCc = (e) => {
    this.setState({ emailCc: e.target.value }, () => this.props.setSetting("emailCc", this.state.emailCc));
  };

  render() {
    // console.log("state = ", this.state);
    // console.log("this.props.settings = ", this.props.settings);
    return (
      <Fragment>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="title2">Marge commerciale brute</p>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={6}>
            <p className="inputlabel">Type de marge brute par défault</p>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <Select
                labelId="default-markup-mode-select-label"
                id="default-markup-mode-select"
                value={this.state.defaultMarkupMode}
                onChange={this.handleUpdateDefaultMarkupMode}
              >
                <MenuItem value={"global"}>Globale</MenuItem>
                <MenuItem value={"yearly"}>Par années</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {this.state.defaultMarkupMode === "global" ? (
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={6}>
              <p className="inputlabel">Marge brute par défault</p>
            </Grid>
            <Grid item xs={6}>
              <Input
                id={`default_markup`}
                value={this.state.defaultMarkup}
                onChange={this.handleUpdateDefaultMarkup}
                style={{ width: "160px", fontSize: "0.8rem" }}
                endAdornment={<InputAdornment position="end">ct CHF/KWh</InputAdornment>}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={6}>
              <p className="inputlabel">Marge brute annuelle par défault</p>
            </Grid>
            <Grid item xs={6}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p className="text-small bold">Année</p>
                    </TableCell>
                    <TableCell>
                      <p className="text-small bold">Marge commercial brute</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.yearlyMarkups.map((yearlyMarkup) => {
                    return (
                      <TableRow key={`row_${yearlyMarkup.year}`}>
                        <TableCell>
                          <p className="text-small">{yearlyMarkup.year}</p>
                        </TableCell>
                        <TableCell>
                          <Input
                            id={`default_markup_${yearlyMarkup.year}`}
                            value={yearlyMarkup.markup ? yearlyMarkup.markup : 0}
                            onChange={(e) => this.handleUpdateYearlyMarkup(yearlyMarkup.year, e.target.value)}
                            style={{ width: "160px", fontSize: "0.8rem" }}
                            endAdornment={<InputAdornment position="end">ct CHF/KWh</InputAdornment>}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}

        <hr style={{ marginTop: "2rem", marginBottom: "3rem", borderTop: "solid 1px #E0E0E0", borderBottom: "none" }}></hr>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="title2">Validité</p>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={6}>
            <p className="inputlabel">Type de validité par défault</p>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <Select
                labelId="default-validity-mode-select-label"
                id="default-validity-mode-select"
                value={this.state.defaultValidityMode}
                onChange={this.handleUpdateDefaultValidityMode}
              >
                <MenuItem value={"day"}>Nombre de jours</MenuItem>
                <MenuItem value={"datetime"}>Date et heure spécifique</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {this.state.defaultValidityMode === "day" ? (
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={6}>
              <p className="inputlabel">Validité par défaut</p>
            </Grid>
            <Grid item xs={6}>
              <Input
                id={`default_markup`}
                value={this.state.defaultDuration}
                onChange={this.handleUpdateDefaultDuration}
                style={{ width: "160px", fontSize: "0.8rem" }}
                endAdornment={<InputAdornment position="end">jours</InputAdornment>}
              />
            </Grid>
          </Grid>
        ) : null}

        <hr style={{ marginTop: "2rem", marginBottom: "3rem", borderTop: "solid 1px #E0E0E0", borderBottom: "none" }}></hr>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="title2">Notifications</p>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={6}>
            <p className="inputlabel">Notifications d'offres gangées envoyée à</p>
          </Grid>
          <Grid item xs={6}>
            <Input id={`emailTo`} value={this.state.emailTo} onChange={this.handleUpdateMailTo} style={{ width: "100%", fontSize: "0.8rem" }} />
            <p className="text-small" style={{ marginTop: "5px" }}>
              Les adresses email doivent être séparées par des point-virgules (;)
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={6}>
            <p className="inputlabel">Copie de notidications d'offres gangées envoyée à</p>
          </Grid>
          <Grid item xs={6}>
            <Input id={`emailCc`} value={this.state.emailCc} onChange={this.handleUpdateMailCc} style={{ width: "100%", fontSize: "0.8rem" }} />
            <p className="text-small" style={{ marginTop: "5px" }}>
              Les adresses email doivent être séparées par des point-virgules (;)
            </p>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.shareholderId)),
  user: state.user,
  config: state.config,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setSetting: (key, value) => dispatch(setSetting(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationSettingsPanel);
