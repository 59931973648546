/**
 * AppEASA
 *
 * @file VolatilityPanel.js
 * @version 1.0
 * @description Volatility configuration panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { beginLoadVolatilities, beginSaveVolatility } from "../../../actions/volatilities";

// load other libs
import NumberFormat from "react-number-format";
import moment from "moment";
import _ from "lodash";

// load material UI components
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function volatilityFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      mask="_"
      fixedDecimalScale={true}
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "left", fontSize: "0.8rem" }}
    />
  );
}

function sdFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      mask="_"
      fixedDecimalScale={true}
      decimalScale={3}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "left", fontSize: "0.8rem" }}
    />
  );
}

class VolatilityPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addVolatilityDialogOpen: false,
      volatitliy: 0,
      standardDeviation: 0,
    };
  }

  componentDidMount() {
    this.props.beginLoadVolatilities();
  }

  handleAddVolatility = () => {
    this.setState({ addVolatilityDialogOpen: true });
  };

  handleCancelVolatility = () => {
    this.setState({ addVolatilityDialogOpen: false, volatility: 0, standardDeviation: 0 });
  };

  handleValueChange = (key, value) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        [key]: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
      };
    });
  };

  handleSaveVolatility = () => {
    console.log("handleSaveVolatility...");

    let body = {
      volatility: this.state.volatitliy,
      standardDeviation: this.state.standardDeviation,
    };

    this.props.beginSaveVolatility(body).then(() => {
      this.props.setSnackbar({ open: true, severity: "success", message: "Nouvelle volatilité enregistrée avec succès", duration: 3000 });
      this.handleCancelVolatility();
    });
  };

  render() {
    return (
      <Fragment>
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <p className="text bold">Définition de la volatilité du marché</p>
          </Grid>
          <Grid item>
            <ButtonGroup>
              <Button variant="contained" color="primary" size="small" onClick={this.handleAddVolatility}>
                Ajouter nouvelle volatilité
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <p className="text-small bold">Volatilité</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">Ecart type</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">Ajouté le</p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-small bold">Volatilité actuelle</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.volatilities &&
                  this.props.volatilities.map((volatility) => {
                    return (
                      <TableRow key={volatility.id}>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={volatility.volatility * 100}
                                suffix="%"
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">
                            {
                              <NumberFormat
                                displayType="text"
                                decimalScale={3}
                                fixedDecimalScale={true}
                                value={volatility.standardDeviation}
                                suffix=""
                                thousandSeparator="'"
                              />
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-small">{moment(volatility.created_at).format("DD/MM/YYYY HH:mm")}</p>
                        </TableCell>
                        <TableCell align="center">{volatility.isActive === 1 ? <CheckIcon fontSize="small" style={{ color: "green" }} /> : null}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.addVolatilityDialogOpen}
          onClose={this.handleAddVolatility}
          aria-labelledby="addvolatility-dialog-title"
          aria-describedby="addvolatility-dialog-description"
        >
          <DialogTitle id="addvolatility-dialog-title">App EASA : Ajout volatilité</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="addvolatility-dialog-description">
              Saisissez les champs ci-dessous et cliquez sur ajouter pour enregistrer les nouvelles données de volatilité du marché.
            </DialogContentText>

            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    id="standard-required"
                    label="Volatilité en %"
                    value={this.state.volatitliy}
                    onChange={(e) => this.handleValueChange("volatitliy", e.target.value)}
                    InputProps={{
                      inputComponent: volatilityFormat,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    required
                    id="standard-required"
                    label="Ecart type"
                    value={this.state.standardDeviation}
                    onChange={(e) => this.handleValueChange("standardDeviation", e.target.value)}
                    InputProps={{
                      inputComponent: sdFormat,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelVolatility} color="primary">
              Annuler
            </Button>
            <Button onClick={this.handleSaveVolatility} color="primary" autoFocus>
              Ajouter
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  volatilities: state.volatilities,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  beginLoadVolatilities: () => dispatch(beginLoadVolatilities()),
  beginSaveVolatility: (body) => dispatch(beginSaveVolatility(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VolatilityPanel);
