/**
 * AppEASA
 *
 * @file roles.js
 * @version 1.0
 * @description redux roles' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_ROLES } from "./types";

// load roles
export const beginLoadRoles = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/roles", config);
      DEBUG && console.log("GET /api/roles res = ", res.data);

      if (res.status === 200) {
        dispatch(setRoles(res.data.roles));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des rôles"],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      const errors = err.response.data.errors;
      reject({ loginStatus: false, msg: errors[0].msg });
    }
  });

// set roles
export const setRoles = (roles) => ({
  type: SET_ROLES,
  payload: roles,
});
