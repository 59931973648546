/**
 * AppEASA
 *
 * @file RestrictedAccess.js
 * @version 1.0
 * @description UI component to display when access is restricted
 *
 */

// load react components
import React, { Component } from "react";

// load Material UI components
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";

class RestrictedAccess extends Component {
  render() {
    return (
      <div style={{ width: "100%", marginTop: "10rem", marginBottom: "25rem" }}>
        <Container>
          <Alert severity="error">Oups - Accès non autorisé!</Alert>
        </Container>
      </div>
    );
  }
}

export default RestrictedAccess;
