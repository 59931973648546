/**
 * AppEASA
 *
 * @file AdminQuotationAccess.js
 * @version 1.0
 * @description component used to check admin quotation's view access
 *
 */

// base component
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// load components
import Can from "../../rbac/Can";

// load UI Components
import EditQuotation from "./EditQuotation";
import RestrictedAccess from "../../rbac/RestrictedAccess";

class EditQuotationAccess extends Component {
  render() {
    return (
      <Can
        permissions={this.props.user.permissions}
        action={"admin-quotation:edit"}
        yes={() => {
          return <EditQuotation />;
        }}
        no={() => <RestrictedAccess />}
      />
    );
  }
}

EditQuotationAccess.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  //navbar: state.navbar,
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditQuotationAccess);
