/**
 * AppEASA
 *
 * @file AddQuotation.js
 * @version 1.0
 * @description add new quotation component
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { beginLoadSettings } from "../../../actions/settings";
import { beginLoadPrices } from "../../../actions/prices";
import {
  resetNewQuotation,
  setShareholder,
  setReference,
  setSelectedMarketEnergyPrices,
  setMarketEnergyPricesDate,
  setMarketEnergyPricesTime,
  setMarketEnergyPricesType,
  setSelectedMarketGoPrices,
  setMarketGoPricesDate,
  setMarketGoPricesTime,
  setMarketAdjustmentCosts,
  setMarketRiskCosts,
} from "../../../actions/quotation";

// load other libs
import { withRouter } from "react-router-dom";
import { customAlphabet } from "nanoid";

// UI Component
import AddQuotationComponent from "../../quotation/AddQuotationComponent";
import ShareholderHeaderBlock from "../../shareholder/ShareholderHeaderBlock";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

class AddQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
    };
  }

  componentDidMount() {
    let redirectTo = this.props.user && this.props.user.role === "admin" ? "/dashboard" : "/shareholders";
    if (this.props.shareholder === undefined) {
      this.props.history.push(redirectTo);
      return;
    }

    // load shareholder prices
    this.props.beginLoadPrices(this.props.match.params.id).then((res) => {
      this.props.setSelectedMarketEnergyPrices(res.prices.power.prices);
      this.props.setMarketEnergyPricesDate(res.prices.power.date);
      this.props.setMarketEnergyPricesTime(res.prices.power.time);
      this.props.setMarketEnergyPricesType(res.prices.power.type);
      this.props.setSelectedMarketGoPrices(res.prices.gos.prices);
      this.props.setMarketGoPricesDate(res.prices.gos.date);
      this.props.setMarketGoPricesTime(res.prices.gos.time);
      this.props.setMarketAdjustmentCosts(res.prices.adjustmentCosts);
      this.props.setMarketRiskCosts(res.prices.spotRiskCosts);
    });

    this.props.setShareholder(this.props.shareholder.id);
    let ref = customAlphabet("1234567890", 7);

    this.props.setReference(ref());
    this.props.beginLoadSettings(this.props.shareholder.id);

    this.props.setBreadcrumb([
      {
        label: this.props.user && this.props.user.role === "admin" ? "Tableau de bord" : "Actionnaires",
        link: redirectTo,
      },
      {
        label: `Cotations ${this.props.shareholder.name}`,
        link: `/quotations/${this.props.shareholder.id}`,
      },
      { label: `Nouvelle cotation`, link: null },
    ]);
  }

  updateCurrentStep = (currentStep) => {
    this.setState({ currentStep });
  };

  cancelNewQuotation = () => {
    this.props.resetNewQuotation();
    if (this.props.user && this.props.user.role === "shareholder") {
      this.props.history.push("/quotations");
    } else {
      this.props.history.push(`/quotations/${this.props.shareholder && this.props.shareholder.id}`);
    }
  };

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <ShareholderHeaderBlock shareholderId={this.props.match.params.id} />
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Nouvelle cotation</p>
            </Grid>
            <Grid item>
              <ButtonGroup>
                {/* <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={this.saveQuotation}
                  disabled={this.state.currentStep !== 2}
                >
                  Enregistrer
                </Button> */}
                <Button size="small" variant="contained" disableElevation onClick={this.cancelNewQuotation}>
                  Annuler
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2rem" }}>
            <Grid item xs={12}>
              <AddQuotationComponent
                updateCurrentStep={this.updateCurrentStep}
                shareholderId={this.props.shareholder && this.props.shareholder.id}
                cancelNewQuotation={this.cancelNewQuotation}
              />
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  user: state.user,
  quotation: state.quotation,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  beginLoadSettings: (shareholderId) => dispatch(beginLoadSettings(shareholderId)),
  beginLoadPrices: (shareholderId) => dispatch(beginLoadPrices(shareholderId)),
  resetNewQuotation: () => dispatch(resetNewQuotation()),
  setShareholder: (shareholderId) => dispatch(setShareholder(shareholderId)),
  setReference: (reference) => dispatch(setReference(reference)),
  setSelectedMarketEnergyPrices: (prices) => dispatch(setSelectedMarketEnergyPrices(prices)),
  setSelectedMarketGoPrices: (prices) => dispatch(setSelectedMarketGoPrices(prices)),
  setMarketAdjustmentCosts: (prices) => dispatch(setMarketAdjustmentCosts(prices)),
  setMarketRiskCosts: (prices) => dispatch(setMarketRiskCosts(prices)),
  setMarketEnergyPricesDate: (date) => dispatch(setMarketEnergyPricesDate(date)),
  setMarketEnergyPricesTime: (time) => dispatch(setMarketEnergyPricesTime(time)),
  setMarketEnergyPricesType: (type) => dispatch(setMarketEnergyPricesType(type)),
  setMarketGoPricesDate: (date) => dispatch(setMarketGoPricesDate(date)),
  setMarketGoPricesTime: (time) => dispatch(setMarketGoPricesTime(time)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddQuotation));
