/**
 * AppEASA
 *
 * @file PowerHistoryPricesPanel.js
 * @version 1.0
 * @description power prices history panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import {
  beginLoadHistoryPowerTimes,
  setHistoryTimes,
  setHistoryPrices,
  beginLoadHistoryPowerPrices,
  getLastPowerPricesTime,
} from "../../../actions/historyprices";

// load other libs
import NumberFormat from "react-number-format";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";

// load material UI components
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";

class PowerHistoryPricesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      selectedTime: "n/a",
      isCurrentTime: false,
      priceType: "n/a",
      apiStatus: "n/a",
      lastPricesDate: "",
      lastPricesTime: "",
    };
  }

  componentDidMount() {
    moment.locale("fr");
    let today = moment();
    this.setState({ selectedDate: today }, () => {
      this.loadAvailableTimes();
    });
  }

  getTimeButtonsGroup = () => {
    return (
      <ButtonGroup size="small" color="primary">
        {this.props.historyprices &&
          this.props.historyprices.times &&
          this.props.historyprices.times.map((time) => {
            return (
              <Button
                disableElevation
                key={time.id}
                variant={time.time === this.state.selectedTime ? "contained" : "outlined"}
                onClick={(e) => this.handleTimeSelectorChange(time)}
              >
                {time.time}
              </Button>
            );
          })}
      </ButtonGroup>
    );
  };

  handleDateSelectorChange = (date) => {
    // console.log("handleDateSelectorChange ", moment(date).format("DD/MM/YYYY"));
    this.setState({ selectedDate: date }, () => {
      this.loadAvailableTimes();
    });
  };

  loadAvailableTimes = () => {
    this.props.setLoading(true);
    this.props.setHistoryTimes([]);
    this.props.setHistoryPrices([]);
    this.props
      .beginLoadHistoryPowerTimes(moment(this.state.selectedDate).format("YYYY-MM-DD"))
      .then((res) => {
        if (res.length === 0) {
          this.props
            .getLastPowerPricesTime()
            .then((res) => {
              this.setState({ lastPricesDate: res.time.date, lastPricesTime: res.time.time });
              this.props.setSnackbar({ open: true, severity: "warning", message: "Aucuns prix disponibles pour la date sélectionnée.", duration: 3000 });
            })
            .catch((err) => {});
        } else {
          this.handleTimeSelectorChange(res.defaultTime);
        }
        this.props.setLoading(false);
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Une erreur s'est produite, réessayez plus tard.", duration: 3000 });
      });
  };

  handleTimeSelectorChange = (time) => {
    this.props.setLoading(true);
    this.props
      .beginLoadHistoryPowerPrices(time.id)
      .then(() => {
        this.props.setLoading(false);
        this.setState({ selectedTime: time.time, priceType: time.type, apiStatus: time.status + " " + time.statusText, isCurrentTime: time.isCurrent });
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Une erreur s'est produite, réessayez plus tard.", duration: 3000 });
      });
  };

  render() {
    return (
      <Fragment>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12}>
            <p className="title1 bold">Electricité</p>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" alignItems="flex-end">
          <Grid item xs={3}>
            <p className="text bold" style={{ paddingBottom: "3px" }}>
              Sélectionnez une date
            </p>
          </Grid>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"fr"}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date"
                  value={this.state.selectedDate}
                  onChange={this.handleDateSelectorChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  orientation="landscape"
                  cancelLabel="Annuler"
                  maxDate={Date(moment())}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container spacing={3} direction="row" alignItems="flex-end">
          <Grid item xs={12}>
            <Grid container spacing={0} direction="row" justify="flex-end" alignItems="center">
              <Grid item>{this.getTimeButtonsGroup()}</Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.props.historyprices && this.props.historyprices.prices && this.props.historyprices.prices.length > 0 ? (
          <Fragment>
            <Grid container spacing={3} style={{ marginTop: "1rem" }} direction="row" alignItems="center" justify="space-between">
              <Grid item>
                <p className="text-small">
                  Vérsion de : <span className="bold">{this.state.selectedTime}</span>
                </p>
              </Grid>
              <Grid item>
                <p className="text-small">
                  Type de prix : <span className="bold">{this.state.priceType === "settlement" ? "Clôture" : "Temps réél"}</span>
                </p>
              </Grid>
              <Grid item>
                <p className="text-small">Statut de réception : {this.state.apiStatus}</p>
              </Grid>
            </Grid>
            {this.state.isCurrentTime ? (
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xs={12}>
                  <Alert severity="warning">Prix marchés actuellement appliqués pour les cotations</Alert>
                </Grid>
              </Grid>
            ) : null}
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none", borderRight: "solid 1px #e0e0e0" }}></TableCell>
                      <TableCell colSpan={2} style={{ borderBottom: "none", borderRight: "solid 1px #e0e0e0" }} align="center">
                        <p className="text-small bold">Prix DE en &euro;/MWh</p>
                      </TableCell>
                      <TableCell colSpan={2} style={{ borderBottom: "none", borderRight: "solid 1px #e0e0e0" }} align="center">
                        <p className="text-small bold">Prix CH en &euro;/MWh</p>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderRight: "solid 1px #e0e0e0" }}>
                        <p className="text-small bold">Produit</p>
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: "dotted 1px #e0e0e0" }}>
                        <p className="text-small bold">Bid</p>
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: "solid 1px #e0e0e0" }}>
                        <p className="text-small bold">Ask</p>
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: "dotted 1px #e0e0e0" }}>
                        <p className="text-small bold">Bid</p>
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: "solid 1px #e0e0e0" }}>
                        <p className="text-small bold">Ask</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="text-small bold">Euro/CHF</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.historyprices.prices.map((price) => {
                      let rowBGColor = "none";
                      if (price.symbol.substring(0, 3) === "CAL") {
                        rowBGColor = "rgba(0, 91, 151, 0.2)";
                      } else if (price.symbol.substring(0, 1) === "Q") {
                        rowBGColor = "rgba(255, 255, 128, 0.2)";
                      }
                      return (
                        <TableRow key={price.id} style={{ backgroundColor: rowBGColor }}>
                          <TableCell align="left" style={{ borderRight: "solid 1px #e0e0e0" }}>
                            <p className="text-small">{price.symbol}</p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "dotted 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={4}
                                fixedDecimalScale={true}
                                value={price.bid_power_DE}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "solid 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={4}
                                fixedDecimalScale={true}
                                value={price.ask_power_DE}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "dotted 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={4}
                                fixedDecimalScale={true}
                                value={price.bid_power_CH}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "solid 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={4}
                                fixedDecimalScale={true}
                                value={price.ask_power_CH}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={8}
                                fixedDecimalScale={true}
                                value={price.xr_EURO_CH}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid container spacing={3} style={{ marginTop: "2rem" }}>
              <Grid item>
                <p className="text-small italic">
                  Aucune donnée de prix disponible pour la date sélectionnée.
                  {this.state.lastPricesDate && this.state.lastPricesTime ? (
                    <Fragment>
                      Derniers prix reçus le{" "}
                      <Link href="#" onClick={(e) => this.handleDateSelectorChange(this.state.lastPricesDate)}>
                        {moment(this.state.lastPricesDate).format("DD MMMM YYYY")}
                      </Link>
                      à {this.state.lastPricesTime}.
                    </Fragment>
                  ) : null}
                </p>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  historyprices: state.historyprices,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  setHistoryTimes: (times) => dispatch(setHistoryTimes(times)),
  setHistoryPrices: (prices) => dispatch(setHistoryPrices(prices)),
  beginLoadHistoryPowerTimes: (date) => dispatch(beginLoadHistoryPowerTimes(date)),
  beginLoadHistoryPowerPrices: (priceId) => dispatch(beginLoadHistoryPowerPrices(priceId)),
  getLastPowerPricesTime: () => dispatch(getLastPowerPricesTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerHistoryPricesPanel);
