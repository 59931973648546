import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/routing/PrivateRoute";
import { beginLoadAppConfig } from "./actions/appconfig";
import { beginLoadUser } from "./actions/user";

// Load redux components
import { Provider } from "react-redux";
import store from "./store";

// Loading SCSS files
import "./styles.scss";

// Custom UI Components
import Navbar from "./components/navbar/Navbar";
import Breadcrumb from "./components/ui/Breadcrumb";
import Footer from "./components/footer/Footer";
import EASASnackbar from "./components/ui/EASASnackbar";

// Load UI views
import Login from "./components/views/Login";
import UpdatePassword from "./components/views/UpdatePassword";

import MarketAccess from "./components/views/market/MarketAccess";
import DashboardAccess from "./components/views/dashboard/DashboardAccess";
import ShareholdersAccess from "./components/views/shareholders/ShareholdersAccess";

import UsersAccess from "./components/views/users/UsersAccess";
import UsersAddAccess from "./components/views/users/UsersAddAccess";
import UsersEditAccess from "./components/views/users/UsersEditAccess";

import ReportingAccess from "./components/views/reporting/ReportingAccess";

import ShareholderReporting from "./components/views/reporting/ShareholderReportingAccess";

import QuotationsAccess from "./components/views/quotations/QuotationsAccess";
import AddQuotationAccess from "./components/views/quotations/AddQuotationAccess";
import EditQuotationAccess from "./components/views/quotations/EditQuotationAccess";

import ShareholderQuotationAccess from "./components/views/quotations/ShareholderQuotationAccess";

import SettingsAccess from "./components/views/settings/SettingsAccess";

import ConfigurationAccess from "./components/views/configuration/ConfigurationAccess";

import HistoryPricesAccess from "./components/views/historyprices/HistoryPricesAccess";

import HelpAccess from "./components/views/help/HelpAccess";

import NewsletterJanv2022 from "./components/views/newsletter/NewsletterJanv2022Access";
import NewsletterFev2022A from "./components/views/newsletter/NewsletterFev2022Access";
// import newsletterNove2021Access from "./components/views/newsletter/NewsletterNov2021Access";
// import NewsletterNov2021Access from "./components/views/newsletter/NewsletterNov2021Access";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(0, 91, 151, 1)",
    },
    secondary: {
      main: red[500],
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

if (localStorage.token) {
  // if token exists, set the axios header x-auth-token
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    // check if user is authenticated, then load user
    if (store.getState().auth.isAuthenticated) {
      store.dispatch(beginLoadAppConfig());
      store.dispatch(beginLoadUser()).then(() => {
        // console.log("do something...");
        // if (store.getState().user.setNewPassword === 0) {
        //   console.log("redirect...");
        //   return <Redirect to={"/updatepassword"} />;
        // }
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Fragment>
            <Navbar />
            <Breadcrumb />
            <EASASnackbar />
            <Switch>
              <Route exact={true} path="/" component={Login} />
              <PrivateRoute exact={true} path="/updatepassword" component={UpdatePassword} />
              <PrivateRoute exact={true} path="/market" component={MarketAccess} />
              <PrivateRoute exact={true} path="/dashboard" component={DashboardAccess} />
              <PrivateRoute exact={true} path="/newsletterJan2022" component={NewsletterJanv2022} />
              <PrivateRoute exact={true} path="/newsletterFev2022" component={NewsletterFev2022A} />
              <PrivateRoute exact={true} path="/shareholders" component={ShareholdersAccess} />
              <PrivateRoute exact={true} path="/users" component={UsersAccess} />
              <PrivateRoute exact={true} path="/users/add" component={UsersAddAccess} />
              <PrivateRoute exact={true} path="/users/edit/:id" component={UsersEditAccess} />
              <PrivateRoute exact={true} path="/reporting/:id" component={ReportingAccess} />
              <PrivateRoute exact={true} path="/reporting" component={ShareholderReporting} />
              <PrivateRoute exact={true} path="/quotations" component={ShareholderQuotationAccess} />
              <PrivateRoute exact={true} path="/help" component={HelpAccess} />
              <PrivateRoute exact={true} path="/quotations/:id" component={QuotationsAccess} />
              <PrivateRoute exact={true} path="/quotations/add/:id" component={AddQuotationAccess} />
              <PrivateRoute exact={true} path="/quotations/edit/:id/:quotationId" component={EditQuotationAccess} />
              <PrivateRoute exact={true} path="/settings/:id" component={SettingsAccess} />
              <PrivateRoute exact={true} path="/config" component={ConfigurationAccess} />
              <PrivateRoute exact={true} path="/history" component={HistoryPricesAccess} />
            </Switch>
            <Footer />
          </Fragment>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
