/**
 * AppEASA
 *
 * @file prospects.js
 * @version 1.0
 * @description redux shareholders prospects' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_PROSPECTS } from "./types";

// load prospects belonging to a shareholder
export const beginLoadProspects = (shareholderId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;
    DEBUG && console.log("beginLoadProspects, shareholderId=", shareholderId);
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/prospects/" + shareholderId, config);
      DEBUG && console.log("GET /api/prospects/:shareholderId res = ", res.data);

      if (res.status === 200) {
        dispatch(setProspects(res.data.prospects));
        resolve({ status: res.status });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des prospets"],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      const errors = err.response.data.errors;
      reject({ loginStatus: false, msg: errors[0].msg });
    }
  });

// set prospects
export const setProspects = (prospects) => ({
  type: SET_PROSPECTS,
  payload: prospects,
});
