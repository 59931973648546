/**
 * AppEASA
 *
 * @file types.js
 * @description redux action type definition
 *
 */

// ui reducer
export const SET_LOADING = "SET_LOADING";

// snackbar reducer
export const SET_SNACKBAR = "SET_SNACKBAR";

// breadcrumb reducer
export const SET_BREADCRUMB = "SET_BREADCRUMB";

// auth reducer
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const LOGOUT = "LOGOUT";

// user reducer
export const SET_USER = "SET_USER";

// users reducer
export const SET_USERS = "SET_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

// shareholder reducer
export const SET_SHAREHOLDERS = "SET_SHAREHOLDERS";

// consumers
export const SET_CONSUMERS = "SET_CONSUMERS";

// prospects reducer
export const SET_PROSPECTS = "SET_PROSPECTS";

// pods reducer
export const SET_PODS = "SET_PODS";

// quotations reducer
export const SET_QUOTATIONS = "SET_QUOTATIONS";
export const UPDATE_QUOTATION = "UPDATE_QUOTATION";

// prices reducer
export const SET_PRICING = "SET_PRICING";

// new quotation reducer
export const QUOTATION_SET_SHAREHOLDER = "QUOTATION_SET_SHAREHOLDER";

export const QUOTATION_SET_REFERENCE = "QUOTATION_SET_REFERENCE";

export const QUOTATION_SET_STEP2LOADED = "QUOTATION_SET_STEP2LOADED";

export const QUOTATION_SET_PROSPECT = "QUOTATION_SET_PROSPECT";
export const QUOTATION_SET_PROSPECT_NUMBER = "QUOTATION_SET_PROSPECT_NUMBER";
export const QUOTATION_SET_PROSPECT_NAME = "QUOTATION_SET_PROSPECT_NAME";
export const QUOTATION_SET_PROSPECT_ADDRESS1 = "QUOTATION_SET_PROSPECT_ADDRESS1";
export const QUOTATION_SET_PROSPECT_ADDRESS2 = "QUOTATION_SET_PROSPECT_ADDRESS2";
export const QUOTATION_SET_PROSPECT_ZIPCODE = "QUOTATION_SET_PROSPECT_ZIPCODE";
export const QUOTATION_SET_PROSPECT_LOCATION = "QUOTATION_SET_PROSPECT_LOCATION";

export const QUOTATION_SET_STARTYEAR = "QUOTATION_SET_STARTYEAR";
export const QUOTATION_SET_ENDYEAR = "QUOTATION_SET_ENDYEAR";

export const QUOTATION_SET_POD = "QUOTATION_SET_POD";
export const QUOTATION_SET_PRICETYPE = "QUOTATION_SET_PRICETYPE";
export const QUOTATION_SET_MARKET = "QUOTATION_SET_MARKET";
export const QUOTATION_SET_FCCQUATRO = "QUOTATION_SET_FCCQUATRO";

export const QUOTATION_SET_YEARS = "QUOTATION_SET_YEARS";

export const QUOTATION_UPDATE_ALL = "QUOTATION_UPDATE_ALL";

export const QUOTATION_SET_MARKETENERGYPRICES = "QUOTATION_SET_MARKETENERGYPRICES";
export const QUOTATION_SET_MARKETENERGYPRICESDATE = "QUOTATION_SET_MARKETENERGYPRICESDATE";
export const QUOTATION_SET_MARKETENERGYPRICESTIME = "QUOTATION_SET_MARKETENERGYPRICESTIME";
export const QUOTATION_SET_MARKETENERGYPRICESTYPE = "QUOTATION_SET_MARKETENERGYPRICESTYPE";
export const QUOTATION_SET_ENERGYPRICES = "QUOTATION_SET_ENERGYPRICES";
export const QUOTATION_SET_ENERGYPRICE = "QUOTATION_SET_ENERGYPRICE";

export const QUOTATION_SET_MARKETGOPRICES = "QUOTATION_SET_MARKETGOPRICES";
export const QUOTATION_SET_MARKETGOPRICESDATE = "QUOTATION_SET_MARKETGOPRICESDATE";
export const QUOTATION_SET_MARKETGOPRICESTIME = "QUOTATION_SET_MARKETGOPRICESTIME";
export const QUOTATION_SET_GOPRICES = "QUOTATION_SET_GOPRICES";
export const QUOTATION_SET_SELECTEDGO = "QUOTATION_SET_SELECTEDGO";
export const QUOTATION_SET_GOPRICE = "QUOTATION_SET_GOPRICE";
export const QUOTATION_SET_GOCUSTOMNAME = "QUOTATION_SET_GOCUSTOMNAME";

export const QUOTATION_SET_MARKETADJUSTMENTCOSTS = "QUOTATION_SET_MARKETADJUSTMENTCOSTS";
export const QUOTATION_SET_ADJUSTMENTCOSTS = "QUOTATION_SET_ADJUSTMENTCOSTS";
export const QUOTATION_SET_ADJUSTMENTCOST = "QUOTATION_SET_ADJUSTMENTCOST";

export const QUOTATION_SET_MARKETRISKCOSTS = "QUOTATION_SET_MARKETRISKCOSTS";
export const QUOTATION_SET_RISKCOSTS = "QUOTATION_SET_RISKCOSTS";
export const QUOTATION_SET_RISKCOST = "QUOTATION_SET_RISKCOST";

export const QUOTATION_SET_MARKUPMODE = "QUOTATION_SET_MARKUPMODE";
export const QUOTATION_SET_MARKUP = "QUOTATION_SET_MARKUP";
export const QUOTATION_SET_YEARLYMARKUPS = "QUOTATION_SET_YEARLYMARKUPS";

export const QUOTATION_SET_VALIDITYMODE = "QUOTATION_SET_VALIDITYMODE";
export const QUOTATION_SET_VALIDITYDATE = "QUOTATION_SET_VALIDITYDATE";
export const QUOTATION_SET_VALIDITYTIME = "QUOTATION_SET_VALIDITYTIME";
export const QUOTATION_SET_VALIDITYDURATION = "QUOTATION_SET_VALIDITYDURATION";
export const QUOTATION_SET_VALIDITYMARKUP = "QUOTATION_SET_VALIDITYMARKUP";
export const QUOTATION_SET_VALIDITYCOST = "QUOTATION_SET_VALIDITYCOST";

export const QUOTATION_SET_VOLUME = "QUOTATION_SET_VOLUME";

export const QUOTATION_SET_COMBINATIONS = "QUOTATION_SET_COMBINATIONS";
export const QUOTATION_SET_STATUS = "QUOTATION_SET_STATUS";

export const QUOTATION_RESET = "QUOTATION_RESET";

// quotations filter
export const SET_QUOTATIONS_TEXT_FILTER = "SET_QUOTATIONS_TEXT_FILTER";
export const SET_QUOTATIONS_STATUS_FILTER = "SET_QUOTATIONS_STATUS_FILTER";
export const SET_QUOTATIONS_PROSPECT_FILTER = "SET_QUOTATIONS_PROSPECT_FILTER";

// settings reducer
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_SETTING = "SET_SETTING";

// config reducer
export const SET_CONFIG = "SET_CONFIG";

// template content blocs
export const SET_TEMPLATES_CONTENT = "SET_TEMPLATES_CONTENT";

// volatilities
export const SET_VOLATILITIES = "SET_VOLATILITIES";

// market structure
export const SET_MARKETSTRUCTURES = "SET_MARKETSTRUCTURES";

// history prices
export const SET_HISTORYPRICES_TIMES = "SET_HISTORYPRICES_TIMES";
export const SET_HISTORYPRICES = "SET_HISTORYPRICES";

// roles
export const SET_ROLES = "SET_ROLES";

// reporst
export const SET_REPORTS = "SET_REPORTS";
