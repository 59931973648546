/**
 * @description Login form
 * display fields and check they are not empty before sumbiting them to the parent view (Login)
 *
 */

// Base components
import React, { Component } from "react";
import _ from "lodash";

// Material UI Components
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from "@material-ui/lab/Alert";

export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      username: "",
      usernameError: false,
      password: "",
      passwordError: false,
    };
  }

  // toggle display clear password
  displayPassword = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showPassword: !prevState.showPassword,
      };
    });
  };

  // update username and hide error message
  updateUsername = (e) => {
    this.setState(
      {
        username: e.target.value,
        usernameError: false,
      },
      () => {
        this.props.hideErrorMsg();
      }
    );
  };

  // update password and hide error message
  updatePassword = (e) => {
    this.setState(
      {
        password: e.target.value,
        passwordError: false,
      },
      () => {
        this.props.hideErrorMsg();
      }
    );
  };

  // ensure no empty username and/or password before submitting form
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ usernameError: false, passwordError: false }, () => {
      if (_.isEmpty(this.state.username.trim())) {
        this.setState({ usernameError: true }, () => {
          return;
        });
      } else if (this.state.password === null || this.state.password.match(/^\s*$/)) {
        this.setState({ passwordError: true }, () => {
          return;
        });
      } else {
        this.props.submit(this.state.username, this.state.password);
      }
    });
  };

  render() {
    return (
      <Container style={{ padding: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <FormControl style={{ width: "100%" }} error={this.state.usernameError}>
              <InputLabel htmlFor="easa-username">Nom d'utilisateur</InputLabel>
              <Input id="easa-username" type="text" value={this.state.username} onChange={this.updateUsername} />
              {this.state.usernameError ? <FormHelperText id="username-error-text">Nom d'utilisateur incorrect</FormHelperText> : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <FormControl style={{ width: "100%" }} error={this.state.passwordError}>
              <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
              <Input
                id="easa-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.updatePassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={this.displayPassword} size="small">
                      {this.state.showPassword ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {this.state.passwordError ? <FormHelperText id="password-error-text">Mot de passe non valide</FormHelperText> : null}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {this.props.errorMsg !== "" ? <Alert severity="error">{this.props.errorMsg}</Alert> : null}
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" size="small" variant="contained" className="login-button" style={{ marginTop: "1rem" }} onClick={this.onSubmit}>
              Connexion
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Button color="primary" size="small" className="login-button" onClick={(e) => this.props.setResetPassword(true)}>
              Mot de passe oublié ?
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
