/**
 * AppEASA
 *
 * @file NewsletterFev2022Access.js
 * @version 1.0
 * @description Newsletter access
 *
 */

// load requierd librairies
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// load components
import Can from "../../rbac/Can";

// load UI Components
import NewsletterFev2022 from ".//NewsletterFev2022";
import RestrictedAccess from "../../rbac/RestrictedAccess";

class NewsletterFev2022Access extends Component {
  render() {
    return (
      <Can
        permissions={this.props.user.permissions}
        action={"newsletter:access"}
        yes={() => {
          return <NewsletterFev2022 />;
        }}
        no={() => <RestrictedAccess />}
      />
    );
  }
}

NewsletterFev2022Access.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  //navbar: state.navbar,
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterFev2022Access);
