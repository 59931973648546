import React from "react";

// Load Material UI
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "rgba(17, 170, 0, 1)", // green
    //color: "rgb(255, 206, 0)", // orange
  },
}));

export default function Loader({ display }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={display}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
