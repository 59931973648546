/**
 * AppEASA
 *
 * @file quotation.js
 * @version 1.0
 * @description new quotation reducer
 *
 */

// import redux action type
import {
  QUOTATION_SET_SHAREHOLDER,
  QUOTATION_SET_REFERENCE,
  QUOTATION_SET_STEP2LOADED,
  QUOTATION_SET_PROSPECT,
  QUOTATION_SET_PROSPECT_NUMBER,
  QUOTATION_SET_PROSPECT_NAME,
  QUOTATION_SET_PROSPECT_ADDRESS1,
  QUOTATION_SET_PROSPECT_ADDRESS2,
  QUOTATION_SET_PROSPECT_ZIPCODE,
  QUOTATION_SET_PROSPECT_LOCATION,
  QUOTATION_SET_POD,
  QUOTATION_SET_PRICETYPE,
  QUOTATION_SET_MARKET,
  QUOTATION_SET_FCCQUATRO,
  QUOTATION_SET_YEARS,
  QUOTATION_SET_MARKETENERGYPRICES,
  QUOTATION_SET_MARKETENERGYPRICESDATE,
  QUOTATION_SET_MARKETENERGYPRICESTIME,
  QUOTATION_SET_MARKETENERGYPRICESTYPE,
  QUOTATION_SET_ENERGYPRICES,
  QUOTATION_SET_ENERGYPRICE,
  QUOTATION_SET_MARKETGOPRICES,
  QUOTATION_SET_MARKETGOPRICESDATE,
  QUOTATION_SET_MARKETGOPRICESTIME,
  QUOTATION_SET_GOPRICES,
  QUOTATION_SET_SELECTEDGO,
  QUOTATION_SET_GOPRICE,
  QUOTATION_SET_GOCUSTOMNAME,
  QUOTATION_SET_MARKETADJUSTMENTCOSTS,
  QUOTATION_SET_ADJUSTMENTCOSTS,
  QUOTATION_SET_ADJUSTMENTCOST,
  QUOTATION_SET_MARKETRISKCOSTS,
  QUOTATION_SET_RISKCOSTS,
  QUOTATION_SET_RISKCOST,
  QUOTATION_SET_MARKUPMODE,
  QUOTATION_SET_MARKUP,
  QUOTATION_SET_YEARLYMARKUPS,
  QUOTATION_SET_VALIDITYMODE,
  QUOTATION_SET_VALIDITYDATE,
  QUOTATION_SET_VALIDITYTIME,
  QUOTATION_SET_VALIDITYDURATION,
  QUOTATION_SET_VALIDITYMARKUP,
  QUOTATION_SET_VALIDITYCOST,
  QUOTATION_SET_VOLUME,
  QUOTATION_SET_COMBINATIONS,
  QUOTATION_SET_STATUS,
  QUOTATION_RESET,
} from "../actions/types";

// set initial state
const initialState = {
  shareholder: 0,
  reference: "",
  step2Loaded: false,
  selectedProspect: "unselected",
  selectedPod: "unselected",
  selectedPriceType: "unselected",
  marketStructure: {
    q1: {
      hp: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      hc: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      swx: 0,
      fwd: 0,
      factor: 0,
    },
    q2: {
      hp: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      hc: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      swx: 0,
      fwd: 0,
      factor: 0,
    },
    q3: {
      hp: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      hc: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      swx: 0,
      fwd: 0,
      factor: 0,
    },
    q4: {
      hp: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      hc: { hours: 0, consumption: 0, fccswx: 0, fccfwd: 0 },
      swx: 0,
      fwd: 0,
      factor: 0,
    },
  },
  fccQuattro: {
    year: {
      total: 0,
      HP: 0,
      HC: 0,
    },
    summer: {
      total: 0,
      HP: 0,
      HC: 0,
    },
    winter: {
      total: 0,
      HP: 0,
      HC: 0,
    },
  },
  prospectNumber: "",
  prospectName: "",
  prospectAddress1: "",
  prospectAddress2: "",
  prospectZipcode: "",
  prospectLocation: "",
  startYear: 0,
  endYear: 0,
  years: [],
  marketEnergyPrices: [],
  marketEnergyPricesDate: null,
  marketEnergyPricesTime: null,
  marketEnergyPricesType: "",
  energyPrices: [],
  energyPrice: 0,
  selectedGo: 1,
  marketGoPrices: [],
  marketGoPricesDate: null,
  marketGoPricesTime: null,
  goPrices: [],
  goPrice: 0,
  goCustomName: "",
  marketAdjustmentCosts: [],
  adjustmentCosts: [],
  adjustmentCost: 0,
  marketRiskCosts: [],
  riskCosts: [],
  riskCost: 0,
  markupMode: "global",
  yearlyMarkups: [],
  markup: 0,
  validityMode: "day",
  validityDuration: 0,
  validityDate: null,
  validityTime: null,
  validityMarkup: 0,
  validityCost: 0,
  volumeMWh: 0,
  combinations: [],
  status: "",
};

// export reducer
export default function customersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case QUOTATION_SET_SHAREHOLDER:
      return {
        ...state,
        shareholder: payload,
      };

    case QUOTATION_SET_REFERENCE:
      return {
        ...state,
        reference: payload,
      };

    case QUOTATION_SET_STEP2LOADED:
      return {
        ...state,
        step2Loaded: payload,
      };

    case QUOTATION_SET_PROSPECT:
      return {
        ...state,
        selectedProspect: payload,
      };

    case QUOTATION_SET_PROSPECT_NUMBER:
      return {
        ...state,
        prospectNumber: payload,
      };

    case QUOTATION_SET_PROSPECT_NAME:
      return {
        ...state,
        prospectName: payload,
      };

    case QUOTATION_SET_PROSPECT_ADDRESS1:
      return {
        ...state,
        prospectAddress1: payload,
      };

    case QUOTATION_SET_PROSPECT_ADDRESS2:
      return {
        ...state,
        prospectAddress2: payload,
      };

    case QUOTATION_SET_PROSPECT_ZIPCODE:
      return {
        ...state,
        prospectZipcode: payload,
      };

    case QUOTATION_SET_PROSPECT_LOCATION:
      return {
        ...state,
        prospectLocation: payload,
      };

    case QUOTATION_SET_POD:
      return {
        ...state,
        selectedPod: payload,
      };

    case QUOTATION_SET_PRICETYPE:
      return {
        ...state,
        selectedPriceType: payload,
      };

    case QUOTATION_SET_MARKET:
      return {
        ...state,
        marketStructure: payload,
      };

    case QUOTATION_SET_FCCQUATRO:
      return {
        ...state,
        fccQuattro: payload,
      };

    case QUOTATION_SET_YEARS:
      return {
        ...state,
        startYear: action.startYear,
        endYear: action.endYear,
        years: action.years,
      };

    /**
     * Energy
     */
    case QUOTATION_SET_MARKETENERGYPRICES:
      return {
        ...state,
        marketEnergyPrices: payload,
      };

    case QUOTATION_SET_MARKETENERGYPRICESDATE:
      return {
        ...state,
        marketEnergyPricesDate: payload,
      };

    case QUOTATION_SET_MARKETENERGYPRICESTIME:
      return {
        ...state,
        marketEnergyPricesTime: payload,
      };

    case QUOTATION_SET_MARKETENERGYPRICESTYPE:
      return {
        ...state,
        marketEnergyPricesType: payload,
      };

    case QUOTATION_SET_ENERGYPRICES:
      return {
        ...state,
        energyPrices: payload,
      };

    case QUOTATION_SET_ENERGYPRICE:
      return {
        ...state,
        energyPrice: payload,
      };

    /**
     * GO
     */
    case QUOTATION_SET_MARKETGOPRICES:
      return {
        ...state,
        marketGoPrices: payload,
      };

    case QUOTATION_SET_MARKETGOPRICESDATE:
      return {
        ...state,
        marketGoPricesDate: payload,
      };

    case QUOTATION_SET_MARKETGOPRICESTIME:
      return {
        ...state,
        marketGoPricesTime: payload,
      };

    case QUOTATION_SET_GOPRICES:
      return {
        ...state,
        goPrices: payload,
      };

    case QUOTATION_SET_SELECTEDGO:
      return {
        ...state,
        selectedGo: payload,
      };

    case QUOTATION_SET_GOPRICE:
      return {
        ...state,
        goPrice: payload,
      };

    case QUOTATION_SET_GOCUSTOMNAME:
      return {
        ...state,
        goCustomName: payload,
      };

    /**
     * Adjustment
     */
    case QUOTATION_SET_MARKETADJUSTMENTCOSTS:
      return {
        ...state,
        marketAdjustmentCosts: payload,
      };

    case QUOTATION_SET_ADJUSTMENTCOSTS:
      return {
        ...state,
        adjustmentCosts: payload,
      };

    case QUOTATION_SET_ADJUSTMENTCOST:
      return {
        ...state,
        adjustmentCost: payload,
      };

    /**
     * risk
     */
    case QUOTATION_SET_MARKETRISKCOSTS:
      return {
        ...state,
        marketRiskCosts: payload,
      };

    case QUOTATION_SET_RISKCOSTS:
      return {
        ...state,
        riskCosts: payload,
      };

    case QUOTATION_SET_RISKCOST:
      return {
        ...state,
        riskCost: payload,
      };

    /**
     * Markup
     */

    case QUOTATION_SET_MARKUPMODE:
      return {
        ...state,
        markupMode: payload,
      };

    case QUOTATION_SET_MARKUP:
      return {
        ...state,
        markup: payload,
      };

    case QUOTATION_SET_YEARLYMARKUPS:
      return {
        ...state,
        yearlyMarkups: payload,
      };

    /**
     * Validity
     */
    case QUOTATION_SET_VALIDITYMODE:
      return {
        ...state,
        validityMode: payload,
      };

    case QUOTATION_SET_VALIDITYDATE:
      return {
        ...state,
        validityDate: payload,
      };

    case QUOTATION_SET_VALIDITYTIME:
      return {
        ...state,
        validityTime: payload,
      };

    case QUOTATION_SET_VALIDITYDURATION:
      return {
        ...state,
        validityDuration: payload,
      };

    case QUOTATION_SET_VALIDITYMARKUP:
      return {
        ...state,
        validityMarkup: payload,
      };

    case QUOTATION_SET_VALIDITYCOST:
      return {
        ...state,
        validityCost: payload,
      };

    /**
     * Volume
     */
    case QUOTATION_SET_VOLUME:
      return {
        ...state,
        volumeMWh: payload,
      };

    case QUOTATION_SET_COMBINATIONS:
      return {
        ...state,
        combinations: payload,
      };

    case QUOTATION_SET_STATUS:
      return {
        ...state,
        status: payload,
      };

    case QUOTATION_RESET:
      return initialState;

    default:
      return state;
  }
}
