/**
 * AppEASA
 *
 * @file QuotationsListFilters.js
 * @version 1.0
 * @description filters component for quotations list
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Redux functions
import { setQuotationsTextFilter, setQuotationsStatusFilter, setQuotationsProspectsFilter } from "../../actions/quotationsfilters";
import { setLoading } from "../../actions/ui";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

class QuotationsListFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInputValue: "",
      status: "all",
      prospectId: "all",
    };
  }

  handleSearch = (e) => {
    this.setState(
      {
        searchInputValue: e.target.value,
      },
      () => {
        this.props.setQuotationsTextFilter(this.state.searchInputValue);
      }
    );
  };

  clearSearch = () => {
    this.setState(
      {
        searchInputValue: "",
      },
      () => {
        this.props.setQuotationsTextFilter("");
      }
    );
  };

  handleProspectSelectorChange = (e) => {
    this.setState({ prospectId: e.target.value }, () => {
      this.props.setQuotationsProspectsFilter(this.state.prospectId);
    });
  };

  handleStatusSelectorChange = (e) => {
    this.setState({ status: e.target.value }, () => {
      this.props.setQuotationsStatusFilter(this.state.status);
    });
  };

  render() {
    return (
      <Fragment>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel htmlFor="filter-search">Rechercher par numéro</InputLabel>
                <Input
                  placeholder="Entrer un numéro de quotation"
                  onChange={this.handleSearch}
                  inputProps={{
                    name: "search",
                    id: "filter-search",
                  }}
                  value={this.state.searchInputValue}
                  style={{ width: "100%" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Clear search" onClick={this.clearSearch}>
                        <HighlightOffIcon style={{ color: "#AAAAAA" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="quotations-prospect-filter-select-label">Filtrer par prospect</InputLabel>
                <Select
                  labelId="quotations-prospect-filter-select-label"
                  id="quotations-prospect-filter-select"
                  value={this.state.prospectId}
                  onChange={this.handleProspectSelectorChange}
                >
                  <MenuItem value={"all"}>Tous</MenuItem>
                  {this.props.prospects &&
                    this.props.prospects.map((prospect) => {
                      return (
                        <MenuItem key={prospect.id} value={prospect.id}>
                          {prospect.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="quotations-status-filter-select-label">Filtrer par status</InputLabel>
                <Select
                  labelId="quotations-status-filter-select-label"
                  id="quotations-status-filter-select"
                  value={this.state.status}
                  onChange={this.handleStatusSelectorChange}
                >
                  <MenuItem value={"all"}>Toutes les cotations</MenuItem>
                  <MenuItem value={"waiting"}>Quotations en attente de réponse</MenuItem>
                  <MenuItem value={"won"}>Quotations gagnées</MenuItem>
                  <MenuItem value={"lost"}>Quotations pérdues</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  quotationsfilters: state.quotationsfilters,
  prospects: state.prospects,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setQuotationsTextFilter: (text) => dispatch(setQuotationsTextFilter(text)),
  setQuotationsStatusFilter: (status) => dispatch(setQuotationsStatusFilter(status)),
  setQuotationsProspectsFilter: (prospectId) => dispatch(setQuotationsProspectsFilter(prospectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotationsListFilters);
