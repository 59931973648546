/**
 * AppEASA
 *
 * @file historyprices.js
 * @version 1.0
 * @description redux history prices' actions
 *
 */

// loading axios
import axios from "axios";

// import redux action type
import { SET_HISTORYPRICES_TIMES, SET_HISTORYPRICES } from "./types";
import setAuthToken from "../utils/setAuthToken";

// set history times
export const setHistoryTimes = (times) => ({
  type: SET_HISTORYPRICES_TIMES,
  payload: times,
});

// set history prices
export const setHistoryPrices = (prices) => ({
  type: SET_HISTORYPRICES,
  payload: prices,
});

/***************************************************************************************************
 *
 * POWER
 *
 ***************************************************************************************************/

// load history prices times
export const beginLoadHistoryPowerTimes = (date) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/historyprices/power/times/" + date, config);

      if (res.status === 200) {
        dispatch(setHistoryTimes(res.data.times));
        resolve({ status: res.status, length: res.data.times.length, defaultTime: res.data.times[0] });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des dates des historiques de prix."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadHistoryPowerTimes err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// load history prices dates
export const beginLoadHistoryPowerPrices = (priceId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/historyprices/power/" + priceId, config);

      if (res.status === 200) {
        dispatch(setHistoryPrices(res.data.prices));
        resolve({ status: res.status, length: res.data.prices.length });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des dates des prix historiques."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadHistoryPrices err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// get the latest time when prices have been received
export const getLastPowerPricesTime = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/historyprices/power/last/date", config);

      if (res.status === 200) {
        resolve({ status: res.status, time: res.data.time });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations concernant la dernière réception des prix."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("getLastPowerPricesTime err = ", err);
      reject({ status: false, msg: "" });
    }
  });

/***************************************************************************************************
 *
 * GUARANTEE
 *
 ***************************************************************************************************/

// load history prices times
export const beginLoadHistoryGoTimes = (date) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/historyprices/gos/times/" + date, config);

      if (res.status === 200) {
        dispatch(setHistoryTimes(res.data.times));
        resolve({ status: res.status, length: res.data.times.length, defaultTime: res.data.times[0] });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des dates des historiques de prix."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadHistoryGoTimes err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// load history prices dates
export const beginLoadHistoryGoPrices = (priceId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/historyprices/gos/" + priceId, config);

      if (res.status === 200) {
        dispatch(setHistoryPrices(res.data.prices));
        resolve({ status: res.status, length: res.data.prices.length });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations des dates des prix historiques."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("beginLoadHistoryGoPrices err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// get the latest time when prices have been received
export const getLastGoPricesTime = () => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      const res = await axios.get("/api/historyprices/gos/last/date", config);

      if (res.status === 200) {
        resolve({ status: res.status, time: res.data.time });
      } else {
        let err = {
          response: {
            data: {
              errors: ["Impossible de charger les informations concernant le dernier enregistrement des prix."],
            },
          },
        };
        throw err;
      }
    } catch (err) {
      console.log("getLastGoPricesTime err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// save new GO prices
export const beginSaveGosPrices = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios.post("/api/historyprices/gos", body, config).then((res) => {
        if (res.status === 200) {
          resolve({ msg: res.data.msg });
        } else {
          let err = {
            response: {
              data: {
                errors: ["Impossible d'enregistrer les nouveaux prix des garanties d'origines."],
              },
            },
          };
          throw err;
        }
      });
    } catch (err) {
      console.log("getLastGoPricesTime err = ", err);
      reject({ status: false, msg: "" });
    }
  });

// delete GO prices
export const beginDeleteGosPrices = (timeId) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios.delete("/api/historyprices/gos/" + timeId, config).then((res) => {
        if (res.status === 200) {
          resolve({ msg: res.data.msg });
        } else {
          let err = {
            response: {
              data: {
                errors: ["Impossible de supprimerles nouveaux prix des garanties d'origines."],
              },
            },
          };
          throw err;
        }
      });
    } catch (err) {
      console.log("beginDeleteGosPrices err = ", err);
      reject({ status: false, msg: "" });
    }
  });
