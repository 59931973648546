/**
 * AppEASA
 *
 * @file breadcrumb.js
 * @version 1.0
 * @description redux breadcrumb's actions
 *
 */

// import redux action type
import { SET_BREADCRUMB } from "./types";

// set breadcrumb
export const setBreadcrumb = (breadcrumb) => ({
  type: SET_BREADCRUMB,
  payload: breadcrumb,
});
